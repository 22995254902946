// @flow

import { subscribeUserList } from "../utils/SessionUsers";

import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

import UserContext from "./../utils/UserContext";
import { useInterval } from "./../utils/util";
import Card from "react-bootstrap/Card";
import ChatPartnerView from "./ChatPartnerView.react";
import SessionUserSelectModal from "./SessionUserSelectModal.react";

type Props = $ReadOnly<{
  sessionId: string,
  isTrainingView: boolean,
  selectedUser: string,
  setSelectedUser: React.Dispatch<React.SetStateAction<any>>,
}>;

type userConnection = {
  username: string,
  lastPulse: Date,
};

export default function SessionUsersView(props: Props): React$Element<any> {
  const [users, setUsers] = useState<Array<userConnection>>([]);
  const [interavls, setIntervals] = useState(0);
  // const [selectedUser, setSelectedUser] = useState("");

  const [isHidden, setIsHidden] = useState(props.isTrainingView);

  const [otherSessionUsers, setOtherSessionUsers] = useState([]);
  const [selectedOtherSessionUsers, setSelectedOtherSessionUsers] = useState(
    []
  );
  const [showSelectModal, setShowSelectModal] = useState(false);

  const userContext = useContext(UserContext);

  useEffect(() => {
    if (userContext.username === "") {
      return;
    }
    const sub = subscribeUserList(
      props.sessionId,
      userContext.username,
      setUsers
    );
    return () => {
      sub.unsubscribe();
    };
  }, [userContext, props.sessionId]);

  useInterval(() => {
    setIntervals((interavls) => interavls + 1);
    // Don't show users that has not sent a pulse in the last 40 seconds
    setUsers((previousUsers) => {
      const newUsers = [];
      previousUsers.forEach((user: userConnection) => {
        if (Date.now() - user.lastPulse > 40 * 1000) {
          if (props.selectedUser === user.username) {
            onUserSelect("");
          }
        } else {
          newUsers.push(user);
        }
      });
      return newUsers;
    });
  }, 10000);

  function onUserSelect(username: string) {
    props.setSelectedUser(username);
  }

  const handleOtherSessionUsersClick = (username) => {
    if (selectedOtherSessionUsers.includes(username)) {
      console.log("includes");
      setSelectedOtherSessionUsers(
        selectedOtherSessionUsers.filter((name) => name !== username)
      );
    } else {
      setSelectedOtherSessionUsers((prev) => [...prev, username]);
    }
  };

  const handleRemoveUser = (name) => {
    setOtherSessionUsers(
      otherSessionUsers.filter((user) => user.username !== name)
    );
  };

  function renderUsers() {
    return (
      <Container className="p-0">
        <Row>
          <Col>
            {users.length === 0
              ? "(no active users)"
              : users.map((user: userConnection) => {
                  return (
                    <div style={{ cursor: "pointer" }} key={user.username}>
                      <span
                        className={
                          props.selectedUser === user.username
                            ? "text-primary"
                            : null
                        }
                        onClick={() => {
                          if (props.selectedUser === user.username) {
                            onUserSelect("");
                          } else {
                            onUserSelect(user.username);
                          }
                        }}
                      >
                        {user.username}
                        {/* {selectedUser === user.username ? " (viewing)" : null} */}
                      </span>
                    </div>
                  ); // onclick onUserSelect
                })}
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Card className="p-0">
      <Card.Header>
        <Card.Text className="float-start p-0 m-0" as="h5">
          Partner Input
        </Card.Text>
        <span
          style={{
            float: "right",
            width: "fit-content",
            cursor: "pointer",
            color: "gray",
            textDecoration: "underline",
            marginLeft: "10px",
          }}
          onClick={() => {
            // setIsHidden((prev) => !prev);
            setShowSelectModal(true);
          }}
        >
          other
        </span>
        <span
          style={{
            float: "right",
            width: "fit-content",
            cursor: "pointer",
            color: "gray",
            textDecoration: "underline",
          }}
          onClick={() => {
            setIsHidden((prev) => !prev);
          }}
        >
          {isHidden ? "show" : "hide"}
        </span>
      </Card.Header>
      <Card.Body hidden={isHidden}>
        <Row
          style={{
            maxHeight: "80px",
            overflow: "hidden",
          }}
        >
          <Col xs={2}>{renderUsers()}</Col>
          <Col xs={10}>
            {users.length !== 0 && props.selectedUser === "" ? (
              <span style={{ color: "gray" }}>
                (click on username to start viewing)
              </span>
            ) : null}
            <ChatPartnerView
              sessionId={props.sessionId}
              username={props.selectedUser}
            />
          </Col>
        </Row>
        {otherSessionUsers.length > 0 ? (
          <Row style={{ border: "1px dotted #32a1ce" }}>
            {otherSessionUsers.map((user, index) => (
              <Row key={index}>
                <Col
                  xs={2}
                  onClick={() => {
                    handleOtherSessionUsersClick(user.username);
                  }}
                  style={{ cursor: "pointer" }}
                  className={
                    selectedOtherSessionUsers.includes(user.username)
                      ? "text-primary"
                      : null
                  }
                >
                  {user.username}
                </Col>
                <Col xs={9}>
                  {selectedOtherSessionUsers.includes(user.username) ? (
                    <ChatPartnerView
                      key={index}
                      sessionId={user.sessionId}
                      username={user.username}
                    />
                  ) : (
                    <span style={{ color: "gray" }}>
                      (click on username to start viewing)
                    </span>
                  )}
                </Col>
                <Col
                  onClick={() => {
                    handleRemoveUser(user.username);
                  }}
                  style={{ cursor: "pointer" }}
                  className="text-danger"
                  xs={1}
                >
                  X
                </Col>
              </Row>
            ))}
          </Row>
        ) : null}
      </Card.Body>
      <SessionUserSelectModal
        show={showSelectModal}
        onHide={() => {
          setShowSelectModal(false);
        }}
        setOtherSessionUsers={setOtherSessionUsers}
      />
      {/* Dummy invisible element for rerendinerg */}
      <div style={{ display: "none" }}>{interavls}</div>
    </Card>
  );
}

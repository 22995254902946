// @flow
import { API } from "aws-amplify";

import React from "react";
import { useContext, useEffect, useState } from "react";
import { Container, Row, Col, ToggleButton } from "react-bootstrap";
import { updateYoutubeSettings as updateYoutubeSettingsMutation } from "../graphql/mutations";


import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./../index.css";

type Props = $ReadOnly<{
  sessionId: string,
  youtubeUrl: string,
  show: boolean,
  onHide: (boolean) => void,
  minSeconds: number,
  maxSeconds: number,
  delay: number,
  holdTime: number,
  bufferTime: number,
  addedDelay: boolean,
  addedDelaySecs: number,
  setYoutubeUrl: React.Dispatch<React.SetStateAction<any>>,
  setShowSettings: React.Dispatch<React.SetStateAction<any>>,
  setMinSeconds: React.Dispatch<React.SetStateAction<any>>,
  setMaxSeconds: React.Dispatch<React.SetStateAction<any>>,
  setDelay: React.Dispatch<React.SetStateAction<any>>,
  setHoldTime: React.Dispatch<React.SetStateAction<any>>,
  setBufferTime: React.Dispatch<React.SetStateAction<any>>,
  setAddedDelay: React.Dispatch<React.SetStateAction<any>>,
  setAddedDelaySecs: React.Dispatch<React.SetStateAction<any>>,
}>;



export default function ShortcutsView(props: Props): React$Element<any> {
  const [checked, setChecked] = useState(true);

  async function updateYoutubeSettings(url: string,) {
    if (props.sessionId === "") {
      return;
    }
    const ret = await API.graphql({
      query: updateYoutubeSettingsMutation,
      variables: {
        input: {
          id: props.sessionId,
          url: url,
        },
      },
    });
  }

  async function updateYoutubeSettingsDelay(addedDelay: number,) {
    if (props.sessionId === "") {
      return;
    }
    const ret = await API.graphql({
      query: updateYoutubeSettingsMutation,
      variables: {
        input: {
          id: props.sessionId,
          addedDelay: addedDelay,
        },
      },
    });
  }

  async function updateYoutubeSettingsDelayOn(addedDelayOn: boolean,) {
    if (props.sessionId === "") {
      return;
    }
    const ret = await API.graphql({
      query: updateYoutubeSettingsMutation,
      variables: {
        input: {
          id: props.sessionId,
          addedDelayOn: addedDelayOn,
        },
      },
    });
  }


  function handleCheck(e) {
    // setChecked(e.currentTarget.checked);
    // props.setAddedDelay(prevAddedDelay => !prevAddedDelay)
    // updateYoutubeSettingsDelayOn();
    if (props.addedDelay) {
      updateYoutubeSettingsDelayOn(false);
    } else if (!props.addedDelay) {
      updateYoutubeSettingsDelayOn(true);
    }
  }

  function handleChange(e) {
    const number = parseInt(e.target.value, 10);
    // props.setAddedDelaySecs(number);
    updateYoutubeSettingsDelay(number);
  }

  return (
    <Modal
      show={props.show}
      onHide={() => props.onHide(false)}
      dialogClassName="modal-60w"
    >
      <Modal.Header closeButton>
        <Modal.Title
        // id="example-custom-modal-styling-title"
        >
          Youtube CC Settings
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="p-0 m-0">
          <Row>
            <Col>Min Subtitles Display
              <Form.Control
                type="number"
                onChange={(number) => {
                  props.setMinSeconds(number.target.valueAsNumber);
                }}
                value={props.minSeconds}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                min="1"
                max="3"
              />
            </Col>
            <Col>
              Max Subtitles Display
              <Form.Control
                type="number"
                onChange={(number) => {
                  props.setMaxSeconds(number.target.valueAsNumber);
                }}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                min="2"
                max="9"
                value={props.maxSeconds}
              />
            </Col>
            <Col> Delay (- : earlier / + : later)
              <Form.Control
                type="number"
                onChange={(number) => {
                  props.setDelay(number.target.valueAsNumber);
                }}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                min="-5"
                max="5"
                value={props.delay}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ToggleButton
                className="mt-3 mb-2"
                id="toggle-check"
                type="checkbox"
                variant="outline-success"
                checked={props.addedDelay}
                value="1"
                onChange={handleCheck}
              >
                {props.addedDelay
                  ? 'Added Delay On'
                  : 'No Delay'}
              </ToggleButton>
            </Col>
          </Row>
          <Row>
            {props.addedDelay ? (
              <Col>
                Added Delay Seconds
                <Form.Control
                  // type="number"
                  as="select"
                  onChange={handleChange}
                  value={props.addedDelaySecs}
                >
                  <option value="30">30 seconds</option>
                  <option value="60">60 seconds</option>
                </Form.Control>
              </Col>
            ) : null}
            {props.addedDelay ? (
              <Col>
                Hold Time
                <Form.Control
                  type="number"
                  onChange={(number) => {
                    props.setHoldTime(number.target.valueAsNumber);
                  }}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  min="15"
                  max="25"
                  value={props.holdTime}
                />
              </Col>
            ) : null}
            {props.addedDelay ? (
              <Col>
                Packet Buffer Time
                <Form.Control
                  type="number"
                  onChange={(number) => {
                    props.setBufferTime(number.target.valueAsNumber);
                  }}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  min="0"
                  max="2"
                  value={props.bufferTime}
                />
              </Col>
            ) : null}
          </Row>
          <Row className="mt-3 mb-3">
            <Col>Closed Caption Ingestion URL
              <Form.Control
                type="text"
                onChange={(event) => {
                  updateYoutubeSettings(event.target.value);
                }}
                defaultValue={props.youtubeUrl}
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

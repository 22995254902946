// @flow

import { API } from "aws-amplify";
import React, { useState, useEffect, useRef } from "react";
import { onCreateMessageBySession } from "./../graphql/subscriptions";
import { messagesBySession } from "./../graphql/queries";
import { Container, Row, Col } from "react-bootstrap";
import { useInterval } from "../utils/util";

type Props = $ReadOnly<{
  sessionId: string,
  textBackgroundColor: string,
  textBackgroundOpacity: number,
  fontSize: string, // Artificial dependency to rerender box shadow height
  fontColor: string,
  lineHeight: string,
  minSeconds: number,
  maxSeconds: number,
  minSecondsTextLengthThreshold: number,
  maxSecondsTextLengthThreshold: number,
  textShadowWidth: number,
  textShadowBlur: number,
  marginBottom: number,
  subAlign: string,
  lineNumber: number,
  isNoWrap: boolean,
  isDemoText: boolean,
}>;

export default function BroadcastMessagesView(
  props: Props
): React$Element<any> {
  const [messages, setMessages] = useState([]);
  const [interavls, setIntervals] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState("");
  const [shadowHeight, setShadowHeight] = useState("");
  const [textShadow, setTextShadow] = useState("");
  const [marginBottom, setMarginBottom] = useState(0);
  const [emptyRows, setEmptyRows] = useState([]);

  const parentRef = useRef();
  const spanRef = useRef();

  useEffect(() => {
    function calculateBackgroundColor() {
      if (props.textBackgroundOpacity < 7) {
        return (
          props.textBackgroundColor +
          "0" +
          Math.floor((props.textBackgroundOpacity * 255) / 100).toString(16)
        );
      }
      return (
        props.textBackgroundColor +
        Math.floor((props.textBackgroundOpacity * 255) / 100).toString(16)
      );
    }
    setBackgroundColor(calculateBackgroundColor());
  }, [props.textBackgroundColor, props.textBackgroundOpacity]);

  useEffect(() => {
    // console.log("calc shadow");
    function calculateShadowHeight() {
      return parentRef.current?.offsetHeight && spanRef.current?.offsetHeight
        ? (parentRef.current?.offsetHeight - spanRef.current?.offsetHeight) /
            2 +
            0.05
        : 0;
    }
    setShadowHeight(calculateShadowHeight());
  }, [parentRef, spanRef, props.fontSize, props.fontColor, props.lineHeight]);

  //Calculate textShadow value
  useEffect(() => {
    const width = props.textShadowWidth
      ? props.textShadowWidth.toString()
      : "0";
    const blur = props.textShadowBlur ? props.textShadowBlur.toString() : "0";

    function calculateTextShadow() {
      if (width === "0") {
        return "none";
      }
      return `${width}px ${width}px ${blur}px black,
      -${width}px -${width}px ${blur}px black,
      ${width}px -${width}px ${blur}px black,
      -${width}px ${width}px ${blur}px black`;
    }
    setTextShadow(calculateTextShadow());
  }, [props.textShadowWidth, props.textShadowBlur]);

  // Calculate margin bottom value
  useEffect(() => {
    const marginBottomValue = props.marginBottom
      ? props.marginBottom.toString()
      : "0";
    setMarginBottom(marginBottomValue);
  }, [props.marginBottom]);

  // Render empty rows according to props.lineNumber
  useEffect(() => {
    handleEmptyRows(props.lineNumber);
  }, [props.lineNumber]);

  const handleEmptyRows = (lineNumber) => {
    const newEmptyRows = [];

    for (let i = 0; i < lineNumber; i++) {
      newEmptyRows.push(
        <Row key={i}>
          <Col md="auto">
            <div ref={parentRef}>
              <span
                ref={spanRef}
                style={{
                  visibility: "hidden",
                  overflow: "hidden",
                  whiteSpace: props.isNoWrap ? "nowrap" : "normal",
                  backgroundColor: backgroundColor,
                  paddingLeft: "3px",
                  paddingRight: "10px",
                  boxShadow:
                    "0px " +
                    shadowHeight +
                    "px " +
                    backgroundColor +
                    ", 0px -" +
                    shadowHeight +
                    "px " +
                    backgroundColor,
                }}
              ></span>
            </div>
          </Col>
        </Row>
      );
    }
    setEmptyRows(newEmptyRows);
  };

  async function fetchMessages(sessionId) {
    const apiData = await API.graphql({
      query: messagesBySession,
      variables: {
        sessionMessagesId: sessionId,
        sortDirection: "DESC",
        // limit: 2,
        limit: props.lineNumber,
      },
    });
    return apiData.data?.messagesBySession?.items.reverse() ?? [];
  }

  // Remove if we don't want default message load
  // useEffect(() => {
  //   async function initialFetch() {
  //     const messages = await fetchMessages(props.sessionId);
  //     setMessages(messages);
  //   }
  //   initialFetch();
  // }, [props.sessionId, props.lineNumber]);
  //////

  useInterval(() => {
    setIntervals((interavls) =>
      interavls == Number.MAX_SAFE_INTEGER - 1 ? 0 : interavls + 1
    );
  }, 200);

  useEffect(() => {
    const subscription = API.graphql({
      query: onCreateMessageBySession,
      variables: {
        sessionMessagesId: props.sessionId,
      },
    }).subscribe({
      next: ({ provider, value }) => {
        // Allow single space to clear out the screen (second condition for u+3000 unicode space(ex. Japanese))
        if (
          value.data.onCreateMessageBySession.text === " " ||
          value.data.onCreateMessageBySession.text === "　"
        ) {
          setMessages([]);
        } else if (props.lineNumber === 1) {
          setMessages((prevMessages) => [
            {
              ...value.data.onCreateMessageBySession,
              receivedTime: Date.now(),
            },
          ]);
        } else {
          setMessages((prevMessages) => [
            // 원래 코드
            // ...prevMessages.slice(prevMessages.length - 1, prevMessages.length),
            // Dynamic line number
            ...prevMessages.slice((props.lineNumber - 1) * -1),
            {
              ...value.data.onCreateMessageBySession,
              receivedTime: Date.now(),
            },
          ]);
        }
      },
      error: (error) => console.warn(error),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [props.lineNumber]);

  function isMessageVisible(message): boolean {
    if (props.isDemoText) {
      return true;
    } else {
      const length = message.text.length;
      const minSeconds = props.minSeconds * 1000;
      const maxSeconds = props.maxSeconds * 1000;
      const minTextLength = props.minSecondsTextLengthThreshold;
      const maxTextLength = props.maxSecondsTextLengthThreshold;

      let displayLength = 0;
      if (length <= minTextLength) {
        displayLength = minSeconds;
      } else if (length > maxTextLength) {
        displayLength = maxSeconds;
      } else {
        displayLength =
          (maxSeconds - minSeconds) *
            (length / (maxTextLength - minTextLength)) +
          minSeconds;
      }
      return Date.now() - message.receivedTime < displayLength;
    }
  }

  return (
    <Container
      fluid
      // style={{ marginBottom: `${marginBottom}px` }}
    >
      {messages != null && messages.length > 0
        ? messages.map((message, index) => (
            <Row
              className={`text-${props.subAlign} justify-content-md-${props.subAlign}`}
              key={message.id}
            >
              <Col md="auto">
                <div ref={parentRef}>
                  <span
                    ref={spanRef}
                    style={{
                      visibility: isMessageVisible(message)
                        ? "visible"
                        : "hidden",
                      overflow: "hidden",
                      whiteSpace: props.isNoWrap ? "nowrap" : "normal",
                      backgroundColor: backgroundColor,
                      fontWeight: "bold",
                      paddingLeft: "3px",
                      paddingRight: "10px",
                      color: props.fontColor,
                      textShadow: textShadow,
                      boxShadow:
                        "0px " +
                        shadowHeight +
                        "px " +
                        backgroundColor +
                        ", 0px -" +
                        shadowHeight +
                        "px " +
                        backgroundColor,
                    }}
                  >
                    {message.text}
                  </span>
                </div>
              </Col>
            </Row>
          ))
        : // Below is a blank row as a placeholder for initial page load or 'Delete Row' function
          emptyRows}
    </Container>
  );
}

// @flow

// This is needed for indexing the sessions and is not actually used for anything
// Currently there are only one type of sessions.
import { useEffect, useRef } from "react";

export const DEFAULT_SESSION_TYPE = 0;

// This is needed for indexing the messages and is not actually used for anything
// Currently there are only one type of sessions.
export const DEFAULT_MESSAGE_TYPE = 0;

export const PUBLISH_LINE_BREAK = "^ENTER^";

export function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback && savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function useImperativeDisableScroll(element: any, disabled: boolean) {
  useEffect(() => {
    if (!element) {
      return;
    }

    element.style.overflowY = disabled ? "hidden" : "scroll";

    return () => {
      element.style.overflowY = "scroll";
    };
  }, [disabled]);
}


export const defaultKeyCommands = [
  {
    commandName: "clearSubtitles",
    triggerKey: "F8",
  },
  {
    commandName: "focusInput",
    triggerKey: "F1",
  },
  {
    commandName: "moveDown",
    triggerKey: "ArrowDown",
  },
  {
    commandName: "moveUp",
    triggerKey: "ArrowUp",
  },
  {
    commandName: "sendScript",
    triggerKey: "Off",
  },
  {
    commandName: "sendTranslation",
    triggerKey: "F4",
  },
]

export const functionKeys = ["F1", "F2", "F3", "F4", "F5", "F6", "F7", "F8", "F9", "F10", "F11", "F12", "ArrowUp", "ArrowDown", "Off"];
import React, { useState, useEffect, useContext } from "react";
import { API } from "aws-amplify";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import NavigationBar from "./NavigationBar.react";
import UserContext from "./../utils/UserContext";

import {
  getArtist as getArtistQuery,
  listArtists as listArtistsQuery,
} from "../graphql/queries";
import {
  createArtist as createArtistMutation,
  updateArtist as updateArtistMutation,
  deleteArtist as deleteArtistMutation,
} from "../graphql/mutations";
import {
  onCreateArtist,
  onUpdateArtist,
  onDeleteArtist,
} from "../graphql/subscriptions";

const styles = {
  item: { width: "20%" },
  btn: { width: "3%" },
  ch: { width: "17%" },
  header: { fontWeight: "bold" },
};

async function deleteArtist(id: string) {
  const ret = await API.graphql({
    query: deleteArtistMutation,
    variables: {
      input: {
        id: id,
      },
    },
  });
}

async function listArtists() {
  const apiData = await API.graphql({
    query: listArtistsQuery,
    variables: {},
  });
  return apiData.data?.listArtists?.items.reverse() ?? [];
}

export default function ArtistsView(props: Props): React$Element<any> {
  const [allArtists, setAllArtists] = useState([]);
  const [koreanName, setKoreanName] = useState("");
  const [englishName, setEnglishName] = useState("");
  const [japaneseName, setJapaneseName] = useState("");
  const [chineseName, setChineseName] = useState("");
  const [teamName, setTeamName] = useState("");

  const userContext = useContext(UserContext);

  // Initial fetch
  useEffect(() => {
    fetchArtists();
  }, []);

  // Subscribe to Mutations
  useEffect(() => {
    const subscribeToMutation = (query) => {
      const subscription = API.graphql({
        query,
        variables: {
          //   userId: userContext.userId,
        },
      }).subscribe({
        next: () => {
          fetchArtists();
        },
        error: (error) => console.warn(error),
      });

      return subscription;
    };

    const onCreateSub = subscribeToMutation(onCreateArtist);
    const onDeleteSub = subscribeToMutation(onDeleteArtist);
    const onUpdateSub = subscribeToMutation(onUpdateArtist);

    return () => {
      onCreateSub.unsubscribe();
      onDeleteSub.unsubscribe();
      onUpdateSub.unsubscribe();
    };
  }, []);

  async function fetchArtists() {
    const allArtists = await listArtists();
    setAllArtists(allArtists);
  }

  async function createArtist() {
    const ret = await API.graphql({
      query: createArtistMutation,
      variables: {
        input: {
          krName: koreanName,
          enName: englishName,
          jaName: japaneseName,
          chName: chineseName,
          team: teamName,
        },
      },
    });
  }

  const setToDefault = () => {
    setTeamName("");
    setKoreanName("");
    setEnglishName("");
    setJapaneseName("");
    setChineseName("");
  };

  const handleCreateClick = () => {
    createArtist();
    setToDefault();
  };

  return (
    <>
      <NavigationBar />
      <Container fluid>
        <Row>
          <Col className="p-0">
            <Card className="m-2" bg="secondary" text="white">
              <Card.Body>
                <Card.Text as="h3">Edit Artists</Card.Text>
                <Card.Text>
                  Hello, <i>{userContext.username}</i>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="m-2 p-0">
              <Card.Body>
                <InputGroup className="mt-4">
                  <InputGroup.Text>Team</InputGroup.Text>
                  <Form.Control
                    type="text"
                    onChange={(event) => {
                      setTeamName(event.target.value);
                    }}
                    value={teamName}
                  />
                  <InputGroup.Text>Korean</InputGroup.Text>
                  <Form.Control
                    type="text"
                    onChange={(event) => {
                      setKoreanName(event.target.value);
                    }}
                    value={koreanName}
                  />
                  <InputGroup.Text>English</InputGroup.Text>
                  <Form.Control
                    type="text"
                    onChange={(event) => {
                      setEnglishName(event.target.value);
                    }}
                    value={englishName}
                  />
                  <InputGroup.Text>Japanese</InputGroup.Text>
                  <Form.Control
                    type="text"
                    onChange={(event) => {
                      setJapaneseName(event.target.value);
                    }}
                    value={japaneseName}
                  />
                  <InputGroup.Text>Chinese</InputGroup.Text>
                  <Form.Control
                    type="text"
                    onChange={(event) => {
                      setChineseName(event.target.value);
                    }}
                    value={chineseName}
                  />
                </InputGroup>
                <Button className="float-end" onClick={handleCreateClick}>
                  Create
                </Button>
              </Card.Body>
            </Card>

            <Card className="m-2 p-0">
              <Card.Body>
                <ListGroup horizontal style={styles.header}>
                  <ListGroup.Item style={styles.item}>팀</ListGroup.Item>
                  <ListGroup.Item style={styles.item}>한글</ListGroup.Item>
                  <ListGroup.Item style={styles.item}>영문</ListGroup.Item>
                  <ListGroup.Item style={styles.item}>일문</ListGroup.Item>
                  <ListGroup.Item style={styles.item}>중문</ListGroup.Item>
                </ListGroup>
                {allArtists.map((artist, index) => (
                  <ListGroup key={index} horizontal>
                    <ListGroup.Item style={styles.item}>
                      {artist.team}
                    </ListGroup.Item>
                    <ListGroup.Item style={styles.item}>
                      {artist.krName}
                    </ListGroup.Item>
                    <ListGroup.Item style={styles.item}>
                      {artist.enName}
                    </ListGroup.Item>
                    <ListGroup.Item style={styles.item}>
                      {artist.jaName}
                    </ListGroup.Item>
                    <ListGroup.Item style={styles.ch}>
                      {artist.chName}
                    </ListGroup.Item>
                    <Button
                      style={styles.btn}
                      variant="outline-danger"
                      onClick={() => {
                        deleteArtist(artist.id);
                      }}
                    >
                      X
                    </Button>
                  </ListGroup>
                ))}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

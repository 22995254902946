// @flow

import { PubSub } from "aws-amplify";

const delim = "^|";

export async function publishUserJoined(
  sessionId: string,
  username: string,
  isRepublish: boolean
) {
  if (username === "" || username == null) {
    return;
  }
  await PubSub.publish(
    _getSessionUsersTopic(sessionId),
    "joined" + delim + username
  );
  if (!isRepublish) {
    await PubSub.publish(
      _getSessionUsersTopic(sessionId),
      "request_connected_users"
    );
  }
}

export function subscribeUserList(
  sessionId: string,
  username: string,
  setUsersFunc: any
): any {
  return PubSub.subscribe(_getSessionUsersTopic(sessionId)).subscribe({
    next: (data) =>
      _parseMessageAndUpdateUsers(
        sessionId,
        username,
        data.value,
        setUsersFunc
      ),
    error: (error) => console.error(error),
    complete: () => console.log("Done"),
  });
}

export function getSessionUsernameTopic(
  sessionId: string,
  username: string
): string {
  return sessionId + delim + username;
}

function _getSessionUsersTopic(sessionId: string): string {
  return sessionId + "_users";
}

function _parseMessageAndUpdateUsers(
  sessionId,
  selfUserName,
  message,
  setUsersFunc
) {
  const [action, username] = message.split(delim);

  if (action === "joined") {
    if (username === selfUserName || username === "" || username == null) {
      return;
    }
    setUsersFunc((previousUsers) => {
      const newUsers = [];
      let exists = false;
      previousUsers.forEach((user) => {
        // Refresh pulse timestamp if this join
        // matches the username already in the array
        if (user.username === username) {
          exists = true;
        }
        newUsers.push({
          username: user.username,
          lastPulse: user.username === username ? Date.now() : user.lastPulse,
        });
      });
      if (!exists) {
        newUsers.push({ username, lastPulse: Date.now() });
      }
      return newUsers;
    });
  } else if (action === "request_connected_users") {
    _resend_connected(sessionId, selfUserName);
  }
}

async function _resend_connected(sessionId, username) {
  await PubSub.publish(
    _getSessionUsersTopic(sessionId),
    "joined" + delim + username
  );
}

// @flow
import { API } from "aws-amplify";
import React, { useState, useEffect, useContext } from "react";
import MessagesView from "./MessagesView.react";
import { Container, Row, Col, Button, ToggleButton, ButtonGroup } from "react-bootstrap";
import NavigationBar from "./NavigationBar.react";

import { getSession, getYoutubeSettings } from "./../graphql/queries";
import { onUpdateYoutubeSettings } from "../graphql/subscriptions";
import { createYoutubeSettings, createYoutubeSettings as createYoutubeSettingsMutation } from "../graphql/mutations";

import Card from "react-bootstrap/Card";
import YoutubeInputView from "./YoutubeInputView.react";
import YoutubeSettingsView from "./YoutubeSettingsView.react";
import SessionUsersView from "./SessionUsersView.react";
import ShortcutsView from "./ShortcutsView.react";
import SheetsView from "./SheetsView.react";

// Below are copied from Session Users View
import { subscribeUserList } from "../utils/SessionUsers";
import { useInterval } from "./../utils/util";
import UserContext from "./../utils/UserContext";

type Props = $ReadOnly<{
  sessionId: ?string,
}>;

// Session Users View 에서 가져옴
type userConnection = {
  username: string,
  lastPulse: Date,
};


export default function TranslatorView(props: Props): React$Element<any> {
  const [sessionName, setSessionName] = useState("");

  // Below are copied from Session Users View
  const [users, setUsers] = useState < Array < userConnection >> ([]);
  const [interavls, setIntervals] = useState(0);
  const [selectedUser, setSelectedUser] = useState("");
  const userContext = useContext(UserContext);

  // Fetch Youtube Url from DynamoDB Tables
  const [youtubeUrl, setYoutubeUrl] = useState("");

  // useState hooks for showing / hiding sheets / shortcuts
  const [showSheets, setShowSheets] = useState(false);
  const [sheetsChecked, setSheetsChecked] = useState(false);
  const [showShortcuts, setShowShortcuts] = useState(false);
  const [shortcutsChecked, setShortcutsChecked] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [settingsChecked, setSettingsChecked] = useState(false);

  const [sheetsInput, setSheetsInput] = useState("");
  const [sheetsInputIndex, setSheetsInputIndex] = useState(0);

  const [shortcutInput, setShortcutInput] = useState("");
  const [shortcutInputIndex, setShortcutInputIndex] = useState(0);

  const [prevDisplay, setPrevDisplay] = useState(false);

  const [minSeconds, setMinSeconds] = useState(2);
  const [maxSeconds, setMaxSeconds] = useState(5);
  const [delay, setDelay] = useState(0);
  const [addedDelay, setAddedDelay] = useState(true);
  const [holdTime, setHoldTime] = useState(22);
  // WARNING!! holdTime must be greater than 15 sec at least!
  const [bufferTime, setBufferTime] = useState(2);
  const [addedDelaySecs, setAddedDelaySecs] = useState(30);

  const isYoutubeView = true;

  let inputWidth;
  if (!showSheets && !showShortcuts) {
    inputWidth = 12;
  } else if (showSheets && !showShortcuts) {
    inputWidth = 5;
  } else if (!showSheets && !showShortcuts) {
    inputWidth = 11;
  }

  let sheetsWidth;
  if (showShortcuts) {
    sheetsWidth = 6;
  } else {
    sheetsWidth = 7;
  }


  // useEffect hook below is copied from Session Users View, not sure if necessary...
  useEffect(() => {
    if (userContext.username === "") {
      return;
    }
    const sub = subscribeUserList(
      props.sessionId,
      userContext.username,
      setUsers
    );
    return () => {
      sub.unsubscribe();
    };
  }, [userContext, props.sessionId]);

  // useInterval below is copied from Session Users View, not sure if necessary...
  useInterval(() => {
    setIntervals((interavls) => interavls + 1);
    // Don't show users that has not sent a pulse in the last 40 seconds
    setUsers((previousUsers) => {
      const newUsers = [];
      previousUsers.forEach((user: userConnection) => {
        if (Date.now() - user.lastPulse > 40 * 1000) {
          if (selectedUser === user.username) {
            onUserSelect("");
          }
        } else {
          newUsers.push(user);
        }
      });
      return newUsers;
    });
  }, 10000);

  // function below is copied from Session Users View, not sure if necessary...
  function onUserSelect(username: string) {
    setSelectedUser(username);
  }

  useEffect(() => {
    fetchSession();
    // fetchYoutubeSettings();
  });

  useEffect(() => {
    async function initialFetch() {
      const apiData = await API.graphql({
        query: getYoutubeSettings,
        variables: {
          id: props.sessionId,
        },
      });
      if (apiData.data.getYoutubeSettings === null) {
        createEmptyYoutubeSettings();
      } else {
        setYoutubeUrl(apiData.data.getYoutubeSettings.url);
        setAddedDelaySecs(apiData.data.getYoutubeSettings.addedDelay);
        setAddedDelay(apiData.data.getYoutubeSettings.addedDelayOn);
      }
    }
    initialFetch();
  }, [props.sessionId]);


  // Subscribe to update youtubeUrl by session
  useEffect(() => {
    const subscription = API.graphql({
      query: onUpdateYoutubeSettings,
      variables: {
        id: props.sessionId,
      },
    }).subscribe({
      next: () => {
        fetchYoutubeUrl();
        fetchAddedDelaySecs();
        fetchAddedDelayOn();
      },
      error: (error) => console.warn(error),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);


  async function fetchSession() {
    const apiData = await API.graphql({
      query: getSession,
      variables: {
        id: props.sessionId,
      },
    });
    setSessionName(apiData.data.getSession.name);
  }

  async function fetchYoutubeUrl() {
    const apiData = await API.graphql({
      query: getYoutubeSettings,
      variables: {
        id: props.sessionId,
      },
    });
    setYoutubeUrl(apiData.data.getYoutubeSettings.url);
  }

  async function fetchAddedDelaySecs() {
    const apiData = await API.graphql({
      query: getYoutubeSettings,
      variables: {
        id: props.sessionId,
      },
    });
    setAddedDelaySecs(apiData.data.getYoutubeSettings.addedDelay);
  }

  async function fetchAddedDelayOn() {
    const apiData = await API.graphql({
      query: getYoutubeSettings,
      variables: {
        id: props.sessionId,
      },
    });
    setAddedDelay(apiData.data.getYoutubeSettings.addedDelayOn);
  }

  async function createEmptyYoutubeSettings() {
    if (props.sessionId === "") {
      return;
    }
    const ret = await API.graphql({
      query: createYoutubeSettingsMutation,
      variables: {
        input: {
          id: props.sessionId,
          url: "",
          addedDelay: 30,
          addedDelayOn: true,
        },
      },
    });
  }


  function handleShowSheets(e) {
    setSheetsChecked(e.currentTarget.checked);
    setShowSheets(prevShowSheets => !prevShowSheets)
  }

  function handleShowShortcuts(e) {
    setShortcutsChecked(e.currentTarget.checked);
    setShowShortcuts(prevShowShortcuts => !prevShowShortcuts)
  }

  function handleShowSettings(e) {
    setSettingsChecked(e.currentTarget.checked);
    setShowSettingsModal(true);
  }

  if (props.sessionId == null) {
    return <div>Invalid Session</div>;
  }
  return (
    <>
      <NavigationBar />
      <Container fluid>
        <Row>
          <Col className="p-0">
            <Card className="m-2" bg="secondary" text="white">
              <Card.Body>
                <Card.Text as="h5">{sessionName}
                  <ButtonGroup className="float-end">
                    <ToggleButton
                      id="toggle-setSheetsCheck"
                      type="checkbox"
                      variant="outline-warning"
                      checked={sheetsChecked}
                      value="1"
                      onChange={handleShowSheets}
                    >
                      Script
                    </ToggleButton>
                    <ToggleButton
                      id="toggle-setCheckShortcuts"
                      type="checkbox"
                      variant="outline-warning"
                      checked={shortcutsChecked}
                      value="1"
                      onChange={handleShowShortcuts}
                    >
                      Shortcuts
                    </ToggleButton>
                    <ToggleButton
                      id="toggle-setCheckSettings"
                      type="checkbox"
                      className="float-end"
                      variant="outline-warning"
                      checked={settingsChecked}
                      onClick={handleShowSettings}
                    >
                      Settings
                    </ToggleButton>
                  </ButtonGroup>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="p-0">
          {showSheets ? (
            <Col xs={sheetsWidth} className="p-2">
              <SheetsView
                sessionId={props.sessionId}
                setSheetsInput={setSheetsInput}
                setSheetsInputIndex={setSheetsInputIndex}
                isYoutubeView={isYoutubeView}
                prevDisplay={prevDisplay}
              />
            </Col>
          ) : null}
          <Col xs={inputWidth} className="p-2">
            <MessagesView
              sessionId={props.sessionId}
              title="History"
            ></MessagesView>
            <br />
            <SessionUsersView sessionId={props.sessionId} />
            <br />
            <YoutubeInputView
              sessionId={props.sessionId}
              username={users}
              youtubeUrl={youtubeUrl}
              sheetsInput={sheetsInput}
              sheetsInputIndex={sheetsInputIndex}
              prevDisplay={prevDisplay}
              setPrevDisplay={setPrevDisplay}
              minSeconds={minSeconds}
              maxSeconds={maxSeconds}
              delay={delay}
              addedDelay={addedDelay}
              holdTime={holdTime}
              bufferTime={bufferTime}
              addedDelaySecs={addedDelaySecs}
            />
          </Col>
          {showShortcuts ? (
            <Col xs={1} className="p-2">
              <ShortcutsView
                sessionId={props.sessionId}
                setShortcutInput={setShortcutInput}
                setShortcutInputIndex={setShortcutInputIndex}
              />
            </Col>
          ) : null}
        </Row>
        <YoutubeSettingsView
          sessionId={props.sessionId}
          youtubeUrl={youtubeUrl}
          show={showSettingsModal}
          minSeconds={minSeconds}
          maxSeconds={maxSeconds}
          delay={delay}
          addedDelay={addedDelay}
          holdTime={holdTime}
          bufferTime={bufferTime}
          addedDelaySecs={addedDelaySecs}
          setYoutubeUrl={setYoutubeUrl}
          setMinSeconds={setMinSeconds}
          setMaxSeconds={setMaxSeconds}
          setDelay={setDelay}
          setAddedDelay={setAddedDelay}
          setHoldTime={setHoldTime}
          setBufferTime={setBufferTime}
          setAddedDelaySecs={setAddedDelaySecs}
          onHide={() => {
            setShowSettingsModal(false);
          }}
        />
      </Container>
    </>
  );
}

// @flow

import { API, PubSub } from "aws-amplify";
import React, { useState, useEffect, useContext, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import { createMessage as createMessageMutation } from "./../graphql/mutations";
import {
  queryShortcutBindingsByKeyBindingSessionIdIndex,
  queryUserMacrosByUserIdTriggerWordIndex,
} from "./../graphql/queries";
import {
  onUpdateShortcutBindingBySession,
  onCreateUserMacro,
  onUpdateUserMacro,
  onDeleteUserMacro,
} from "./../graphql/subscriptions";
import { queryUserKeyCommandsByUserIdCommandNameIndex as queryUserKeyCommands } from "./../graphql/queries";

import { DEFAULT_MESSAGE_TYPE } from "./../utils/util";
import { publishUserJoined } from "./../utils/SessionUsers";
import UserContext from "./../utils/UserContext";
import { getSessionUsernameTopic } from "../utils/SessionUsers";
import { PUBLISH_LINE_BREAK } from "../utils/util";

type Props = $ReadOnly<{
  sessionId: string,
  shortcutInput: string,
  shortcutInputIndex: number,
  setSourceWord: React.Dispatch<React.SetStateAction<any>>,
  translatedWord: string,
}>;

const styles = {
  title: {
    width: "150px",
    fontSize: "10.5pt",
    padding: "3px",
    fontWeight: 500,
    backgroundColor: "white",
    // border: "1px solid black",
    cursor: "default",
  },
  macroList: {
    width: "150px",
    fontSize: "10.5pt",
    padding: "3px",
    cursor: "default",
  },
  inputGroup: {
    cursor: "default",
  },
  groupName: {
    cursor: "default",
    fontWeight: 600,
  },
  listContainer: { paddingBottom: "10px" },
  within: {
    marginRight: "10px",
    float: "right",
    width: "fit-content",
    color: "gray",
  },
  exceed: {
    marginRight: "10px",
    float: "right",
    width: "fit-content",
    color: "red",
  },
};

async function createMessage(
  sessionId: string,
  text: string,
  username: string
) {
  if (sessionId === "") {
    return;
  }
  const ret = await API.graphql({
    query: createMessageMutation,
    variables: {
      input: {
        sessionMessagesId: sessionId,
        text,
        type: DEFAULT_MESSAGE_TYPE,
        username: username,
      },
    },
  });
}

async function fetchBindings(sessionId) {
  try {
    const apiData = await API.graphql({
      query: queryShortcutBindingsByKeyBindingSessionIdIndex,
      variables: {
        keyBindingSessionId: sessionId,
      },
    });
    return (
      apiData.data?.queryShortcutBindingsByKeyBindingSessionIdIndex?.items ?? []
    );
  } catch (e) {
    console.log(e);
  }
}

async function fetchUserMacro(userId) {
  try {
    const apiData = await API.graphql({
      query: queryUserMacrosByUserIdTriggerWordIndex,
      variables: {
        userId: userId,
      },
    });
    return apiData.data.queryUserMacrosByUserIdTriggerWordIndex.items;
  } catch (e) {
    console.log(e);
  }
}

async function fetchUserKeyCommands(userId) {
  try {
    const apiData = await API.graphql({
      query: queryUserKeyCommands,
      variables: {
        userId: userId,
      },
    });
    return apiData.data.queryUserKeyCommandsByUserIdCommandNameIndex.items;
  } catch (e) {
    console.log(e);
  }
}

export default function ChatInputView(props: Props): React$Element<any> {
  const [textInput, setTextInput] = useState("");
  const [bindings, setBindings] = useState([]);
  const userContext = useContext(UserContext);

  const ref = useRef(null);
  const isMounted = useRef(false);

  const [focusInputKey, setFocusInputKey] = useState("F1");
  const [clearSubtitlesKey, setClearSubtitlesKey] = useState("F8");

  // Macro Function
  const [macroArray, setMacroArray] = useState([]);
  const [showUserMacro, setShowUserMacro] = useState(false);
  const [macroGroupArray, setMacroGroupArray] = useState([]);
  const [showName, setShowName] = useState(true);
  const [showTrigger, setShowTrigger] = useState(true);
  const [showInsert, setShowInsert] = useState(true);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [typingWord, setTypingWord] = useState("");

  // Max text length function
  const [maxCount, setMaxCount] = useState(100);

  // Initial macro fetch
  useEffect(() => {
    handleFetchMacro();
  }, []);

  const makeMacroGroupArray = (macro: array) => {
    let arr = [];
    for (let i = 0; i < macro.length; i++) {
      if (!arr.includes(macro[i].macroGroup) && macro[i].isEnabled) {
        arr.push(macro[i].macroGroup);
      }
    }
    arr.sort();
    setMacroGroupArray(arr);
  };

  const handleFetchMacro = () => {
    async function updateFetch() {
      const macro = await fetchUserMacro(userContext.userId);
      setMacroArray(macro);
      makeMacroGroupArray(macro);
    }
    updateFetch();
  };

  // User Key Command initial fetch
  useEffect(() => {
    async function initialFetch() {
      const fetchedKeyCommands = await fetchUserKeyCommands(userContext.userId);
      const hasValue = (command) => {
        for (const obj of fetchedKeyCommands) {
          if (obj.commandName === command) {
            return true;
          }
        }
        return false;
      };
      hasValue("focusInput") &&
        setFocusInputKey(
          fetchedKeyCommands.find(
            (command) => command.commandName === "focusInput"
          ).triggerKey
        );
      hasValue("focusInput") &&
        setClearSubtitlesKey(
          fetchedKeyCommands.find(
            (command) => command.commandName === "clearSubtitles"
          ).triggerKey
        );
    }
    initialFetch();
  }, [props.sessionId]);

  // Subscribe to macro mutation
  useEffect(() => {
    const subscription = API.graphql({
      query: onCreateUserMacro,
      variables: {
        userId: userContext.userId,
      },
    }).subscribe({
      next: () => {
        handleFetchMacro();
      },
      error: (error) => console.warn(error),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const subscription = API.graphql({
      query: onDeleteUserMacro,
      variables: {
        userId: userContext.userId,
      },
    }).subscribe({
      next: () => {
        handleFetchMacro();
      },
      error: (error) => console.warn(error),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const subscription = API.graphql({
      query: onUpdateUserMacro,
      variables: {
        userId: userContext.userId,
      },
    }).subscribe({
      next: () => {
        handleFetchMacro();
      },
      error: (error) => console.warn(error),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // Sort macro array
  useEffect(() => {
    macroArray.sort((a, b) => (a.macroName > b.macroName ? 1 : -1));
  }, [macroArray]);

  // Set translate source word
  useEffect(() => {
    props.setSourceWord(textInput.split(" ").pop());
  }, [textInput]);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // Inits the sessionUsers object and publishes the user connection status
  useEffect(
    () => {
      publishUserJoined(props.sessionId, userContext.username, false);
      setInterval(() => {
        publishUserJoined(props.sessionId, userContext.username, true);
      }, 30000);
    },
    // Do not include anything else in the dependency list
    [props.sessionId, userContext]
  );

  useEffect(() => {
    async function initialFetch() {
      const fetchedBindings = await fetchBindings(props.sessionId);
      setBindings(fetchedBindings);
    }
    initialFetch();
  }, [props.sessionId]);

  // Shortcut input by button click, inefficient because this re-renders sheet
  useEffect(() => {
    if (isMounted.current) {
      insertShortcut(props.shortcutInput);
    } else {
      isMounted.current = true;
    }
  }, [props.shortcutInputIndex]);

  function handleSendMessage(event) {
    event.preventDefault();
    //  Don't create a message if the message is empty
    if (textInput === "") {
      return;
    }
    createMessage(props.sessionId, textInput, userContext.username);
    setTextInput("");
  }

  // Subscribe to update shortcut by session
  useEffect(() => {
    const subscription = API.graphql({
      query: onUpdateShortcutBindingBySession,
      variables: {
        keyBindingSessionId: props.sessionId,
      },
    }).subscribe({
      next: () => {
        async function updateFetch() {
          const fetchedBindings = await fetchBindings(props.sessionId);
          setBindings(fetchedBindings);
        }
        updateFetch();
      },
      error: (error) => console.warn(error),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleShortcutPress);
    return () => {
      window.removeEventListener("keydown", handleShortcutPress);
    };
  }, [bindings]);

  useEffect(() => {
    window.addEventListener("keydown", handleFocusInput);
    return () => {
      window.removeEventListener("keydown", handleFocusInput);
    };
  }, [focusInputKey]);

  useEffect(() => {
    window.addEventListener("keydown", handleDeleteMessages);
    return () => {
      window.removeEventListener("keydown", handleDeleteMessages);
    };
  }, [clearSubtitlesKey]);

  useEffect(() => {
    window.addEventListener("keydown", handleAutoTranslate);
    return () => {
      window.removeEventListener("keydown", handleAutoTranslate);
    };
  }, [props.translatedWord]);

  // Use useEffect to add the click event listener
  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("click", handleMouseClick);
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener("click", handleMouseClick);
      }
    };
  }, []);

  const handleMouseClick = () => {
    if (!ref.current) return;
    const textarea = ref.current;
    setCursorPosition(textarea.selectionStart);
  };

  // HandleShortcutPress V 2.0
  const handleShortcutPress = (event) => {
    bindings.map((binding) => {
      if (event.ctrlKey || event.metaKey) {
        if (event.key === binding.key) {
          console.log("shortcut triggered");
          event.preventDefault();
          insertShortcut(binding.text);
        }
      }
    });
  };

  // Function to insert a shortcut at the cursor position
  const insertShortcut = (shortcut) => {
    if (!ref.current) return;

    const textarea = ref.current;
    const selectionStart = textarea.selectionStart;
    const selectionEnd = textarea.selectionEnd;
    const newCursorPosition = selectionStart + shortcut.length;

    // Insert the shortcut at the cursor position
    const newText =
      textarea.value.substring(0, selectionStart) +
      shortcut +
      textarea.value.substring(selectionEnd);

    // setTextInput(newText);
    textarea.value = newText;

    // Set the cursor position to the end of the inserted shortcut
    textarea.selectionStart = newCursorPosition;
    textarea.selectionEnd = newCursorPosition;
    textarea.focus();
    setTextInput(newText);
  };

  // // Handle Macro v 1.0
  // const handleMacro = (event) => {
  //   const inputValue = event.target.value;
  //   const matchingMacro = macroArray.find(
  //     (macro) => inputValue.endsWith(macro.triggerWord) && macro.isEnabled
  //   );
  //   const insert = matchingMacro?.insertWord || inputValue;
  //   setTextInput(
  //     inputValue.substring(
  //       0,
  //       inputValue.length - matchingMacro?.triggerWord?.length || 0
  //     ) + insert
  //   );
  // };

  const findWhiteSpace = (string, startIndex) => {
    if (startIndex <= 0) {
      // Handle edge case where startIndex is 0 or negative
      return -1;
    }

    // Iterate in reverse from startIndex to 0
    for (let i = startIndex - 1; i >= 0; i--) {
      if (/\s/.test(string[i])) {
        // Found a whitespace character before the given index
        return i;
      }
    }

    // No whitespace found before the given index, return -1
    return -1;
  };

  // Handle Macro v 2.0
  const handleMacro = (event) => {
    if (!ref.current) return;

    const inputValue = event.target.value;
    const textarea = ref.current;
    const selectionStart = textarea.selectionStart;
    const selectionEnd = textarea.selectionEnd;
    const whiteSpaceIndex = findWhiteSpace(inputValue, selectionStart);
    const detectedTrigger = inputValue.slice(
      whiteSpaceIndex + 1,
      selectionStart
    );

    const matchingMacro = macroArray.find(
      (macro) => detectedTrigger === macro.triggerWord && macro.isEnabled
    );

    const insert = matchingMacro?.insertWord || inputValue;

    if (matchingMacro) {
      const before = inputValue.substring(
        0,
        selectionStart - matchingMacro?.triggerWord?.length
      );
      const after = inputValue.substring(selectionStart, inputValue.length);

      const newText = before + insert + after;

      const newCursorPosition =
        selectionStart +
        -matchingMacro?.triggerWord?.length +
        matchingMacro?.insertWord?.length;

      textarea.value = newText;

      textarea.selectionStart = newCursorPosition;
      textarea.selectionEnd = newCursorPosition;

      textarea.focus();

      setTextInput(newText);
    } else {
      setTextInput(insert);
    }
  };

  // const handleTypingWord = (event) => {
  //   console.log(event.target.value);
  //   setTypingWord(event.target.value);
  // };

  // useEffect(() => {
  //   window.addEventListener("keyup", handleTypingWord);
  //   return () => {
  //     window.removeEventListener("keyup", handleTypingWord);
  //   };
  // }, []);

  const handleFocusInput = (event) => {
    if (event.key === focusInputKey) {
      event.preventDefault();
      ref.current.focus();
    }
  };

  const handleDeleteMessages = (event) => {
    if (event.key === clearSubtitlesKey) {
      event.preventDefault();
      createMessage(props.sessionId, " ");
    }
  };

  const handleAutoTranslate = (event) => {
    if (event.key === "Tab") {
      // event.preventDefault();
      console.log(props.translatedWord);

      // function replaceLastWord(str, replacement) {
      //   var words = str.split(' ');
      //   words[words.length - 1] = replacement; // Replace the last word with the desired replacement
      //   return words.join(' '); // Join the words back into a string
      // }

      // setTextInput(replaceLastWord(textInput, props.translatedWord));
    }
  };

  async function publishTextChange(text) {
    if (userContext.username === "") {
      return;
    }
    const topic = getSessionUsernameTopic(
      props.sessionId,
      userContext.username
    );
    await PubSub.publish(topic, text);
  }

  function handleTextsInputChange(event) {
    if (userContext.username === "") {
      return;
    }
    // console.log(event.target.value);
    if (event.key === "Enter") {
      publishTextChange(PUBLISH_LINE_BREAK);
    } else if (event.key === "Backspace") {
      publishTextChange(event.target.value);
    } else if (event.key === "Escape") {
      publishTextChange(PUBLISH_LINE_BREAK);
    } else {
      publishTextChange(event.target.value);
    }
  }

  const macroByGroup = (group, macro) => {
    if (group === macro.macroGroup && macro.isEnabled) {
      return (
        <Row>
          <Col xs={12}>
            <InputGroup>
              <InputGroup.Text style={styles.macroList}>
                {macro.macroName}
              </InputGroup.Text>
              <InputGroup.Text style={styles.macroList}>
                {macro.triggerWord}
              </InputGroup.Text>
              <InputGroup.Text style={styles.macroList}>
                {macro.insertWord}
              </InputGroup.Text>
            </InputGroup>
          </Col>
        </Row>
      );
    }
  };

  const macroList = macroGroupArray.map((group, idx) => (
    <Container key={idx} style={styles.listContainer}>
      <div style={styles.groupName}>{group}</div>
      <InputGroup style={styles.inputGroup}>
        <InputGroup.Text style={styles.title}>Name</InputGroup.Text>
        <InputGroup.Text style={styles.title}>Trigger</InputGroup.Text>
        <InputGroup.Text style={styles.title}>Insert</InputGroup.Text>
      </InputGroup>
      {macroArray.map((macro, idx) => (
        <div key={idx}>{macroByGroup(group, macro, idx)}</div>
      ))}
    </Container>
  ));

  return (
    <Card>
      <Card.Header as="h5">
        Input{" "}
        <span style={{ fontSize: "15px", fontWeight: "normal" }}>
          ({focusInputKey})
        </span>
      </Card.Header>
      <Card.Body className="m-0 pb-2">
        <Container fluid className="p-0 m-0">
          <Row>
            <InputGroup className="mb-2">
              <Form.Control
                id="chat-input-field"
                type="text"
                placeholder="Enter message"
                ref={ref}
                onChange={(event) => {
                  handleMacro(event);
                  // setTextInput(event.target.value);
                }}
                value={textInput}
                autoFocus="autofocus"
                // onBlur={focusOnBlur}
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    handleSendMessage(event);
                  } else if (event.key === "Escape") {
                    setTextInput("");
                  }
                  handleTextsInputChange(event);
                }}
              />
              <Button
                variant="primary"
                onClick={handleSendMessage}
                disabled={textInput === ""}
              >
                Send
              </Button>
            </InputGroup>
            <div>
              <span
                style={{
                  float: "right",
                  width: "fit-content",
                  cursor: "pointer",
                  color: "gray",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  setShowUserMacro((prev) => !prev);
                }}
              >
                {showUserMacro ? "hide user macro" : "show user macro"}
              </span>
              <span
                style={
                  textInput.length <= maxCount ? styles.within : styles.exceed
                }
              >{`${textInput.length}/${maxCount}`}</span>
            </div>
            {showUserMacro ? macroList : null}
            {/* Typing : {typingWord} */}
            {/* {props.translatedWord} */}
            {/* <Button
              onClick={() => {
                insertShortcut("Yo");
              }}
            >
              Insert Shortcut
            </Button> */}
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}

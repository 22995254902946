// @flow

import { getSession } from "./../graphql/queries";
import { API } from "aws-amplify";
import React, { useState, useEffect } from "react";
import MessagesView from "./MessagesView.react";
import { Container, Row, Col } from "react-bootstrap";

import Card from "react-bootstrap/Card";
import ChatInputView from "./ChatInputView.react";
import SessionUsersView from "./SessionUsersView.react";
import ShortcutsView from "./ShortcutsView.react";

type Props = $ReadOnly<{
  sessionId: ?string,
}>;

export default function TranslatorViewLite(props: Props): React$Element<any> {
  if (props.sessionId == null) {
    return <div>Invalid Session</div>;
  }
  return (
    <Container fluid>
      <Row className="p-0">
        <ChatInputView sessionId={props.sessionId} />
      </Row>
    </Container>
  );
}

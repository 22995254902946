// @flow

import { API, PubSub } from "aws-amplify";
import React, { useState, useEffect, useContext, useRef } from "react";
import { Container, Row, Col, ProgressBar } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import ToggleButton from 'react-bootstrap/ToggleButton';
import { createMessage as createMessageMutation } from "./../graphql/mutations";
import { queryShortcutBindingsByKeyBindingSessionIdIndex } from "./../graphql/queries";
import { onUpdateShortcutBindingBySession } from "./../graphql/subscriptions";

import { DEFAULT_MESSAGE_TYPE } from "./../utils/util";
import { publishUserJoined } from "./../utils/SessionUsers";
import UserContext from "./../utils/UserContext";
import { getSessionUsernameTopic } from "../utils/SessionUsers";
import { PUBLISH_LINE_BREAK } from "../utils/util";

// Copied from Session Users View
import { useInterval } from "./../utils/util";

type Props = $ReadOnly<{
  sessionId: string,
  username: object,
  youtubeUrl: string,
  sheetsInput: string,
  sheetsInputIndex: number,
  prevDisplay: boolean,
  minSeconds: number,
  maxSeconds: number,
  delay: number,
  holdTime: number,
  bufferTime: number,
  addedDelaySecs: number,
  setPrevDisplay: React.Dispatch<React.SetStateAction<any>>,
}>;

async function createMessage(sessionId: string, text: string) {
  if (sessionId === "") {
    return;
  }
  const ret = await API.graphql({
    query: createMessageMutation,
    variables: {
      input: {
        sessionMessagesId: sessionId,
        text,
        type: DEFAULT_MESSAGE_TYPE,
      },
    },
  });
}

async function fetchBindings(sessionId) {
  try {
    const apiData = await API.graphql({
      query: queryShortcutBindingsByKeyBindingSessionIdIndex,
      variables: {
        keyBindingSessionId: sessionId,
      },
    });
    return apiData.data?.queryShortcutBindingsByKeyBindingSessionIdIndex?.items ?? [];
  } catch (e) {
    console.log(e);
  }
}

let timer;

export default function ChatInputView(props: Props): React$Element<any> {
  const [textInput, setTextInput] = useState("");
  const [bindings, setBindings] = useState([]);
  const userContext = useContext(UserContext);

  // useState hooks for sending POST requests and showing settings

  const [settingsChecked, setSettingsChecked] = useState(true);


  // useState hooks for preventing mixed up subs

  const [prevSendTime, setPrevSendTime] = useState(0);
  const [prevSubtext, setPrevSubtext] = useState("");


  // useState hooks for detecting partner input
  const [check, setCheck] = useState(0);
  const [partnerSendTime, setPartnerSendTime] = useState(0);
  const [subscriptionArray, setSubscriptionArray] = useState([]);

  // useState hook for no delay mode progress bar
  const [progress, setProgress] = useState(0);

  // prevent useEffect from running on first render
  const isMounted = useRef(false);
  const isMountedTwo = useRef(false);
  const ref = useRef(null);

  // variables
  const subtext = textInput;
  const addedDelaySeconds = props.addedDelaySecs;
  const packetLength = addedDelaySeconds - props.holdTime;
  const partnerInterval = (partnerSendTime - prevSendTime) / 1000;

  // To subscribe to subtitle submits from any partner & refresh every 10 sec
  useInterval(() => {
    subscriptionArray.forEach((sub) => sub.unsubscribe());
    const newSubscriptionArray = [];
    props.username.forEach((object) => {
      const topic = getSessionUsernameTopic(props.sessionId, object.username);
      const sub = PubSub.subscribe(topic).subscribe({
        next: (data) => {
          if (data.value === PUBLISH_LINE_BREAK) {
            setPartnerSendTime(new Date().getTime())
            setCheck(prevCheck => prevCheck + 1);
          }
        },
        error: (error) => console.error(error),
        complete: () => console.log("Done"),
      });
      newSubscriptionArray.push(sub);
    });
    setSubscriptionArray(newSubscriptionArray);
  }, 10000
  )

  // If partner submits message within packet time, change my previous POST body to single timestamp
  useEffect(() => {
    if (isMounted.current) {
      if (partnerInterval < packetLength - props.bufferTime) {
        clearTimeout(timer);
        setTimeout(prevSubtextSingleTimestamp, (props.holdTime - partnerInterval) * 1000);
      }
    } else {
      isMounted.current = true;
    }
  }, [check])

  // Sends a POST request when a line is submitted from SheetsView component
  useEffect(() => {
    if (isMountedTwo.current) {
      setPrevSendTime(new Date().getTime());
      setPrevSubtext(props.sheetsInput);
      sendPost(props.sheetsInput);
      publishTextChange(PUBLISH_LINE_BREAK);
    } else {
      isMountedTwo.current = true;
    }
  }, [props.sheetsInput, props.sheetsInputIndex])

  // Inits the sessionUsers object and publishes the user connection status
  useEffect(
    () => {
      publishUserJoined(props.sessionId, userContext.username, false);
      setInterval(() => {
        publishUserJoined(props.sessionId, userContext.username, true);
      }, 30000);
    },
    // Do not include anything else in the dependency list
    [props.sessionId, userContext]
  );

  useEffect(() => {
    async function initialFetch() {
      const fetchedBindings = await fetchBindings(props.sessionId);
      setBindings(fetchedBindings);
    }
    initialFetch();
  }, [props.sessionId]);

  // Subscribe to update shortcut by session
  useEffect(() => {
    const subscription = API.graphql({
      query: onUpdateShortcutBindingBySession,
      variables: {
        keyBindingSessionId: props.sessionId,
      },
    }).subscribe({
      next: () => {
        async function updateFetch() {
          const fetchedBindings = await fetchBindings(props.sessionId);
          setBindings(fetchedBindings);
        }
        updateFetch();
      },
      error: (error) => console.warn(error),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleShortcutPress);
    return () => {
      window.removeEventListener("keydown", handleShortcutPress);
    }
  }, [bindings]);

  function handleShortcutPress(event) {
    bindings.map((binding) => {
      if (event.ctrlKey) {
        if (event.key === binding.key) {
          console.log('shortcut triggered');
          event.preventDefault();
          setTextInput(prevTextInput => prevTextInput + binding.text)
          ref.current.focus()
          // props.setShortcutInput(binding.text);
          // props.setShortcutInputIndex(prevShortcutInputIndex => prevShortcutInputIndex + 1)
        }
      }
    }
    )
  }



  // Shortcut input by button click, inefficient because this re-renders sheet

  // Multi timestamp POST body in added delay mode
  function addedDelayMultiTimestamp(subtext) {

    const length = subtext.length;
    const lastIndex = subtext.lastIndexOf(" ");
    let displayLength;
    let data;

    function getUTCString() {
      const t = new Date();
      t.setSeconds(t.getSeconds() + props.delay - props.holdTime);
      return t.toISOString();
    }

    function unixTimeSeq() {
      return Math.floor(new Date().getTime() / 1000);
    }

    const url = `${props.youtubeUrl}&seq=${unixTimeSeq()}`;

    if (length <= 8) {
      displayLength = props.minSeconds;
    } else if (length > 55) {
      displayLength = props.maxSeconds;
    } else {
      displayLength =
        (props.maxSeconds - props.minSeconds) * (length / 55) + props.minSeconds;
    }

    if (lastIndex === -1) {

      data = getUTCString().slice(0, -1) + "\n" + "<br>" + subtext + "<br>" + "\n";

    } else if (length <= 55) {

      function getUTCString2() {
        const t2 = new Date();
        t2.setSeconds(t2.getSeconds() + props.delay - props.holdTime + displayLength);
        return t2.toISOString();
      }

      if (displayLength <= 10) {

        const subtextArr = subtext.split(' ');
        const subtext1Len = Math.floor(subtextArr.length / 2);
        const subtext1 = subtextArr.slice(0, subtext1Len).join(' ');
        const subtext2 = subtextArr.slice(-1 * (subtextArr.length - subtext1Len)).join(' ');

        data = getUTCString().slice(0, -1) + "\n" + "<br>" + subtext1 + "\n" + getUTCString2().slice(0, -1) + "\n" + subtext2 + "<br>" + "\n";

      } else if (displayLength > 10) {

        data = getUTCString().slice(0, -1) + "\n" + "<br>" + subtext + "\n" + getUTCString2().slice(0, -1) + "\n" + " " + "<br>" + "\n";

      }

    } else if (length > 55) {

      const wordCount = subtext.split(" ").length;
      const subtext1WordCount = Math.floor(wordCount / 2);
      const subtext1Array = subtext.split(" ", subtext1WordCount);
      const subtext1 = subtext1Array.join(" ");
      const subtext2 = subtext.split(" ").slice(subtext1WordCount, wordCount - 1).join(" ");
      const subtext3 = subtext.split(" ").slice(-1).join(" ");

      function getUTCString2() {
        const t2 = new Date();
        t2.setSeconds(t2.getSeconds() + props.delay - props.holdTime + Math.ceil(displayLength / 2));
        return t2.toISOString();
      }

      function getUTCString3() {
        const t3 = new Date();
        t3.setSeconds(t3.getSeconds() + props.delay - props.holdTime + displayLength);
        return t3.toISOString();
      }

      data = getUTCString().slice(0, -1) + "\n" + "<br>" + subtext1 + "\n" + getUTCString2().slice(0, -1) + "\n" + "<br>" + subtext2 + "\n" + getUTCString3().slice(0, -1) + "\n" + subtext3 + "<br>" + "\n";

    }

    const xhr = new XMLHttpRequest();
    xhr.open("POST", url);
    xhr.setRequestHeader("Content-Type", "text/plain");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        console.log(xhr.status);
        console.log(xhr.responseText);
      }
    };

    console.log(`URL : ${url}`)
    console.log(`POST body : ${data}`);
    xhr.send(data);
  };

  function prevSubtextSingleTimestamp() {

    function getUTCString() {
      const t = new Date();
      t.setSeconds(t.getSeconds() + props.delay - props.holdTime);
      return t.toISOString();
    }

    function unixTimeSeq() {
      return Math.floor(new Date().getTime() / 1000);
    }

    const url = `${props.youtubeUrl}&seq=${unixTimeSeq()}`;

    const data = getUTCString().slice(0, -1) + "\n" + "<br>" + prevSubtext + "<br>" + "\n";

    const xhr = new XMLHttpRequest();
    xhr.open("POST", url);
    xhr.setRequestHeader("Content-Type", "text/plain");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        console.log(xhr.status);
        console.log(xhr.responseText);
      }
    };

    console.log(`URL : ${url}`)
    console.log(`POST body : ${data}`);
    xhr.send(data);
  };

  function noDelayMultiTimestamp(subtext) {
    const length = subtext.length;
    const lastIndex = subtext.lastIndexOf(" ");
    let displayLength;
    let data;

    function getUTCString() {
      const t = new Date();
      t.setSeconds(t.getSeconds() + props.delay);
      return t.toISOString();
    }

    function unixTimeSeq() {
      return Math.floor(new Date().getTime() / 1000);
    }

    const url = `${props.youtubeUrl}&seq=${unixTimeSeq()}`;

    if (length <= 8) {
      displayLength = props.minSeconds;
    } else if (length > 55) {
      displayLength = props.maxSeconds;
    } else {
      displayLength =
        Math.floor((props.maxSeconds - props.minSeconds) * (length / 55) + props.minSeconds);
    }

    if (lastIndex === -1) {

      data = getUTCString().slice(0, -1) + "\n" + "<br>" + subtext + "<br>" + "\n";

    } else if (length <= 55) {

      function getUTCString2() {
        const t2 = new Date();
        t2.setSeconds(t2.getSeconds() + props.delay + displayLength);
        return t2.toISOString();
      }

      if (displayLength <= 10) {

        const subtextArr = subtext.split(' ');
        const subtext1Len = Math.floor(subtextArr.length / 2);
        const subtext1 = subtextArr.slice(0, subtext1Len).join(' ');
        const subtext2 = subtextArr.slice(-1 * (subtextArr.length - subtext1Len)).join(' ');

        data = getUTCString().slice(0, -1) + "\n" + "<br>" + subtext1 + "\n" + getUTCString2().slice(0, -1) + "\n" + subtext2 + "<br>" + "\n";

      } else if (displayLength > 10) {

        data = getUTCString().slice(0, -1) + "\n" + "<br>" + subtext + "\n" + getUTCString2().slice(0, -1) + "\n" + " " + "\n";

      }


    } else if (length > 55) {

      const wordCount = subtext.split(" ").length;
      const subtext1WordCount = Math.floor(wordCount / 2);
      const subtext1Array = subtext.split(" ", subtext1WordCount);
      const subtext1 = subtext1Array.join(" ");
      const subtext2 = subtext.split(" ").slice(subtext1WordCount, wordCount - 1).join(" ");
      const subtext3 = subtext.split(" ").slice(-1).join(" ");

      function getUTCString2() {
        const t2 = new Date();
        t2.setSeconds(t2.getSeconds() + props.delay + Math.ceil(displayLength / 2));
        return t2.toISOString();
      }

      function getUTCString3() {
        const t3 = new Date();
        t3.setSeconds(t3.getSeconds() + props.delay + displayLength);
        return t3.toISOString();
      }

      data = getUTCString().slice(0, -1) + "\n" + "<br>" + subtext1 + "\n" + getUTCString2().slice(0, -1) + "\n" + "<br>" + subtext2 + "\n" + getUTCString3().slice(0, -1) + "\n" + subtext3 + "<br>" + "\n";

    }

    const xhr = new XMLHttpRequest();
    xhr.open("POST", url);
    xhr.setRequestHeader("Content-Type", "text/plain");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        console.log(xhr.status);
        console.log(xhr.responseText);
      }
    };

    console.log(`URL : ${url}`)
    console.log(`POST body : ${data}`);
    console.log(`Display Length : ${displayLength}`);
    xhr.send(data);

    // Disable send button
    props.setPrevDisplay(true);

    // Animate progress bar
    for (let i = 0; i < 20; i++) {
      setTimeout(() => { setProgress(prevProgress => prevProgress + 5) }, displayLength * 1000 / 20 * i);
    }

    // Enable send button again when previous subtitle expires
    setTimeout(() => {
      props.setPrevDisplay(false);
      setProgress(0);
    }, displayLength * 1000);
  }


  function sendPost(text) {

    const thisSendTime = new Date().getTime();
    const sendTimeInterval = (thisSendTime - prevSendTime) / 1000;

    if (props.addedDelay === true) {
      if (sendTimeInterval < packetLength - props.bufferTime) {
        if (partnerInterval > sendTimeInterval || partnerInterval < 0) {
          // timer의 console log 값이 계속 증가하는데.. 혹시 뭔가 부하가 걸리고 있는 건 아닌지?
          // console.log(`timer : ${timer}`);
          clearTimeout(timer);
          setTimeout(prevSubtextSingleTimestamp, (props.holdTime - sendTimeInterval) * 1000);
          timer = setTimeout(() => { addedDelayMultiTimestamp(text) }, props.holdTime * 1000);
        }
      } else {
        timer = setTimeout(() => { addedDelayMultiTimestamp(text) }, props.holdTime * 1000);
      }
    } else {
      /* 아래 코드는 혼자 noAddedDelay 세팅으로 작업할 때의 Send POST Function이며,
       현재 progress bar로 뒤섞임 방지 해 놓은 상태임 */
      noDelayMultiTimestamp(text)
    }
  }

  function handleSendMessage(event) {
    event.preventDefault();
    //  Don't create a message if the message is empty
    if (textInput === "") {
      return;
    }
    createMessage(props.sessionId, textInput);
    setTextInput("");

    setPrevSendTime(new Date().getTime());
    setPrevSubtext(textInput);
    sendPost(textInput);
    publishTextChange(PUBLISH_LINE_BREAK)
  }

  function handleTextsInputChange(event) {
    if (userContext.username === "") {
      return;
    }
    // console.log(event.target.value);
    if (event.key === "Enter") {
      // (JY) Commented out because of redundant trigger
      // publishTextChange(PUBLISH_LINE_BREAK);
    } else if (event.key === "Backspace") {
      publishTextChange(event.target.value);
    } else {
      publishTextChange(event.target.value);
    }
  }

  // Functions by SY as they were
  async function publishTextChange(text) {
    if (userContext.username === "") {
      return;
    }
    const topic = getSessionUsernameTopic(
      props.sessionId,
      userContext.username
    );
    await PubSub.publish(topic, text);
  }

  function handleSettings(e) {
    setSettingsChecked(e.currentTarget.checked);
    props.setShowSettings(prevShowSettings => !prevShowSettings)
  }



  return (
    <Card>
      <Card.Header as="h5">
        Input
      </Card.Header>
      <Card.Body className="m-0">
        <Container className="p-0 m-0">
          <Row>
            <Col>
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Enter message"
                  ref={ref}
                  onChange={(event) => {
                    setTextInput(event.target.value);
                  }}
                  value={textInput}
                  onKeyUp={(event) => {
                    if (event.key === "Enter" && !props.prevDisplay) {
                      handleSendMessage(event);
                    }
                    handleTextsInputChange(event);
                  }}
                />
                <Button
                  variant="primary"
                  onClick={handleSendMessage}
                  disabled={textInput === "" || props.prevDisplay}
                >
                  Send
                </Button>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            {!props.addedDelay && props.prevDisplay ? (
              <Col xs={9}>
                <ProgressBar
                  now={progress}
                  variant="success"
                >
                </ProgressBar>
              </Col>
            ) : null}
            {/* <Row>
              Check : {check}
            </Row>
            <Row>
              Part Send Time : {partnerSendTime}
            </Row>
            <Row>
              Prev Send Time : {prevSendTime}
            </Row>
            <Row>
              Prev Subtext : {prevSubtext}
            </Row>
            <Row>
              YouTube Added Delay : {props.addedDelaySecs}
            </Row>
            <Row>
              Youtube URL : {props.youtubeUrl}
            </Row> */}
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}

// @flow
import { API } from "aws-amplify";

import React from "react";
import { useEffect, useState, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  queryShortcutBindingsByKeyBindingSessionIdIndex,
  queryUserMacrosByUserIdTriggerWordIndex,
} from "./../graphql/queries";
import { onUpdateShortcutBindingBySession } from "./../graphql/subscriptions";
import { createShortcutBinding as createShortcutBindingMutation } from "../graphql/mutations";

import UserContext from "./../utils/UserContext";

import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import EditShortcutsView from "./EditShortcutsView.react";
import { InputGroup } from "react-bootstrap";

type Props = $ReadOnly<{
  sessionId: string,
  setShortcutInput: React.Dispatch<React.SetStateAction<any>>,
  setShortcutInputIndex: React.Dispatch<React.SetStateAction<any>>,
}>;

const styles = {
  title: {
    width: "150px",
    fontSize: "10.5pt",
    padding: "3px",
    fontWeight: 500,
    backgroundColor: "white",
    // border: "1px solid black",
    cursor: "default",
  },
  macroList: {
    width: "150px",
    fontSize: "10.5pt",
    padding: "3px",
    cursor: "default",
  },
  inputGroup: {
    cursor: "default",
  },
  groupName: {
    cursor: "default",
    fontWeight: 600,
  },
  listContainer: { paddingBottom: "10px" },
};

async function fetchBindings(sessionId) {
  try {
    const apiData = await API.graphql({
      query: queryShortcutBindingsByKeyBindingSessionIdIndex,
      variables: {
        keyBindingSessionId: sessionId,
      },
    });
    return (
      apiData.data?.queryShortcutBindingsByKeyBindingSessionIdIndex?.items ?? []
    );
  } catch (e) {
    console.log(e);
  }
}

async function createShortcutBinding(
  sessionId: string,
  index: number,
  key,
  text: string
) {
  if (sessionId === "") {
    return;
  }
  const ret = await API.graphql({
    query: createShortcutBindingMutation,
    variables: {
      input: {
        keyBindingSessionId: sessionId,
        index: index,
        key: key,
        ctrl: true,
        text: text,
      },
    },
  });
}

async function fetchUserMacro(userId) {
  try {
    const apiData = await API.graphql({
      query: queryUserMacrosByUserIdTriggerWordIndex,
      variables: {
        userId: userId,
      },
    });
    return apiData.data.queryUserMacrosByUserIdTriggerWordIndex.items;
  } catch (e) {
    console.log(e);
  }
}

export default function ShortcutsView(props: Props): React$Element<any> {
  const [showEditModal, setShowEditModal] = useState(false);
  const [bindings, setBindings] = useState([]);
  const [macroArray, setMacroArray] = useState([]);
  const [macroGroupArray, setMacroGroupArray] = useState([]);
  const userContext = useContext(UserContext);

  // Initial fetch, create shortcut bindings when the session is first loaded
  useEffect(() => {
    async function initialFetch() {
      const fetchedBindings = await fetchBindings(props.sessionId);
      if (fetchedBindings.length === 0) {
        for (let i = 0; i < 10; i++) {
          if (i === 9) {
            createShortcutBinding(props.sessionId, i + 1, 0, "");
          } else {
            createShortcutBinding(props.sessionId, i + 1, i + 1, "");
          }
        }
        setTimeout(() => {
          async function initialFetch() {
            const fetchedBindings = await fetchBindings(props.sessionId);
            setBindings(fetchedBindings);
          }
          initialFetch();
        }, 1000);
      } else {
        setBindings(fetchedBindings);
      }
    }
    initialFetch();
  }, [props.sessionId]);

  // Subscribe to update shortcut by session
  useEffect(() => {
    const subscription = API.graphql({
      query: onUpdateShortcutBindingBySession,
      variables: {
        keyBindingSessionId: props.sessionId,
      },
    }).subscribe({
      next: () => {
        async function updateFetch() {
          const fetchedBindings = await fetchBindings(props.sessionId);
          setBindings(fetchedBindings);
        }
        updateFetch();
      },
      error: (error) => console.warn(error),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // Initial macro fetch
  useEffect(() => {
    handleFetchMacro();
  }, []);

  // useEffect(() => {
  //   window.addEventListener("keydown", handleShortcutPress);
  //   return () => {
  //     window.removeEventListener("keydown", handleShortcutPress);
  //   }
  // }, [bindings]);

  // function handleShortcutPress(event) {
  //   bindings.map((binding) => {
  //     if (event.ctrlKey) {
  //       if (event.key === binding.key) {
  //         console.log('triggered');
  //         event.preventDefault();
  //         props.setShortcutInput(binding.text);
  //         props.setShortcutInputIndex(prevShortcutInputIndex => prevShortcutInputIndex + 1)
  //       }
  //     }
  //   }
  //   )
  // }

  const macroByGroup = (group, macro) => {
    if (group === macro.macroGroup && macro.isEnabled) {
      return (
        <Row>
          <Col xs={12}>
            <InputGroup>
              <InputGroup.Text style={styles.macroList}>
                {macro.macroName}
              </InputGroup.Text>
              <InputGroup.Text style={styles.macroList}>
                {macro.triggerWord}
              </InputGroup.Text>
              <InputGroup.Text style={styles.macroList}>
                {macro.insertWord}
              </InputGroup.Text>
            </InputGroup>
          </Col>
        </Row>
      );
    }
  };

  const macroList = macroGroupArray.map((group, idx) => (
    <Container key={idx} style={styles.listContainer}>
      <div style={styles.groupName}>{group}</div>
      <InputGroup style={styles.inputGroup}>
        <InputGroup.Text style={styles.title}>Name</InputGroup.Text>
        <InputGroup.Text style={styles.title}>Trigger</InputGroup.Text>
        <InputGroup.Text style={styles.title}>Insert</InputGroup.Text>
      </InputGroup>
      {macroArray.map((macro, idx) => (
        <div key={idx}>{macroByGroup(group, macro, idx)}</div>
      ))}
    </Container>
  ));

  const makeMacroGroupArray = (macro: array) => {
    let arr = [];
    for (let i = 0; i < macro.length; i++) {
      if (!arr.includes(macro[i].macroGroup) && macro[i].isEnabled) {
        arr.push(macro[i].macroGroup);
      }
    }
    arr.sort();
    setMacroGroupArray(arr);
  };

  const handleFetchMacro = () => {
    async function updateFetch() {
      const macro = await fetchUserMacro(userContext.userId);
      setMacroArray(macro);
      makeMacroGroupArray(macro);
    }
    updateFetch();
  };

  return (
    <>
      <Card
        style={{
          maxHeight: "550px",
          overflowY: "hidden",
          textAlign: "center",
        }}
      >
        <Card.Header as="h5">
          Ctrl
          <br />
          <Button
            variant="outline-secondary"
            size="sm"
            style={{
              fontSize: "0.8rem",
              width: "50px",
            }}
            // disabled
            onClick={() => {
              setShowEditModal(true);
            }}
          >
            Edit
          </Button>
        </Card.Header>
        <Card.Body className="m-0 py-2">
          <Container className="p-0 m-0">
            <Row>
              {bindings.map((binding, idx) => (
                <Button
                  key={idx}
                  variant="outline-secondary"
                  type="text"
                  style={{
                    textAlign: "left",
                    fontSize: "0.9rem",
                    padding: "1.1px",
                    paddingLeft: "5px",
                  }}
                  onClick={() => {
                    // console.log('click shortcut triggered');
                    props.setShortcutInput(binding.text);
                    props.setShortcutInputIndex(
                      (prevShortcutInputIndex) => prevShortcutInputIndex + 1
                    );
                  }}
                >
                  <div
                    title={binding.text}
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {binding.key} {binding.text}
                  </div>
                </Button>
              ))}
            </Row>
            {/* {macroList} */}
          </Container>
        </Card.Body>
      </Card>

      <EditShortcutsView
        sessionId={props.sessionId}
        show={showEditModal}
        onHide={() => {
          setShowEditModal(false);
        }}
      />
    </>
  );
}

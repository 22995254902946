/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateArtist = /* GraphQL */ `
  subscription OnCreateArtist(
    $chName: String
    $enName: String
    $id: String
    $jaName: String
    $krName: String
  ) {
    onCreateArtist(
      chName: $chName
      enName: $enName
      id: $id
      jaName: $jaName
      krName: $krName
    ) {
      chName
      enName
      id
      jaName
      krName
      team
      __typename
    }
  }
`;
export const onCreateBroadcastSetting = /* GraphQL */ `
  subscription OnCreateBroadcastSetting {
    onCreateBroadcastSetting {
      borderOpacity
      createdAt
      dualSessionId
      id
      isDemoText
      isNoWrap
      lineNumber
      marginBottom
      multiSessionIdList
      screenColor
      screenHeight
      screenOpacity
      screenWidth
      subAlign
      subBackgroundColor
      subBackgroundOpacity
      subColor
      subFont
      subLineHeight
      subMaxDisplaySeconds
      subMaxThresholdCharacters
      subMinDisplaySeconds
      subMinThresholdCharacters
      subSize
      textShadowBlur
      textShadowWidth
      updatedAt
      __typename
    }
  }
`;
export const onCreateKeyBinding = /* GraphQL */ `
  subscription OnCreateKeyBinding {
    onCreateKeyBinding {
      alt
      createdAt
      ctrl
      id
      key
      keyBindingSessionId
      shift
      text
      updatedAt
      userKeyBindingsId
      user
      session
      __typename
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      createdAt
      id
      scriptIndex
      sessionMessagesId
      text
      type
      updatedAt
      username
      __typename
    }
  }
`;
export const onCreateMessageBySession = /* GraphQL */ `
  subscription OnCreateMessageBySession($sessionMessagesId: String!) {
    onCreateMessageBySession(sessionMessagesId: $sessionMessagesId) {
      createdAt
      id
      scriptIndex
      sessionMessagesId
      text
      type
      updatedAt
      username
      __typename
    }
  }
`;
export const onCreateSession = /* GraphQL */ `
  subscription OnCreateSession {
    onCreateSession {
      broadcastSetting {
        borderOpacity
        createdAt
        dualSessionId
        id
        isDemoText
        isNoWrap
        lineNumber
        marginBottom
        multiSessionIdList
        screenColor
        screenHeight
        screenOpacity
        screenWidth
        subAlign
        subBackgroundColor
        subBackgroundOpacity
        subColor
        subFont
        subLineHeight
        subMaxDisplaySeconds
        subMaxThresholdCharacters
        subMinDisplaySeconds
        subMinThresholdCharacters
        subSize
        textShadowBlur
        textShadowWidth
        updatedAt
        __typename
      }
      createdAt
      id
      messages {
        items {
          createdAt
          id
          scriptIndex
          sessionMessagesId
          text
          type
          updatedAt
          username
          __typename
        }
        nextToken
        __typename
      }
      name
      sessionBroadcastSettingId
      sheetsUpdateCount
      sheetsUrl
      streamUrl
      type
      updatedAt
      __typename
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      allowAllAccess
      createdAt
      id
      isSuperAdmin
      keyBindings {
        items {
          alt
          createdAt
          ctrl
          id
          key
          keyBindingSessionId
          shift
          text
          updatedAt
          userKeyBindingsId
          user
          session
          __typename
        }
        nextToken
        __typename
      }
      name
      updatedAt
      username
      isBroadcaster
      __typename
    }
  }
`;
export const onCreateYoutubeSettings = /* GraphQL */ `
  subscription OnCreateYoutubeSettings($id: String, $url: String) {
    onCreateYoutubeSettings(id: $id, url: $url) {
      addedDelay
      addedDelayOn
      id
      url
      __typename
    }
  }
`;
export const onDeleteArtist = /* GraphQL */ `
  subscription OnDeleteArtist(
    $chName: String
    $enName: String
    $id: String
    $jaName: String
    $krName: String
  ) {
    onDeleteArtist(
      chName: $chName
      enName: $enName
      id: $id
      jaName: $jaName
      krName: $krName
    ) {
      chName
      enName
      id
      jaName
      krName
      team
      __typename
    }
  }
`;
export const onDeleteBroadcastSetting = /* GraphQL */ `
  subscription OnDeleteBroadcastSetting {
    onDeleteBroadcastSetting {
      borderOpacity
      createdAt
      dualSessionId
      id
      isDemoText
      isNoWrap
      lineNumber
      marginBottom
      multiSessionIdList
      screenColor
      screenHeight
      screenOpacity
      screenWidth
      subAlign
      subBackgroundColor
      subBackgroundOpacity
      subColor
      subFont
      subLineHeight
      subMaxDisplaySeconds
      subMaxThresholdCharacters
      subMinDisplaySeconds
      subMinThresholdCharacters
      subSize
      textShadowBlur
      textShadowWidth
      updatedAt
      __typename
    }
  }
`;
export const onDeleteKeyBinding = /* GraphQL */ `
  subscription OnDeleteKeyBinding {
    onDeleteKeyBinding {
      alt
      createdAt
      ctrl
      id
      key
      keyBindingSessionId
      shift
      text
      updatedAt
      userKeyBindingsId
      user
      session
      __typename
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      createdAt
      id
      scriptIndex
      sessionMessagesId
      text
      type
      updatedAt
      username
      __typename
    }
  }
`;
export const onDeleteSession = /* GraphQL */ `
  subscription OnDeleteSession {
    onDeleteSession {
      broadcastSetting {
        borderOpacity
        createdAt
        dualSessionId
        id
        isDemoText
        isNoWrap
        lineNumber
        marginBottom
        multiSessionIdList
        screenColor
        screenHeight
        screenOpacity
        screenWidth
        subAlign
        subBackgroundColor
        subBackgroundOpacity
        subColor
        subFont
        subLineHeight
        subMaxDisplaySeconds
        subMaxThresholdCharacters
        subMinDisplaySeconds
        subMinThresholdCharacters
        subSize
        textShadowBlur
        textShadowWidth
        updatedAt
        __typename
      }
      createdAt
      id
      messages {
        items {
          createdAt
          id
          scriptIndex
          sessionMessagesId
          text
          type
          updatedAt
          username
          __typename
        }
        nextToken
        __typename
      }
      name
      sessionBroadcastSettingId
      sheetsUpdateCount
      sheetsUrl
      streamUrl
      type
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      allowAllAccess
      createdAt
      id
      isSuperAdmin
      keyBindings {
        items {
          alt
          createdAt
          ctrl
          id
          key
          keyBindingSessionId
          shift
          text
          updatedAt
          userKeyBindingsId
          user
          session
          __typename
        }
        nextToken
        __typename
      }
      name
      updatedAt
      username
      isBroadcaster
      __typename
    }
  }
`;
export const onDeleteYoutubeSettings = /* GraphQL */ `
  subscription OnDeleteYoutubeSettings($id: String, $url: String) {
    onDeleteYoutubeSettings(id: $id, url: $url) {
      addedDelay
      addedDelayOn
      id
      url
      __typename
    }
  }
`;
export const onUpdateArtist = /* GraphQL */ `
  subscription OnUpdateArtist(
    $chName: String
    $enName: String
    $id: String
    $jaName: String
    $krName: String
  ) {
    onUpdateArtist(
      chName: $chName
      enName: $enName
      id: $id
      jaName: $jaName
      krName: $krName
    ) {
      chName
      enName
      id
      jaName
      krName
      team
      __typename
    }
  }
`;
export const onUpdateBroadcastSetting = /* GraphQL */ `
  subscription OnUpdateBroadcastSetting {
    onUpdateBroadcastSetting {
      borderOpacity
      createdAt
      dualSessionId
      id
      isDemoText
      isNoWrap
      lineNumber
      marginBottom
      multiSessionIdList
      screenColor
      screenHeight
      screenOpacity
      screenWidth
      subAlign
      subBackgroundColor
      subBackgroundOpacity
      subColor
      subFont
      subLineHeight
      subMaxDisplaySeconds
      subMaxThresholdCharacters
      subMinDisplaySeconds
      subMinThresholdCharacters
      subSize
      textShadowBlur
      textShadowWidth
      updatedAt
      __typename
    }
  }
`;
export const onUpdateKeyBinding = /* GraphQL */ `
  subscription OnUpdateKeyBinding {
    onUpdateKeyBinding {
      alt
      createdAt
      ctrl
      id
      key
      keyBindingSessionId
      shift
      text
      updatedAt
      userKeyBindingsId
      user
      session
      __typename
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      createdAt
      id
      scriptIndex
      sessionMessagesId
      text
      type
      updatedAt
      username
      __typename
    }
  }
`;
export const onUpdateSession = /* GraphQL */ `
  subscription OnUpdateSession {
    onUpdateSession {
      broadcastSetting {
        borderOpacity
        createdAt
        dualSessionId
        id
        isDemoText
        isNoWrap
        lineNumber
        marginBottom
        multiSessionIdList
        screenColor
        screenHeight
        screenOpacity
        screenWidth
        subAlign
        subBackgroundColor
        subBackgroundOpacity
        subColor
        subFont
        subLineHeight
        subMaxDisplaySeconds
        subMaxThresholdCharacters
        subMinDisplaySeconds
        subMinThresholdCharacters
        subSize
        textShadowBlur
        textShadowWidth
        updatedAt
        __typename
      }
      createdAt
      id
      messages {
        items {
          createdAt
          id
          scriptIndex
          sessionMessagesId
          text
          type
          updatedAt
          username
          __typename
        }
        nextToken
        __typename
      }
      name
      sessionBroadcastSettingId
      sheetsUpdateCount
      sheetsUrl
      streamUrl
      type
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      allowAllAccess
      createdAt
      id
      isSuperAdmin
      keyBindings {
        items {
          alt
          createdAt
          ctrl
          id
          key
          keyBindingSessionId
          shift
          text
          updatedAt
          userKeyBindingsId
          user
          session
          __typename
        }
        nextToken
        __typename
      }
      name
      updatedAt
      username
      isBroadcaster
      __typename
    }
  }
`;
export const onUpdateYoutubeSettings = /* GraphQL */ `
  subscription OnUpdateYoutubeSettings($id: String) {
    onUpdateYoutubeSettings(id: $id) {
      addedDelay
      addedDelayOn
      id
      url
      __typename
    }
  }
`;
export const onCreateUserKeyCommand = /* GraphQL */ `
  subscription OnCreateUserKeyCommand(
    $id: String
    $commandName: String
    $userId: String
    $triggerKey: String
    $isEnabled: Boolean
  ) {
    onCreateUserKeyCommand(
      id: $id
      commandName: $commandName
      userId: $userId
      triggerKey: $triggerKey
      isEnabled: $isEnabled
    ) {
      id
      commandName
      userId
      triggerKey
      isEnabled
      __typename
    }
  }
`;
export const onUpdateUserKeyCommand = /* GraphQL */ `
  subscription OnUpdateUserKeyCommand(
    $id: String
    $commandName: String
    $userId: String
    $triggerKey: String
    $isEnabled: Boolean
  ) {
    onUpdateUserKeyCommand(
      id: $id
      commandName: $commandName
      userId: $userId
      triggerKey: $triggerKey
      isEnabled: $isEnabled
    ) {
      id
      commandName
      userId
      triggerKey
      isEnabled
      __typename
    }
  }
`;
export const onDeleteUserKeyCommand = /* GraphQL */ `
  subscription OnDeleteUserKeyCommand(
    $id: String
    $commandName: String
    $userId: String
    $triggerKey: String
    $isEnabled: Boolean
  ) {
    onDeleteUserKeyCommand(
      id: $id
      commandName: $commandName
      userId: $userId
      triggerKey: $triggerKey
      isEnabled: $isEnabled
    ) {
      id
      commandName
      userId
      triggerKey
      isEnabled
      __typename
    }
  }
`;
export const onCreateUserMacro = /* GraphQL */ `
  subscription OnCreateUserMacro(
    $id: String
    $triggerWord: String
    $userId: String
    $insertWord: String
    $isEnabled: Boolean
  ) {
    onCreateUserMacro(
      id: $id
      triggerWord: $triggerWord
      userId: $userId
      insertWord: $insertWord
      isEnabled: $isEnabled
    ) {
      id
      triggerWord
      userId
      insertWord
      isEnabled
      macroGroup
      macroName
      __typename
    }
  }
`;
export const onUpdateUserMacro = /* GraphQL */ `
  subscription OnUpdateUserMacro(
    $id: String
    $triggerWord: String
    $userId: String
    $insertWord: String
    $isEnabled: Boolean
  ) {
    onUpdateUserMacro(
      id: $id
      triggerWord: $triggerWord
      userId: $userId
      insertWord: $insertWord
      isEnabled: $isEnabled
    ) {
      id
      triggerWord
      userId
      insertWord
      isEnabled
      macroGroup
      macroName
      __typename
    }
  }
`;
export const onDeleteUserMacro = /* GraphQL */ `
  subscription OnDeleteUserMacro(
    $id: String
    $triggerWord: String
    $userId: String
    $insertWord: String
    $isEnabled: Boolean
  ) {
    onDeleteUserMacro(
      id: $id
      triggerWord: $triggerWord
      userId: $userId
      insertWord: $insertWord
      isEnabled: $isEnabled
    ) {
      id
      triggerWord
      userId
      insertWord
      isEnabled
      macroGroup
      macroName
      __typename
    }
  }
`;
export const onCreateShortcutBinding = /* GraphQL */ `
  subscription OnCreateShortcutBinding(
    $id: String
    $index: Int
    $keyBindingSessionId: String
    $userKeyBindingsId: String
    $ctrl: Boolean
  ) {
    onCreateShortcutBinding(
      id: $id
      index: $index
      keyBindingSessionId: $keyBindingSessionId
      userKeyBindingsId: $userKeyBindingsId
      ctrl: $ctrl
    ) {
      id
      index
      keyBindingSessionId
      userKeyBindingsId
      ctrl
      key
      text
      __typename
    }
  }
`;
export const onUpdateShortcutBinding = /* GraphQL */ `
  subscription OnUpdateShortcutBinding(
    $id: String
    $index: Int
    $keyBindingSessionId: String
    $userKeyBindingsId: String
    $ctrl: Boolean
  ) {
    onUpdateShortcutBinding(
      id: $id
      index: $index
      keyBindingSessionId: $keyBindingSessionId
      userKeyBindingsId: $userKeyBindingsId
      ctrl: $ctrl
    ) {
      id
      index
      keyBindingSessionId
      userKeyBindingsId
      ctrl
      key
      text
      __typename
    }
  }
`;
export const onUpdateShortcutBindingBySession = /* GraphQL */ `
  subscription OnUpdateShortcutBindingBySession($keyBindingSessionId: String!) {
    onUpdateShortcutBindingBySession(
      keyBindingSessionId: $keyBindingSessionId
    ) {
      id
      index
      keyBindingSessionId
      userKeyBindingsId
      ctrl
      key
      text
      __typename
    }
  }
`;
export const onDeleteShortcutBinding = /* GraphQL */ `
  subscription OnDeleteShortcutBinding(
    $id: String
    $index: Int
    $keyBindingSessionId: String
    $userKeyBindingsId: String
    $ctrl: Boolean
  ) {
    onDeleteShortcutBinding(
      id: $id
      index: $index
      keyBindingSessionId: $keyBindingSessionId
      userKeyBindingsId: $userKeyBindingsId
      ctrl: $ctrl
    ) {
      id
      index
      keyBindingSessionId
      userKeyBindingsId
      ctrl
      key
      text
      __typename
    }
  }
`;
export const onCreateScriptSheets = /* GraphQL */ `
  subscription OnCreateScriptSheets(
    $id: String
    $index: Int
    $sessionSheetsId: String
    $name: String
    $script: String
  ) {
    onCreateScriptSheets(
      id: $id
      index: $index
      sessionSheetsId: $sessionSheetsId
      name: $name
      script: $script
    ) {
      id
      index
      sessionSheetsId
      name
      script
      translation
      backgroundColor
      textColor
      isEditable
      isSelected
      __typename
    }
  }
`;
export const onUpdateScriptSheets = /* GraphQL */ `
  subscription OnUpdateScriptSheets(
    $id: String
    $index: Int
    $sessionSheetsId: String
    $name: String
    $script: String
  ) {
    onUpdateScriptSheets(
      id: $id
      index: $index
      sessionSheetsId: $sessionSheetsId
      name: $name
      script: $script
    ) {
      id
      index
      sessionSheetsId
      name
      script
      translation
      backgroundColor
      textColor
      isEditable
      isSelected
      __typename
    }
  }
`;
export const onDeleteScriptSheets = /* GraphQL */ `
  subscription OnDeleteScriptSheets(
    $id: String
    $index: Int
    $sessionSheetsId: String
    $name: String
    $script: String
  ) {
    onDeleteScriptSheets(
      id: $id
      index: $index
      sessionSheetsId: $sessionSheetsId
      name: $name
      script: $script
    ) {
      id
      index
      sessionSheetsId
      name
      script
      translation
      backgroundColor
      textColor
      isEditable
      isSelected
      __typename
    }
  }
`;
export const onCreateBookmarks = /* GraphQL */ `
  subscription OnCreateBookmarks(
    $id: String
    $row: Int
    $sessionId: String
    $color: String
    $title: String
  ) {
    onCreateBookmarks(
      id: $id
      row: $row
      sessionId: $sessionId
      color: $color
      title: $title
    ) {
      id
      row
      sessionId
      color
      title
      __typename
    }
  }
`;
export const onUpdateBookmarks = /* GraphQL */ `
  subscription OnUpdateBookmarks(
    $id: String
    $row: Int
    $sessionId: String
    $color: String
    $title: String
  ) {
    onUpdateBookmarks(
      id: $id
      row: $row
      sessionId: $sessionId
      color: $color
      title: $title
    ) {
      id
      row
      sessionId
      color
      title
      __typename
    }
  }
`;
export const onDeleteBookmarks = /* GraphQL */ `
  subscription OnDeleteBookmarks(
    $id: String
    $row: Int
    $sessionId: String
    $color: String
    $title: String
  ) {
    onDeleteBookmarks(
      id: $id
      row: $row
      sessionId: $sessionId
      color: $color
      title: $title
    ) {
      id
      row
      sessionId
      color
      title
      __typename
    }
  }
`;

// @flow

import UserContext from "../utils/UserContext";
import { API } from "aws-amplify";

import React from "react";
import { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { createShortcutBinding as createShortcutBindingMutation } from "./../graphql/mutations";
import { deleteShortcutBinding as deleteShortcutBindingMutation } from "./../graphql/mutations";
import { updateShortcutBinding as updateShortcutBindingMutation } from "./../graphql/mutations";
import { onUpdateShortcutBindingBySession } from "../graphql/subscriptions";
import { onCreateShortcutBindingBySession } from "../graphql/subscriptions";
import { queryShortcutBindingsByKeyBindingSessionIdIndex } from "./../graphql/queries";

import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import EditShortcutsBinding from "./EditShortcutsBinding.react";

type Props = $ReadOnly<{
  sessionId: string,
  show: boolean,
  onHide: (boolean) => void,
}>;



export default function ShortcutsView(props: Props): React$Element<any> {
  const [newKeyBinding, setNewKeyBinding] = useState("");
  const [waitingForNewBinding, setWaitingForNewBinding] = useState(false);
  const [bindings, setBindings] = useState([]);
  const [text, setText] = useState("");
  const [key, setKey] = useState("");
  const userContext = useContext(UserContext);
  const emptyBinding = {
    text: "",
    key: "",
    ctrl: false,
    alt: false,
    shift: false,
  };

  /* 
  Queries
*/

  // Subscribe to update shortcut by session
  useEffect(() => {
    const subscription = API.graphql({
      query: onUpdateShortcutBindingBySession,
      variables: {
        keyBindingSessionId: props.sessionId,
      },
    }).subscribe({
      next: () => {
        async function updateFetch() {
          const fetchedBindings = await fetchBindings(props.sessionId);
          setBindings(fetchedBindings);
        }
        updateFetch();
      },
      error: (error) => console.warn(error),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  async function fetchBindings(sessionId) {
    try {
      const apiData = await API.graphql({
        query: queryShortcutBindingsByKeyBindingSessionIdIndex,
        variables: {
          keyBindingSessionId: sessionId,
        },
      });
      return apiData.data?.queryShortcutBindingsByKeyBindingSessionIdIndex?.items ?? [];
    } catch (e) {
      console.log(e);
    }
  }

  async function createShortcutBinding(sessionId: string) {
    if (props.sessionId === "" || userContext.userId === "") {
      return;
    }
    const ret = await API.graphql({
      query: createShortcutBindingMutation,
      variables: {
        input: {
          text: text,
          key: key,
          ctrl: true,
          alt: false,
          shift: false,
          keyBindingSessionId: sessionId,
          userKeyBindingsId: userContext.userId,
        },
      },
    });
  }


  async function deleteShortcutBinding(id: string) {
    if (props.sessionId === "" || userContext.userId === "") {
      return;
    }
    console.log('delete');
    const ret = await API.graphql({
      query: deleteShortcutBindingMutation,
      variables: {
        input: {
          id: id,
        },
      },
    });
  }

  async function updateShortcutBinding(id: string, text: string) {
    if (props.sessionId === "" || userContext.userId === "") {
      return;
    }
    const ret = await API.graphql({
      query: updateShortcutBindingMutation,
      variables: {
        input: {
          id: id,
          text: text,
        },
      },
    });
  }


  useEffect(() => {
    async function initialFetch() {
      const fetchedBindings = await fetchBindings(props.sessionId);
      if (fetchedBindings.length === 0) {
        setTimeout(() => {
          async function initialFetch() {
            const fetchedBindings = await fetchBindings(props.sessionId);
            setBindings(fetchedBindings);
          }
          initialFetch();
        }, 1000)
      } else {
        setBindings(fetchedBindings);
      }
    }
    initialFetch();
  }, [userContext.userId]);


  // function handleKeyPress(event) {
  //   event.preventDefault();
  //   console.log(event);
  //   setNewKeyBinding(event.key);
  // }

  // function handleSetNewBinding(event) { }

  // function handleOnAddNewClick() {
  //   setBindings((bindings) => [...bindings, emptyBinding]);
  // }

  // // 아래는 형 코드
  // function handleBindingTextChange(value, idx) {
  //   console.log(value);
  //   console.log(idx);
  //   setBindings((prevBindings) => {
  //     const newBindings = [...prevBindings];
  //     newBindings[idx].text = value;
  //     return newBindings;
  //   });
  // }

  // 아래는 내 코드 임시
  // function handleBindingTextChange(value, idx) {
  //   console.log(value);
  //   console.log(idx);
  // setBindings((prevBindings) => {
  //   const newBindings = [...prevBindings];
  //   newBindings[idx].text = value;
  //   return newBindings;
  // });
  //   setText(value);
  // }

  // function handleBindingKeyChange(value, idx) {
  //   console.log(value);
  //   console.log(idx);
  // setBindings((prevBindings) => {
  //   const newBindings = [...prevBindings];
  //   newBindings[idx].text = value;
  //   return newBindings;
  // });
  //   setKey(value);
  // }


  // function handleOnCreateClick() {
  // console.log('create');
  // createShortcutBinding(props.sessionId);
  // console.log(fetchBindings(props.sessionId, userContext.userId));
  // setText("");
  // setKey("");
  // }

  // function handleOnDeleteClick(id) {
  //   deleteShortcutBinding(id);
  //   console.log('delete');
  // console.log(fetchBindings(props.sessionId, userContext.userId));
  // }


  return (
    <Modal
      show={props.show}
      onHide={() => props.onHide(false)}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Body>
        <Container className="p-0 m-0">
          <Row>
            <Col>
              {/* <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Enter message"
                  onChange={(event) => {
                    setTextInput(event.target.value);
                  }}
                  value={textInput}
                />
              </InputGroup> */}
              {bindings.map((binding, idx) => (
                <Row
                  key={idx}
                >
                  <Col
                    xs={3}
                  >
                    Ctrl + {binding.key}
                    {/* {binding.text} */}
                  </Col>
                  <Col
                    xs={9}>
                    <Form.Control
                      type="text"
                      defaultValue={binding.text}
                      onChange={(event) => {
                        updateShortcutBinding(binding.id, event.target.value);
                      }}
                    />
                  </Col>
                  {/* <Col>
                    <Button
                      variant="outline-secondary"
                      onClick={() => {
                        handleOnDeleteClick(binding.id)
                      }}
                    >
                      Del
                    </Button>
                  </Col> */}
                </Row>
              ))}
              <Row>
                {/* <Col>
                  <Form.Control
                    type="text"
                    placeholder="Key"
                    onChange={(event) => {
                      handleBindingKeyChange(event.target.value);
                    }}
                    value={key}
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Text"
                    onChange={(event) => {
                      handleBindingTextChange(event.target.value);
                    }}
                    value={text}
                  />
                </Col> */}
              </Row>
              {/* {JSON.stringify(bindings)} */}
            </Col>
          </Row>
          <Row>
            <Col>
              {/* <Button
                className="float-end"
                variant="primary"
                size="sm"
                onClick={handleOnAddNewClick}
              >
                Add New
              </Button> */}
            </Col>
            <Col>
              <Button
                className="float-end"
                variant="secondary"
                size="sm"
                onClick={
                  () => props.onHide(true)
                }
              >
                Close
              </Button>
            </Col>
          </Row>
          {/* <Row>
            <EditShortcutsBinding />
          </Row> */}
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default function refreshAccessToken() {
  let info = JSON.parse(localStorage.getItem("authInfo"));
  let refreshToken = info.refresh_token; // Retrieve the refresh token from localStorage or any other storage mechanism

  if (!refreshToken) {
    // Handle the case when there is no refresh token available
    // setErrorLog("No refresh token available");
    // signIn(); // Trigger the sign-in process to obtain a new refresh token
    //   return;
    //   cacheToken();
  }

  let tokenEndpoint = "https://oauth2.googleapis.com/token";

  const tokenParams = {
    client_id: process.env.REACT_APP_GOOGLE_COMET_SA,
    client_secret: process.env.REACT_APP_GOOGLE_COMET_SECRET,
    grant_type: "refresh_token",
    refresh_token: refreshToken,
  };

  fetch(tokenEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(tokenParams),
  })
    .then((response) => response.json())
    .then((data) => {
      // console.log(data);
      // New access token
      const accessToken = data.access_token;
      localStorage.setItem("accessToken", accessToken);
    })
    .catch((error) => {
      console.error("Token refresh error:", error);
    });
}

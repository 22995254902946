// @flow

import { getSession, getBroadcastSetting } from "./../graphql/queries";
import { onUpdateBroadcastSetting } from "../graphql/subscriptions";
import { API } from "aws-amplify";
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FontPicker from "./FontPicker.react";
import Stack from "react-bootstrap/Stack";

import BroadcastScreenView from "./BroadcastScreenView.react";
import BroadcastScreenViewDual from "./BroadcastScreenViewDual.react";
import { useImperativeDisableScroll } from "../utils/util";

type Props = $ReadOnly<{
  sessionId: ?string,
}>;

// const DEFAULT_COLOR = "#04F404";
// const DEFAULT_FONT = "Helvetica";
// const DEFAULT_TEXT_BACKGROUND_COLOR = "#000000";
// const DEFAULT_TEXT_BACKGROUND_OPACITY = 100;
// const DEFAULT_TEXT_SHADOW_WIDTH = 0;
// const DEFAULT_TEXT_SHADOW_BLUR = 2;
// const DEFAULT_FONT_SIZE = "40";
// const DEFAULT_FONT_COLOR = "#FFFFFF";
// const DEFAULT_LINE_HEIGHT = "1.2";
// const DEFAULT_MIN_LENGTH = 6;
// const DEFAULT_MAX_LENGTH = 60;
// const DEFAULT_MIN_SECONDS = 2;
// const DEFAULT_MAX_SECONDS = 6;
// const DEFAULT_SCREEN_WIDTH = 1920;
// const DEFAULT_SCREEN_HEIGHT = 1080;
// const DEFAULT_BACKGROUND_OPACITY = 100;
// const DEFAULT_BORDER_OPACITY = 0;

export default function BroadcasterViewDual(props: Props): React$Element<any> {
  const [sessionName, setSessionName] = useState("");
  const [broadcastSetting, setBroadcastSetting] = useState([]);
  // const [backgroundColor, setBackgroundColor] = useState(broadcastSetting.screenColor);
  // const [font, setFont] = useState(broadcastSetting.subFont);
  // const [textBackgroundColor, setTextBackgroundColor] = useState(broadcastSetting.subBackgroundColor);
  // const [textBackgroundOpacity, setTextBackgroundOpacity] = useState(broadcastSetting.subBackgroundOpacity);
  // const [backgroundOpacity, setBackgroundOpacity] = useState(broadcastSetting.screenOpacity);
  // const [borderOpacity, setBorderOpacity] = useState(broadcastSetting.borderOpacity);
  // const [textShadowWidth, setTextShadowWidth] = useState(broadcastSetting.textShadowWidth);
  // const [textShadowBlur, setTextShadowBlur] = useState(broadcastSetting.textShadowBlur);
  // const [fontSize, setFontSize] = useState(broadcastSetting.subSize);
  // const [fontColor, setFontColor] = useState(broadcastSetting.subColor);
  // const [lineHeight, setLineHeight] = useState(broadcastSetting.subLineHeight);
  // const [minLength, setMinLength] = useState(broadcastSetting.subMinThresholdCharacters);
  // const [maxLength, setMaxLength] = useState(broadcastSetting.subMaxThresholdCharacters);
  // const [minSeconds, setMinSeconds] = useState(broadcastSetting.subMinDisplaySeconds);
  // const [maxSeconds, setMaxSeconds] = useState(broadcastSetting.subMaxDisplaySeconds);
  // const [screenWidth, setScreenWidth] = useState(broadcastSetting.screenWidth);
  // const [screenHeight, setScreenHeight] = useState(broadcastSetting.screenHeight);

  // const styles = {
  //   inputText: {
  //     width: "120px",
  //   },
  // };

  useEffect(() => {
    fetchSession();
  });

  useEffect(() => {
    fetchBroadcastSetting();
  }, []);

  // Subscribe to broadcast setting update
  useEffect(() => {
    const subscription = API.graphql({
      query: onUpdateBroadcastSetting,
      variables: {
        id: props.sessionId,
      },
    }).subscribe({
      next: () => {
        async function updateFetch() {
          const fetchedBroadcastSettings = await fetchBroadcastSetting();
          // setBroadcastSetting(fetchedBroadcastSettings);
        }
        updateFetch();
      },
      error: (error) => console.warn(error),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // Disable scroll
  useImperativeDisableScroll(document.body, true);

  async function fetchSession() {
    const apiData = await API.graphql({
      query: getSession,
      variables: {
        id: props.sessionId,
      },
    });
    setSessionName(apiData.data.getSession.name);
  }

  async function fetchBroadcastSetting() {
    const apiData = await API.graphql({
      query: getBroadcastSetting,
      variables: {
        id: props.sessionId,
      },
    });
    setBroadcastSetting(apiData.data.getBroadcastSetting);
    // console.log(apiData.data.getBroadcastSetting);
  }

  if (props.sessionId == null) {
    return <div>Invalid Session</div>;
  }
  return (
    <>
      {/* <Container fluid> */}
      {/* <span style={{ position: "absolute", right: "0" }}>{sessionName}</span> */}
      {/* <Row className="p-0">
        <Col className="p-2"> */}
      {/* <Stack direction="horizontal" gap={3}> */}
      <BroadcastScreenViewDual
        sessionId={props.sessionId}
        backgroundColor={broadcastSetting.screenColor}
        backgroundOpacity={broadcastSetting.screenOpacity}
        borderOpacity={broadcastSetting.borderOpacity}
        font={broadcastSetting.subFont}
        fontSize={broadcastSetting.subSize}
        fontColor={broadcastSetting.subColor}
        lineHeight={broadcastSetting.subLineHeight}
        textBackgroundColor={broadcastSetting.subBackgroundColor}
        textBackgroundOpacity={broadcastSetting.subBackgroundOpacity}
        textShadowWidth={broadcastSetting.textShadowWidth}
        textShadowBlur={broadcastSetting.textShadowBlur}
        minSeconds={broadcastSetting.subMinDisplaySeconds}
        maxSeconds={broadcastSetting.subMaxDisplaySeconds}
        minSecondsTextLengthThreshold={
          broadcastSetting.subMinThresholdCharacters
        }
        maxSecondsTextLengthThreshold={
          broadcastSetting.subMaxThresholdCharacters
        }
        screenWidth={broadcastSetting.screenWidth}
        screenHeight={broadcastSetting.screenHeight}
        subAlign={broadcastSetting.subAlign}
        marginBottom={broadcastSetting.marginBottom}
        lineNumber={broadcastSetting.lineNumber}
        dualSessionId={broadcastSetting.dualSessionId}
        isNoWrap={broadcastSetting.isNoWrap}
        isDemoText={broadcastSetting.isDemoText}
        multiSessionIdList={broadcastSetting.multiSessionIdList}
      />
      {/* {screenSettings1} */}
      {/* {screenSettings2} */}
      {/* </Stack> */}
      {/* </Col>
      </Row> */}
      {/* </Container> */}
    </>
  );
}

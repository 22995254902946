import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import NavigationBar from "../NavigationBar.react";

const cacheToken = (code) => {
  let tokenEndpoint = "https://oauth2.googleapis.com/token";
  let redirectUri = window.location.origin + "/callback"; // Use the current site's URL as the redirect URI

  // After successful sign-in and redirect, retrieve the authorization code from the URL parameters
  // const urlParams = new URLSearchParams(window.location.search);
  // const authorizationCode = urlParams.get("code");
  const authorizationCode = code;

  if (authorizationCode) {
    // Exchange the authorization code for an access token and refresh token
    const tokenParams = {
      client_id: process.env.REACT_APP_GOOGLE_COMET_SA,
      client_secret: process.env.REACT_APP_GOOGLE_COMET_SECRET,
      redirect_uri: redirectUri,
      grant_type: "authorization_code",
      code: authorizationCode,
    };

    fetch(tokenEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams(tokenParams),
    })
      .then((response) => response.json())
      .then((data) => {
        // Access token and refresh token
        const accessToken = data.access_token;
        const refreshToken = data.refresh_token;

        // Save the access token and refresh token in localStorage
        let authInfo = {
          access_token: accessToken,
          refresh_token: refreshToken,
        };
        localStorage.setItem("authInfo", JSON.stringify(authInfo));

        // Use the access token
        console.log(`access token : ${accessToken}`);
        console.log(`refresh token : ${refreshToken}`);

        // Redirect the user to the root page after token exchange
        // window.location.href = "/";
      })
      .catch((error) => {
        console.error("Token exchange error:", error);
        // setErrorLog("Token exchange error");
      });
  }
};

const Callback = () => {
  const location = useLocation();
  // console.log("callback");

  // Test Temporary //
  // const params = new URLSearchParams(location.search);
  // const code = params.get("code");
  // Test Temporary

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");

    if (code) {
      // Authorization code is available, perform token exchange
      // Call your function to exchange the authorization code for access token and refresh token
      // console.log(code);
      cacheToken(code);
    } else {
      // Authorization code is not available, handle the error case
      //   setErrorLog("No authorization code received");
      console.log("error");
      // Perform any necessary action or redirect the user to an error page
      // ...
    }
  }, [location.search]);

  return (
    <>
      <NavigationBar />
      {/* <div>Redirecting...</div> */}
      {/* <button
        onClick={() => {
          cacheToken(code);
        }}
      >
        Cache Tokens
      </button> */}
      <a href="/">Home</a>
    </>
  );
};

export default Callback;

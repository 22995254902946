// @flow

import LoggerContext from "../utils/LoggerContext";

import React from "react";
import { useContext, useState } from "react";
import { Container, Navbar } from "react-bootstrap";
//import Button from "react-bootstrap/Button";

export default function NavigationBar(): React$Element<any> {
  //const loggerContext = useContext(LoggerContext);
  //const [debugShown, setDebugShown] = useState(false);

  return (
    <>
      <Navbar className="px-2" bg="dark" variant="dark">
        <Navbar.Brand href="/">
          <img
            alt=""
            src="./../../logo_white_30.png"
            width="30"
            height="30"
            style={{ marginRight: "8px" }}
            className="d-inline-block align-top"
          />
          Comet Live Caption
        </Navbar.Brand>
        {/* <Button
          variant="secondary"
          onClick={() => {
            setDebugShown(!debugShown);
          }}
        >
          Debug
        </Button> */}
        <Navbar.Collapse className="m-0 p-0 justify-content-end">
          <Navbar.Text
            style={{ textAlign: "right", fontSize: "0.6em" }}
            className="m-0 p-0"
          >
            <span>comet.livecaps@gmail.com</span>
            <br />
            <span>© 2022 COMET SYSTEMS CO. ALL RIGHTS RESERVED</span>
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
      {/* {debugShown ? loggerContext.logger.getLogView() : null} */}
    </>
  );
}

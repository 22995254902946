// @flow

import React, { useState, useEffect } from "react";
import { Amplify, Auth, API } from "aws-amplify";
import { IoTClient, AttachPolicyCommand } from "@aws-sdk/client-iot";

import { Routes, Route } from "react-router-dom";

import { withAuthenticator } from "@aws-amplify/ui-react";
// $FlowIgnore This css is accessible
import "@aws-amplify/ui-react/styles.css";
import { AWSIoTProvider } from "@aws-amplify/pubsub";

import awsExports from "./aws-exports";
import ListSessionsView from "./components/ListSessionsView.react";
import TranslatorViewRoute from "./routes/TranslatorViewRoute";
import TranslatorViewLiteRoute from "./routes/TranslatorViewLiteRoute";
import BroadcasterViewRoute from "./routes/BroadcasterViewRoute";
import BroadcasterViewDualRoute from "./routes/BroadcasterViewDualRoute";
import YoutubeViewRoute from "./routes/YoutubeViewRoute";
import ScriptViewRoute from "./routes/ScriptViewRoute";
import BroadcastSettingsViewRoute from "./routes/BroadcastSettingsViewRoute";
import MacroEditViewRoute from "./routes/MacroEditViewRoute";
import TrainingViewRoute from "./routes/TrainingViewRoute";
import ArtistsViewRoute from "./routes/ArtistsViewRoute";
import Callback from "./components/OAuth/OAuthCallback";

import UserContext from "./utils/UserContext";

import { createUser as createUserMutation } from "./graphql/mutations";
import { userByUsername } from "./graphql/queries";
import AdminView from "./components/AdminView.react";

import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

import refreshAccessToken from "./components/OAuth/OAuthRefreshToken";

Amplify.configure(awsExports);
Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: "ap-northeast-2",
    aws_pubsub_endpoint:
      "wss://a3uo2wikx5ezjw-ats.iot.ap-northeast-2.amazonaws.com/mqtt",
  })
);
// Amplify.Logger.LOG_LEVEL = "VERBOSE";

const TOKEN_REFRESH_INTERVAL = 3300000;
// const TOKEN_REFRESH_INTERVAL = 5000;

function App(): React$Element<React$FragmentType> {
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [hasAccess, setHasAccess] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [initDone, setInitDone] = useState(false);
  const [hasOauthInfo, setHasOauthInfo] = useState(false);
  const [isBroadcaster, setIsBroadcaster] = useState(false);
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    async function attachPolicy() {
      const cred = await Auth.currentCredentials();
      const cognitoIdentityId = cred.identityId;

      const client = new IoTClient({
        credentials: cred,
        region: "ap-northeast-2",
      });
      const command = new AttachPolicyCommand({
        policyName: "liveCaption",
        target: cognitoIdentityId,
      });
      await client.send(command);
    }
    attachPolicy();
  }, []);

  async function signOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  // Fetch user information from AWS Amplify auth
  useEffect(() => {
    async function getUserInfo() {
      const info = await Auth.currentUserInfo();
      setUserName(info.username);
    }
    getUserInfo();
  }, [userName]);

  useEffect(() => {
    checkOauthInfo();
  }, []);

  useEffect(() => {
    if (hasOauthInfo) {
      refreshAccessToken();
    } else {
      console.log("Doesn't have OAuth info");
    }
  }, [hasOauthInfo]);

  // Refresh Google OAuth Token
  useEffect(() => {
    const tokenRefreshTimer = setInterval(() => {
      refreshAccessToken();
      // setAccessToken(newAccessToken);
      // alert('Access Token Refreshed')
    }, TOKEN_REFRESH_INTERVAL);

    return () => {
      clearInterval(tokenRefreshTimer);
    };
  }, []);

  // Fetch/create app user
  useEffect(() => {
    async function fetchOrCreateAppUser() {
      if (userName === "") {
        return;
      }
      let user = await fetchAppUser();
      if (user == null) {
        user = await createUser();
      }
      setUserId(user?.id);
      setHasAccess(user?.allowAllAccess);
      setIsSuperAdmin(user?.isSuperAdmin);
      setIsBroadcaster(user?.isBroadcaster);
      setInitDone(true);
    }
    fetchOrCreateAppUser();
  }, [userName]);

  async function fetchAppUser() {
    if (userName === "") {
      return;
    }
    const apiData = await API.graphql({
      query: userByUsername,
      variables: {
        username: userName,
      },
    });
    if (apiData.data.userByUsername.items.length > 0) {
      return apiData.data.userByUsername.items[0];
    } else {
      return null;
    }
  }

  async function createUser() {
    if (userName === "") {
      return;
    }
    const ret = await API.graphql({
      query: createUserMutation,
      variables: {
        input: {
          username: userName,
          name: userName,
          isSuperAdmin: false,
          allowAllAccess: false,
        },
      },
    });
    return ret.createUser;
  }

  // // Only checks if OauthInfo exists localStorage, but doesn't check validity 
  // const checkOauthInfo = () => {
  //   const info = JSON.parse(localStorage.getItem("authInfo"));
  //   console.log(info)
  //   if (info && info.refresh_token) {
  //     setHasOauthInfo(true);
  //   } else {
  //     setHasOauthInfo(false);
  //   }
  // };

  // Checks both if OauthInfo exists in localStorage and if it is valid
  async function checkOauthInfo() {
    const info = JSON.parse(localStorage.getItem("authInfo"));

    if (info) {
      const currentAccessToken = info.access_token
      // const currentRefreshToken = info.refresh_token
      // console.log(currentAccessToken)
      // console.log(currentRefreshToken)

      const url = `https://www.googleapis.com/oauth2/v3/tokeninfo?access_token=${currentAccessToken}`;
      try {
        const response = await fetch(url);
        const data = await response.json();
        if (response.ok) {
          console.log('Access token is valid:', data);
          setHasOauthInfo(true);
        } else {
          console.log('Access token is invalid:', data);
          setHasOauthInfo(false);
        }
      } catch (error) {
        console.error('Error validating access token:', error);
      }
    } else {
      return;
    }
  }


  if (!initDone) {
    return <Spinner animation="border" />;
  } else if (userName !== "" && !hasAccess && !isSuperAdmin) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <img src="logo512.png" alt="logo" width="300px" height="300px" />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Arial",
          }}
        >
          <p align="center">
            <h1>No Access</h1> <br />
            <br />
            <h4>
              Please contact{" "}
              <a href="mailto: comet.livecaps@gmail.com">
                comet.livecaps@gmail.com
              </a>{" "}
              to gain access.
            </h4>
            <br />© 2022 COMET SYSTEMS CO. ALL RIGHTS RESERVED
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "10vh",
          }}
        >
          <Button
            className="ms-1 float"
            size="lg"
            variant="secondary"
            onClick={() => {
              signOut();
            }}
          >
            <b>Sign Out</b>
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <UserContext.Provider
        value={{
          username: userName,
          userId: userId ?? "",
          isSuperAdmin: isSuperAdmin ?? false,
          isBroadcaster: isBroadcaster ?? false,
        }}
      >
        {/* <NavigationBar /> */}
        <Routes>
          <Route
            path="/"
            element={<ListSessionsView hasOauthInfo={hasOauthInfo} />}
          />
          <Route path="translator" element={<TranslatorViewRoute />}>
            <Route path=":sessionId" element={<TranslatorViewRoute />} />
          </Route>
          <Route path="training" element={<TrainingViewRoute />}>
            <Route path=":sessionId" element={<TrainingViewRoute />} />
          </Route>
          <Route path="translator_lite" element={<TranslatorViewLiteRoute />}>
            <Route path=":sessionId" element={<TranslatorViewLiteRoute />} />
          </Route>
          <Route path="broadcaster" element={<BroadcasterViewRoute />}>
            <Route path=":sessionId" element={<BroadcasterViewRoute />} />
          </Route>
          <Route path="broadcasterdual" element={<BroadcasterViewDualRoute />}>
            <Route path=":sessionId" element={<BroadcasterViewDualRoute />} />
          </Route>
          <Route
            path="broadcastsetting"
            element={<BroadcastSettingsViewRoute />}
          >
            <Route path=":sessionId" element={<BroadcastSettingsViewRoute />} />
          </Route>
          <Route path="youtube" element={<YoutubeViewRoute />}>
            <Route path=":sessionId" element={<YoutubeViewRoute />} />
          </Route>
          <Route path="script" element={<ScriptViewRoute />}>
            <Route path=":sessionId" element={<ScriptViewRoute />} />
          </Route>
          <Route path="macro" element={<MacroEditViewRoute />}>
            {/* <Route path=":sessionId" element={<MacroEditViewRoute />} /> */}
          </Route>
          <Route path="artists" element={<ArtistsViewRoute />}>
            {/* <Route path=":sessionId" element={<ArtistsViewRoute />} /> */}
          </Route>
          <Route path="callback" element={<Callback />} />
          <Route
            path="admin"
            element={isSuperAdmin ? <AdminView /> : <div />}
          />
        </Routes>
      </UserContext.Provider>
    </>
  );
}

export default (withAuthenticator(App): any);

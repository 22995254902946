/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createArtist = /* GraphQL */ `
  mutation CreateArtist($input: CreateArtistInput!) {
    createArtist(input: $input) {
      chName
      enName
      id
      jaName
      krName
      team
      __typename
    }
  }
`;
export const createBroadcastSetting = /* GraphQL */ `
  mutation CreateBroadcastSetting(
    $condition: ModelBroadcastSettingConditionInput
    $input: CreateBroadcastSettingInput!
  ) {
    createBroadcastSetting(condition: $condition, input: $input) {
      borderOpacity
      createdAt
      dualSessionId
      id
      isDemoText
      isNoWrap
      lineNumber
      marginBottom
      multiSessionIdList
      screenColor
      screenHeight
      screenOpacity
      screenWidth
      subAlign
      subBackgroundColor
      subBackgroundOpacity
      subColor
      subFont
      subLineHeight
      subMaxDisplaySeconds
      subMaxThresholdCharacters
      subMinDisplaySeconds
      subMinThresholdCharacters
      subSize
      textShadowBlur
      textShadowWidth
      updatedAt
      __typename
    }
  }
`;
export const createKeyBinding = /* GraphQL */ `
  mutation CreateKeyBinding(
    $condition: ModelKeyBindingConditionInput
    $input: CreateKeyBindingInput!
  ) {
    createKeyBinding(condition: $condition, input: $input) {
      alt
      createdAt
      ctrl
      id
      key
      keyBindingSessionId
      shift
      text
      updatedAt
      userKeyBindingsId
      user
      session
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $condition: ModelMessageConditionInput
    $input: CreateMessageInput!
  ) {
    createMessage(condition: $condition, input: $input) {
      createdAt
      id
      scriptIndex
      sessionMessagesId
      text
      type
      updatedAt
      username
      __typename
    }
  }
`;
export const createSession = /* GraphQL */ `
  mutation CreateSession(
    $condition: ModelSessionConditionInput
    $input: CreateSessionInput!
  ) {
    createSession(condition: $condition, input: $input) {
      broadcastSetting {
        borderOpacity
        createdAt
        dualSessionId
        id
        isDemoText
        isNoWrap
        lineNumber
        marginBottom
        multiSessionIdList
        screenColor
        screenHeight
        screenOpacity
        screenWidth
        subAlign
        subBackgroundColor
        subBackgroundOpacity
        subColor
        subFont
        subLineHeight
        subMaxDisplaySeconds
        subMaxThresholdCharacters
        subMinDisplaySeconds
        subMinThresholdCharacters
        subSize
        textShadowBlur
        textShadowWidth
        updatedAt
        __typename
      }
      createdAt
      id
      messages {
        items {
          createdAt
          id
          scriptIndex
          sessionMessagesId
          text
          type
          updatedAt
          username
          __typename
        }
        nextToken
        __typename
      }
      name
      sessionBroadcastSettingId
      sheetsUpdateCount
      sheetsUrl
      streamUrl
      type
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $condition: ModelUserConditionInput
    $input: CreateUserInput!
  ) {
    createUser(condition: $condition, input: $input) {
      allowAllAccess
      createdAt
      id
      isSuperAdmin
      keyBindings {
        items {
          alt
          createdAt
          ctrl
          id
          key
          keyBindingSessionId
          shift
          text
          updatedAt
          userKeyBindingsId
          user
          session
          __typename
        }
        nextToken
        __typename
      }
      name
      updatedAt
      username
      isBroadcaster
      __typename
    }
  }
`;
export const createYoutubeSettings = /* GraphQL */ `
  mutation CreateYoutubeSettings($input: CreateYoutubeSettingsInput!) {
    createYoutubeSettings(input: $input) {
      addedDelay
      addedDelayOn
      id
      url
      __typename
    }
  }
`;
export const deleteArtist = /* GraphQL */ `
  mutation DeleteArtist($input: DeleteArtistInput!) {
    deleteArtist(input: $input) {
      chName
      enName
      id
      jaName
      krName
      team
      __typename
    }
  }
`;
export const deleteBroadcastSetting = /* GraphQL */ `
  mutation DeleteBroadcastSetting(
    $condition: ModelBroadcastSettingConditionInput
    $input: DeleteBroadcastSettingInput!
  ) {
    deleteBroadcastSetting(condition: $condition, input: $input) {
      borderOpacity
      createdAt
      dualSessionId
      id
      isDemoText
      isNoWrap
      lineNumber
      marginBottom
      multiSessionIdList
      screenColor
      screenHeight
      screenOpacity
      screenWidth
      subAlign
      subBackgroundColor
      subBackgroundOpacity
      subColor
      subFont
      subLineHeight
      subMaxDisplaySeconds
      subMaxThresholdCharacters
      subMinDisplaySeconds
      subMinThresholdCharacters
      subSize
      textShadowBlur
      textShadowWidth
      updatedAt
      __typename
    }
  }
`;
export const deleteKeyBinding = /* GraphQL */ `
  mutation DeleteKeyBinding(
    $condition: ModelKeyBindingConditionInput
    $input: DeleteKeyBindingInput!
  ) {
    deleteKeyBinding(condition: $condition, input: $input) {
      alt
      createdAt
      ctrl
      id
      key
      keyBindingSessionId
      shift
      text
      updatedAt
      userKeyBindingsId
      user
      session
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $condition: ModelMessageConditionInput
    $input: DeleteMessageInput!
  ) {
    deleteMessage(condition: $condition, input: $input) {
      createdAt
      id
      scriptIndex
      sessionMessagesId
      text
      type
      updatedAt
      username
      __typename
    }
  }
`;
export const deleteSession = /* GraphQL */ `
  mutation DeleteSession(
    $condition: ModelSessionConditionInput
    $input: DeleteSessionInput!
  ) {
    deleteSession(condition: $condition, input: $input) {
      broadcastSetting {
        borderOpacity
        createdAt
        dualSessionId
        id
        isDemoText
        isNoWrap
        lineNumber
        marginBottom
        multiSessionIdList
        screenColor
        screenHeight
        screenOpacity
        screenWidth
        subAlign
        subBackgroundColor
        subBackgroundOpacity
        subColor
        subFont
        subLineHeight
        subMaxDisplaySeconds
        subMaxThresholdCharacters
        subMinDisplaySeconds
        subMinThresholdCharacters
        subSize
        textShadowBlur
        textShadowWidth
        updatedAt
        __typename
      }
      createdAt
      id
      messages {
        items {
          createdAt
          id
          scriptIndex
          sessionMessagesId
          text
          type
          updatedAt
          username
          __typename
        }
        nextToken
        __typename
      }
      name
      sessionBroadcastSettingId
      sheetsUpdateCount
      sheetsUrl
      streamUrl
      type
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $condition: ModelUserConditionInput
    $input: DeleteUserInput!
  ) {
    deleteUser(condition: $condition, input: $input) {
      allowAllAccess
      createdAt
      id
      isSuperAdmin
      keyBindings {
        items {
          alt
          createdAt
          ctrl
          id
          key
          keyBindingSessionId
          shift
          text
          updatedAt
          userKeyBindingsId
          user
          session
          __typename
        }
        nextToken
        __typename
      }
      name
      updatedAt
      username
      isBroadcaster
      __typename
    }
  }
`;
export const deleteYoutubeSettings = /* GraphQL */ `
  mutation DeleteYoutubeSettings($input: DeleteYoutubeSettingsInput!) {
    deleteYoutubeSettings(input: $input) {
      addedDelay
      addedDelayOn
      id
      url
      __typename
    }
  }
`;
export const updateArtist = /* GraphQL */ `
  mutation UpdateArtist($input: UpdateArtistInput!) {
    updateArtist(input: $input) {
      chName
      enName
      id
      jaName
      krName
      team
      __typename
    }
  }
`;
export const updateBroadcastSetting = /* GraphQL */ `
  mutation UpdateBroadcastSetting(
    $condition: ModelBroadcastSettingConditionInput
    $input: UpdateBroadcastSettingInput!
  ) {
    updateBroadcastSetting(condition: $condition, input: $input) {
      borderOpacity
      createdAt
      dualSessionId
      id
      isDemoText
      isNoWrap
      lineNumber
      marginBottom
      multiSessionIdList
      screenColor
      screenHeight
      screenOpacity
      screenWidth
      subAlign
      subBackgroundColor
      subBackgroundOpacity
      subColor
      subFont
      subLineHeight
      subMaxDisplaySeconds
      subMaxThresholdCharacters
      subMinDisplaySeconds
      subMinThresholdCharacters
      subSize
      textShadowBlur
      textShadowWidth
      updatedAt
      __typename
    }
  }
`;
export const updateKeyBinding = /* GraphQL */ `
  mutation UpdateKeyBinding(
    $condition: ModelKeyBindingConditionInput
    $input: UpdateKeyBindingInput!
  ) {
    updateKeyBinding(condition: $condition, input: $input) {
      alt
      createdAt
      ctrl
      id
      key
      keyBindingSessionId
      shift
      text
      updatedAt
      userKeyBindingsId
      user
      session
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $condition: ModelMessageConditionInput
    $input: UpdateMessageInput!
  ) {
    updateMessage(condition: $condition, input: $input) {
      createdAt
      id
      scriptIndex
      sessionMessagesId
      text
      type
      updatedAt
      username
      __typename
    }
  }
`;
export const updateSession = /* GraphQL */ `
  mutation UpdateSession(
    $condition: ModelSessionConditionInput
    $input: UpdateSessionInput!
  ) {
    updateSession(condition: $condition, input: $input) {
      broadcastSetting {
        borderOpacity
        createdAt
        dualSessionId
        id
        isDemoText
        isNoWrap
        lineNumber
        marginBottom
        multiSessionIdList
        screenColor
        screenHeight
        screenOpacity
        screenWidth
        subAlign
        subBackgroundColor
        subBackgroundOpacity
        subColor
        subFont
        subLineHeight
        subMaxDisplaySeconds
        subMaxThresholdCharacters
        subMinDisplaySeconds
        subMinThresholdCharacters
        subSize
        textShadowBlur
        textShadowWidth
        updatedAt
        __typename
      }
      createdAt
      id
      messages {
        items {
          createdAt
          id
          scriptIndex
          sessionMessagesId
          text
          type
          updatedAt
          username
          __typename
        }
        nextToken
        __typename
      }
      name
      sessionBroadcastSettingId
      sheetsUpdateCount
      sheetsUrl
      streamUrl
      type
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $condition: ModelUserConditionInput
    $input: UpdateUserInput!
  ) {
    updateUser(condition: $condition, input: $input) {
      allowAllAccess
      createdAt
      id
      isSuperAdmin
      keyBindings {
        items {
          alt
          createdAt
          ctrl
          id
          key
          keyBindingSessionId
          shift
          text
          updatedAt
          userKeyBindingsId
          user
          session
          __typename
        }
        nextToken
        __typename
      }
      name
      updatedAt
      username
      isBroadcaster
      __typename
    }
  }
`;
export const updateYoutubeSettings = /* GraphQL */ `
  mutation UpdateYoutubeSettings($input: UpdateYoutubeSettingsInput!) {
    updateYoutubeSettings(input: $input) {
      addedDelay
      addedDelayOn
      id
      url
      __typename
    }
  }
`;
export const createUserKeyCommand = /* GraphQL */ `
  mutation CreateUserKeyCommand($input: CreateUserKeyCommandInput!) {
    createUserKeyCommand(input: $input) {
      id
      commandName
      userId
      triggerKey
      isEnabled
      __typename
    }
  }
`;
export const updateUserKeyCommand = /* GraphQL */ `
  mutation UpdateUserKeyCommand($input: UpdateUserKeyCommandInput!) {
    updateUserKeyCommand(input: $input) {
      id
      commandName
      userId
      triggerKey
      isEnabled
      __typename
    }
  }
`;
export const deleteUserKeyCommand = /* GraphQL */ `
  mutation DeleteUserKeyCommand($input: DeleteUserKeyCommandInput!) {
    deleteUserKeyCommand(input: $input) {
      id
      commandName
      userId
      triggerKey
      isEnabled
      __typename
    }
  }
`;
export const createUserMacro = /* GraphQL */ `
  mutation CreateUserMacro($input: CreateUserMacroInput!) {
    createUserMacro(input: $input) {
      id
      triggerWord
      userId
      insertWord
      isEnabled
      macroGroup
      macroName
      __typename
    }
  }
`;
export const updateUserMacro = /* GraphQL */ `
  mutation UpdateUserMacro($input: UpdateUserMacroInput!) {
    updateUserMacro(input: $input) {
      id
      triggerWord
      userId
      insertWord
      isEnabled
      macroGroup
      macroName
      __typename
    }
  }
`;
export const deleteUserMacro = /* GraphQL */ `
  mutation DeleteUserMacro($input: DeleteUserMacroInput!) {
    deleteUserMacro(input: $input) {
      id
      triggerWord
      userId
      insertWord
      isEnabled
      macroGroup
      macroName
      __typename
    }
  }
`;
export const createShortcutBinding = /* GraphQL */ `
  mutation CreateShortcutBinding($input: CreateShortcutBindingInput!) {
    createShortcutBinding(input: $input) {
      id
      index
      keyBindingSessionId
      userKeyBindingsId
      ctrl
      key
      text
      __typename
    }
  }
`;
export const updateShortcutBinding = /* GraphQL */ `
  mutation UpdateShortcutBinding($input: UpdateShortcutBindingInput!) {
    updateShortcutBinding(input: $input) {
      id
      index
      keyBindingSessionId
      userKeyBindingsId
      ctrl
      key
      text
      __typename
    }
  }
`;
export const deleteShortcutBinding = /* GraphQL */ `
  mutation DeleteShortcutBinding($input: DeleteShortcutBindingInput!) {
    deleteShortcutBinding(input: $input) {
      id
      index
      keyBindingSessionId
      userKeyBindingsId
      ctrl
      key
      text
      __typename
    }
  }
`;
export const createScriptSheets = /* GraphQL */ `
  mutation CreateScriptSheets($input: CreateScriptSheetsInput!) {
    createScriptSheets(input: $input) {
      id
      index
      sessionSheetsId
      name
      script
      translation
      backgroundColor
      textColor
      isEditable
      isSelected
      __typename
    }
  }
`;
export const updateScriptSheets = /* GraphQL */ `
  mutation UpdateScriptSheets($input: UpdateScriptSheetsInput!) {
    updateScriptSheets(input: $input) {
      id
      index
      sessionSheetsId
      name
      script
      translation
      backgroundColor
      textColor
      isEditable
      isSelected
      __typename
    }
  }
`;
export const deleteScriptSheets = /* GraphQL */ `
  mutation DeleteScriptSheets($input: DeleteScriptSheetsInput!) {
    deleteScriptSheets(input: $input) {
      id
      index
      sessionSheetsId
      name
      script
      translation
      backgroundColor
      textColor
      isEditable
      isSelected
      __typename
    }
  }
`;
export const createBookmarks = /* GraphQL */ `
  mutation CreateBookmarks($input: CreateBookmarksInput!) {
    createBookmarks(input: $input) {
      id
      row
      sessionId
      color
      title
      __typename
    }
  }
`;
export const updateBookmarks = /* GraphQL */ `
  mutation UpdateBookmarks($input: UpdateBookmarksInput!) {
    updateBookmarks(input: $input) {
      id
      row
      sessionId
      color
      title
      __typename
    }
  }
`;
export const deleteBookmarks = /* GraphQL */ `
  mutation DeleteBookmarks($input: DeleteBookmarksInput!) {
    deleteBookmarks(input: $input) {
      id
      row
      sessionId
      color
      title
      __typename
    }
  }
`;

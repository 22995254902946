// @flow

import React, { useState, useEffect, useContext } from "react";
import { Amplify, API, Auth } from "aws-amplify";
import {
  sessionsByDate,
  queryScriptSheetsBySessionSheetsIdIndex,
} from "./../graphql/queries";
import {
  createSession as createSessionMutation,
  updateSession as updateSessionMutation,
  deleteSession as deleteSessionMutation,
  createBroadcastSetting as createBroadcastSettingMutation,
  deleteBroadcastSetting as deleteBroadcastSettingMutation,
  deleteScriptSheets as deleteScriptSheetsMutation,
} from "./../graphql/mutations";
import { DEFAULT_SESSION_TYPE } from "./../utils/util";
import awsExports from "./../aws-exports";
import NavigationBar from "./NavigationBar.react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";

import UserContext from "./../utils/UserContext";

import signIn from "./OAuth/OAuthLogin";

Amplify.configure(awsExports);

type Props = $ReadOnly<{
  hasOauthInfo: boolean,
}>;

export default function ListSessionsView(props: Props): React$Element<any> {
  const [sessions, setSessions] = useState([]);
  const [newSessionName, setNewSessionName] = useState("");
  const [fetchSessionsInFlight, setFetchSessionsInFlight] = useState(false);
  const [createSessionInFlight, setCreateSessionInFlight] = useState(false);
  const [deleteSessionInFlight, setDeleteSessionInFlight] = useState(false);
  const [deletingSessionID, setDeletingSessionID] = useState("");
  const [deletingSessionName, setDeletingSessionName] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingSessionId, setEditingSessionID] = useState("");
  const [editName, setEditName] = useState("");
  const [editingSessionName, setEditingSessionName] = useState("");

  let navigate = useNavigate();
  const userContext = useContext(UserContext);
  // console.log(`userContext: ${JSON.stringify(userContext)}`)

  useEffect(() => {
    fetchSessions();
  }, []);

  // useEffect(() => {
  //   console.log(props.hasOauthInfo);
  // }, [props.hasOauthInfo]);

  async function deleteSession(id: string) {
    if (id === "") {
      return;
    }
    setDeleteSessionInFlight(true);
    const ret = await API.graphql({
      query: deleteSessionMutation,
      variables: {
        input: {
          id,
        },
      },
    });
    setSessions((prevSessions) =>
      prevSessions.filter((item) => item.id !== id)
    );
    setDeletingSessionID("");
    setDeletingSessionName("");
    setShowDeleteModal(false);
    setDeleteSessionInFlight(false);
    deleteBroadcastSetting(id);
    deleteAllScriptSheets(id);
  }

  async function updateSession(id: string) {
    if (id === "") {
      return;
    }
    const ret = await API.graphql({
      query: updateSessionMutation,
      variables: {
        input: {
          id: id,
          name: editName,
        },
      },
    });
    fetchSessions();
    setEditingSessionID("");
    setEditingSessionName("");
    setShowEditModal(false);
  }

  async function deleteBroadcastSetting(id: string) {
    const ret = await API.graphql({
      query: deleteBroadcastSettingMutation,
      variables: {
        input: {
          id,
        },
      },
    });
  }

  async function deleteScriptSheets(id: string) {
    const ret = await API.graphql({
      query: deleteScriptSheetsMutation,
      variables: {
        input: {
          id: id,
        },
      },
    });
  }

  async function deleteAllScriptSheets(id: string) {
    const apiData = await API.graphql({
      query: queryScriptSheetsBySessionSheetsIdIndex,
      variables: {
        sessionSheetsId: id,
      },
    });
    apiData.data.queryScriptSheetsBySessionSheetsIdIndex.items.forEach((line) =>
      deleteScriptSheets(line.id)
    );
  }

  async function fetchSessions() {
    setFetchSessionsInFlight(true);
    const apiData = await API.graphql({
      query: sessionsByDate,
      variables: { type: 0, sortDirection: "DESC" },
    });
    setSessions(apiData.data?.sessionsByDate?.items ?? []);
    setFetchSessionsInFlight(false);
  }

  async function createSession() {
    setCreateSessionInFlight(true);
    const currentTime = new Date().toLocaleString();
    const sessionName =
      newSessionName !== ""
        ? newSessionName
        : "Translation Session on " + currentTime;
    const newSessionData = await API.graphql({
      query: createSessionMutation,
      variables: {
        input: {
          name: sessionName,
          type: DEFAULT_SESSION_TYPE,
          sheetsUpdateCount: 0,
        },
      },
    });
    setCreateSessionInFlight(false);
    createBroadcastSetting(newSessionData.data.createSession.id);
    navigate("/translator/" + newSessionData.data.createSession.id);
  }

  async function createBroadcastSetting(id: string) {
    const ret = await API.graphql({
      query: createBroadcastSettingMutation,
      variables: {
        input: {
          id: id,
          screenWidth: 1280,
          screenHeight: 720,
          screenColor: "#04F404",
          screenOpacity: 0,
          borderOpacity: 0,
          subFont: "Helvetica",
          subSize: 40,
          subColor: "#FFFFFF",
          subBackgroundColor: "#000000",
          subBackgroundOpacity: 0,
          subLineHeight: 1.2,
          subMinDisplaySeconds: 2,
          subMaxDisplaySeconds: 6,
          subMinThresholdCharacters: 6,
          subMaxThresholdCharacters: 60,
          textShadowWidth: 1.2,
          textShadowBlur: 2,
          marginBottom: 0,
          subAlign: "center",
          lineNumber: 2,
          dualSessionId: "(blank)",
          isNoWrap: false,
          isDemoText: false,
          multiSessionIdList: [id],
        },
      },
    });
  }

  async function signOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  function handleSessionNameInputChange(event) {
    setNewSessionName(event.target.value);
  }

  function handleSubmit() {
    createSession();
    setNewSessionName("");
  }

  function showDeletingModal(id: string, name: string) {
    setShowDeleteModal(true);
    setDeletingSessionID(id);
    setDeletingSessionName(name);
  }

  function showEditingModal(id: string, name: string) {
    setShowEditModal(true);
    setEditName(name);
    setEditingSessionID(id);
    setEditingSessionName(name);
  }

  function cancelDelete() {
    setShowDeleteModal(false);
    setDeletingSessionID("");
    setDeletingSessionName("");
  }

  function cancelEdit() {
    setShowEditModal(false);
    setEditingSessionID("");
    setEditingSessionName("");
  }

  function renderSessionList() {
    return fetchSessionsInFlight ? (
      <Row className="justify-content-md-center">
        <Spinner animation="border" role="status" />
      </Row>
    ) : (
      <Row>
        <Col>
          <ListGroup className="align-middle">
            {sessions.map((session) => (
              <ListGroup.Item key={session.id}>
                <Row>
                  <Col xs={4}>
                    <span className="align-middle">{session.name}</span>
                  </Col>
                  <Col>
                    {userContext.isSuperAdmin ? (
                      <Button
                        className="ms-2 float-end"
                        variant="danger"
                        size="sm"
                        onClick={() => {
                          showDeletingModal(session.id, session.name);
                        }}
                      >
                        Delete
                      </Button>
                    ) : null}
                    {userContext.isSuperAdmin ? (
                      <Button
                        className="ms-2 float-end"
                        variant="outline-secondary"
                        size="sm"
                        onClick={() => {
                          showEditingModal(session.id, session.name);
                        }}
                      >
                        Edit
                      </Button>
                    ) : null}
                    {userContext.isSuperAdmin ? (
                      <Button
                        className="ms-2 float-end"
                        variant="outline-dark"
                        size="sm"
                        onClick={() => {
                          navigate("/script/" + session.id);
                        }}
                      >
                        Script
                      </Button>
                    ) : null}
                    {userContext.isSuperAdmin ? (
                      <Button
                        className="ms-2 float-end"
                        variant="dark"
                        size="sm"
                        onClick={() => {
                          navigate("/youtube/" + session.id);
                        }}
                      >
                        Youtube
                      </Button>
                    ) : null}
                    {userContext.isSuperAdmin || userContext.isBroadcaster ? (
                      <Button
                        className="ms-2 float-end"
                        variant="secondary"
                        size="sm"
                        onClick={() => {
                          navigate("/broadcastsetting/" + session.id);
                        }}
                      >
                        Broadcast Setting
                      </Button>
                    ) : null}
                    <Button
                      bsClass="custom-btn"
                      style={{
                        background: "#FFC13B",
                        border: "2px solid #FFC13B",
                      }}
                      className="ms-2 float-end"
                      // variant="warning"
                      size="sm"
                      onClick={() => {
                        navigate("/training/" + session.id);
                      }}
                    >
                      Training
                    </Button>
                    <Button
                      className="ms-2 float-end"
                      variant="outline-success"
                      size="sm"
                      onClick={() => {
                        navigate("/broadcasterdual/" + session.id);
                      }}
                    >
                      Brodcaster Dual
                    </Button>
                    <Button
                      className="ms-2 float-end"
                      variant="success"
                      size="sm"
                      onClick={() => {
                        navigate("/broadcaster/" + session.id);
                      }}
                    >
                      Broadcaster
                    </Button>
                    <Button
                      className="float-end"
                      variant="primary"
                      size="sm"
                      onClick={() => {
                        navigate("/translator/" + session.id);
                      }}
                    >
                      Translator
                    </Button>
                    <div className="me-4 float-end ">
                      <span className="text-secondary align-middle">
                        {new Date(session.createdAt).toLocaleString()}
                      </span>
                    </div>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>
    );
  }

  function renderCreateButton() {
    return createSessionInFlight ? (
      <Button size="sm" variant="primary" disabled>
        <span>Create </span>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      </Button>
    ) : (
      <Button
        variant="primary"
        size="sm"
        onClick={() => {
          handleSubmit();
        }}
      >
        Create
      </Button>
    );
  }

  return (
    <>
      <NavigationBar />
      <Modal
        show={showDeleteModal}
        onHide={() => {
          setShowDeleteModal(false);
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Session</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>{deletingSessionName}</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelDelete}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              deleteSession(deletingSessionID);
            }}
          >
            {deleteSessionInFlight ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : null}
            Yes, I'm sure. Delete!
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showEditModal}
        onHide={() => {
          setShowEditModal(false);
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Session Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>{editingSessionName}</h3>
          <Form.Group>
            <Form.Label>Enter New Name</Form.Label>
            <Form.Control
              type="text"
              defaultValue={editingSessionName}
              onChange={(event) => {
                setEditName(event.target.value);
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelEdit}>
            Cancel
          </Button>
          <Button
            // variant="danger"
            onClick={() => {
              // deleteSession(deletingSessionID);
              updateSession(editingSessionId);
            }}
          >
            {deleteSessionInFlight ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : null}
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <Container fluid>
        <Row className="p-2">
          {userContext.isSuperAdmin ? (
            <>
              <Col xs={4} className="p-0">
                <Form.Control
                  type="text"
                  id="createSessionText"
                  value={newSessionName}
                  size="sm"
                  onChange={handleSessionNameInputChange}
                  className="d-inline"
                  placeholder="Enter new session name"
                />
              </Col>
              <Col xs={4} className="px-2">
                {renderCreateButton()}
              </Col>
            </>
          ) : null}
          <Col>
            <Button
              className="ms-1 float-end"
              variant="secondary"
              size="sm"
              onClick={() => {
                signOut();
              }}
            >
              Sign Out
            </Button>
            <Button
              className="float-end"
              variant="secondary"
              size="sm"
              onClick={() => {
                navigate("/macro/");
              }}
            >
              User Macro
            </Button>
            {props.hasOauthInfo ? (
              <Button
                className="float-end me-1"
                variant="outline-secondary"
                size="sm"
                disabled
              >
                Google Logged In
              </Button>
            ) : (
              <Button
                className="float-end me-1"
                variant="secondary"
                size="sm"
                onClick={() => {
                  signIn();
                }}
              >
                Google Login
              </Button>
            )}
            {userContext.isSuperAdmin ? (
              <Button
                className="me-1 float-end"
                size="sm"
                variant="secondary"
                onClick={() => {
                  navigate("/artists/");
                }}
              >
                Artists
              </Button>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="me-2 float-end">
              Hello, <i>{userContext.username}</i>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>Translation Sessions</h4>
          </Col>
        </Row>
        {renderSessionList()}
      </Container>
    </>
  );
}

// @flow

import React from "react";
import { API, PubSub } from "aws-amplify";
import { useEffect, useState, useRef, useContext } from "react";

import { Container, Row, Col, Table, Stack } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import UserContext from "./../utils/UserContext";
import { getSessionUsernameTopic } from "../utils/SessionUsers";

import {
  queryScriptSheetsBySessionSheetsIdIndex,
  queryUserKeyCommandsByUserIdCommandNameIndex as queryUserKeyCommands,
  queryBookmarksBySessionIdRowIndex,
} from "./../graphql/queries";

import {
  updateSession as updateSessionMutation,
  createMessage as createMessageMutation,
  updateScriptSheets as updateScriptSheetsMutation,
  createScriptSheets as createScriptSheetsMutation,
  deleteScriptSheets as deleteScriptSheetsMutation,
  createBookmarks as createBookmarksMutation,
} from "./../graphql/mutations";

import { onUpdateSession } from "../graphql/subscriptions";

import { DEFAULT_MESSAGE_TYPE } from "./../utils/util";
import SheetsLoadView from "./SheetsLoadView.react";
import BookmarksBar from "./BookmarksBar.react";

type Props = $ReadOnly<{
  sessionId: string,
  setSheetsInput: React.Dispatch<React.SetStateAction<any>>,
  setSheetsInputIndex: React.Dispatch<React.SetStateAction<any>>,
  isYoutubeView: Boolean,
  prevDisplay: Boolean,
  selectedUser: string,
}>;

async function createMessage(
  sessionId: string,
  text: string,
  scriptIndex: number
) {
  if (sessionId === "") {
    return;
  }
  const ret = await API.graphql({
    query: createMessageMutation,
    variables: {
      input: {
        sessionMessagesId: sessionId,
        text,
        scriptIndex: scriptIndex,
        type: DEFAULT_MESSAGE_TYPE,
      },
    },
  });
}

async function deleteScriptSheets(id: string) {
  const ret = await API.graphql({
    query: deleteScriptSheetsMutation,
    variables: {
      input: {
        id: id,
      },
    },
  });
}

async function updateSessionSheetsUpdateCount(sessionId) {
  const ret = await API.graphql({
    query: updateSessionMutation,
    variables: {
      input: {
        id: sessionId,
        sheetsUpdateCount: 1,
      },
    },
  });
}

async function updateScriptSheetsScript(id: string, editableScript: number) {
  const ret = await API.graphql({
    query: updateScriptSheetsMutation,
    variables: {
      input: {
        id: id,
        script: document.getElementById(`textarea${editableScript - 1}Script`)
          .value,
        // script: script,
      },
    },
  });
}

async function updateScriptSheetsTranslation(
  id: string,
  editableTranslation: number
) {
  const ret = await API.graphql({
    query: updateScriptSheetsMutation,
    variables: {
      input: {
        id: id,
        translation: document.getElementById(
          `textarea${editableTranslation - 1}Translation`
        ).value,
      },
    },
  });
}

async function updateScriptSheetsBackgroundColor(
  id: string,
  backgroundColor: string
) {
  const ret = await API.graphql({
    query: updateScriptSheetsMutation,
    variables: {
      input: {
        id: id,
        backgroundColor: backgroundColor,
      },
    },
  });
}

async function updateScriptSheetsTextColor(id: string, textColor: string) {
  const ret = await API.graphql({
    query: updateScriptSheetsMutation,
    variables: {
      input: {
        id: id,
        textColor: textColor,
      },
    },
  });
}

async function fetchUserKeyCommands(userId) {
  try {
    const apiData = await API.graphql({
      query: queryUserKeyCommands,
      variables: {
        userId: userId,
      },
    });
    return apiData.data.queryUserKeyCommandsByUserIdCommandNameIndex.items;
  } catch (e) {
    console.log(e);
  }
}

const SheetRow = React.memo(
  ({
    line,
    idx,
    isSelected,
    setSelectedRow,
    isEditableScript,
    editableScript,
    setEditableScript,
    isEditableTranslation,
    editableTranslation,
    setEditableTranslation,
    isYoutubeView,
    prevDisplay,
    sessionId,
    handleSheetsInput,
    setSheetsInput,
    setSheetsInputIndex,
    setShouldScroll,
    myRef,
    isMyRef,
    isPartnerSelected,
    partnerRef,
    isPartnerRef,
    showName,
  }) => {
    console.log(`mapping`);

    const styles = {
      td: {
        fontFamily: "Arial",
        padding: "5px",
        verticalAlign: "middle",
        wordBreak: "break-word",
        whiteSpace: "pre-wrap",
      },
      tdIndex: {
        fontFamily: "Arial",
        padding: "5px",
        verticalAlign: "middle",
      },
      tdName: {
        fontFamily: "Arial",
        padding: "0px",
        verticalAlign: "middle",
        whiteSpace: "pre-wrap",
      },
    };

    function handleClick(event, idx, type) {
      if (event.detail === 1) {
        if (idx + 1 !== editableScript && idx + 1 !== editableTranslation) {
          setShouldScroll(false);
          setSelectedRow(idx);
          document.getElementById("chat-input-field").focus();
        }
      } else if (event.detail === 2 && type === "script") {
        setEditableScript(idx + 1);
      } else if (event.detail === 2 && type === "translation") {
        setEditableTranslation(idx + 1);
      }
    }

    function handleSendTranslation(translation, sessionId, index) {
      createMessage(sessionId, translation, index);
    }

    function handleStyle(selected, partnerSelected, editable) {
      if (selected) {
        return {
          border: "2px solid #337BB5",
          background: line.backgroundColor,
          color: line.textColor,
          fontWeight: "bold",
          cursor: "default",
        };
      } else if (selected && editable) {
        return {
          background: line.backgroundColor,
          color: line.textColor,
          cursor: "default",
        };
      } else if (partnerSelected) {
        return {
          border: "2px solid #009405",
          background: line.backgroundColor,
          color: line.textColor,
          fontWeight: "bold",
          cursor: "default",
        };
      } else {
        return {
          background: line.backgroundColor,
          color: line.textColor,
          cursor: "default",
        };
      }
    }

    function handleRef(selected, partnerSelected) {
      if (selected) {
        return myRef;
      } else if (partnerSelected) {
        return partnerRef;
      }
    }

    return (
      <tr
        key={idx}
        style={handleStyle(isSelected, isPartnerSelected)}
        ref={handleRef(isMyRef, isPartnerRef)}
      >
        <td
          align="center"
          style={styles.tdIndex}
          onClick={(event) => {
            if (event.detail === 2) {
              event.stopPropagation();
            }
            handleClick(event, idx);
          }}
        >
          {line.index}
        </td>
        {showName ? (
          <td
            align="center"
            style={styles.tdName}
            onClick={(event) => {
              if (event.detail === 2) {
                event.stopPropagation();
              }
              handleClick(event, idx);
            }}
          >
            {line.name}
          </td>
        ) : null}
        <td
          style={styles.td}
          onClick={(event) => {
            handleClick(event, idx, "script");
          }}
        >
          {isEditableScript ? (
            <textarea
              autoFocus
              id={`textarea${idx}Script`}
              style={{
                width: "100%",
                height: "100%",
                fontWeight: "normal",
              }}
              defaultValue={line.script}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  updateScriptSheetsScript(line.id, editableScript);
                  setEditableScript(0);
                  setTimeout(function () {
                    updateSessionSheetsUpdateCount(sessionId);
                  }, 300);
                } else if (
                  event.key === "ArrowDown" ||
                  event.key === "ArrowUp"
                ) {
                  event.stopPropagation();
                } else if (event.key === "Escape") {
                  setEditableScript(0);
                }
              }}
              onFocus={(e) => {
                const value = e.target.value;
                e.target.value = "";
                e.target.value = value;
              }}
              onBlur={() => {
                updateScriptSheetsScript(line.id, editableScript);
                setEditableScript(0);
                setTimeout(function () {
                  updateSessionSheetsUpdateCount(sessionId);
                }, 300);
              }}
            ></textarea>
          ) : (
            line.script
          )}
        </td>
        <td
          style={styles.td}
          onClick={(event) => {
            handleClick(event, idx, "translation");
          }}
        >
          {isEditableTranslation ? (
            <textarea
              autoFocus
              id={`textarea${idx}Translation`}
              style={{
                width: "100%",
                height: "100%",
                fontWeight: "normal",
              }}
              defaultValue={line.translation}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  updateScriptSheetsTranslation(line.id, editableTranslation);
                  setEditableTranslation(0);
                  setTimeout(function () {
                    updateSessionSheetsUpdateCount(sessionId);
                  }, 300);
                } else if (
                  event.key === "ArrowDown" ||
                  event.key === "ArrowUp"
                ) {
                  event.stopPropagation();
                } else if (event.key === "Escape") {
                  setEditableTranslation(0);
                }
              }}
              onFocus={(e) => {
                const value = e.target.value;
                e.target.value = "";
                e.target.value = value;
              }}
              onBlur={() => {
                updateScriptSheetsTranslation(line.id, editableTranslation);
                setEditableTranslation(0);
                setTimeout(function () {
                  updateSessionSheetsUpdateCount(sessionId);
                }, 300);
              }}
            ></textarea>
          ) : (
            line.translation
          )}
        </td>
        {/* <td align="center">{line.wordCount}</td> */}
        <td style={styles.tdIndex}>
          <Button
            variant="outline-secondary"
            size="sm"
            disabled={isYoutubeView && prevDisplay}
            onClick={(event) => {
              document.getElementById("chat-input-field").focus();
              handleSendTranslation(line.translation, sessionId, line.index);
              handleClick(event, idx + 1);
            }}
          >
            Send
          </Button>
        </td>
      </tr>
    );
  }
);

const SheetRows = ({
  sheets,
  setSheets,
  selectedRow,
  setSelectedRow,
  editableScript,
  setEditableScript,
  editableTranslation,
  setEditableTranslation,
  isYoutubeView,
  prevDisplay,
  sessionId,
  setSheetsInput,
  setSheetsInputIndex,
  myRef,
  handleSheetsInput,
  autoScrollRows,
  partnerSelectedRow,
  partnerRef,
  showName,
  setShouldScroll,
}) => {
  const isSelected = (idx, selectedRow) => {
    if (idx === selectedRow) {
      return true;
    } else {
      return false;
    }
  };

  const isPartnerSelected = (idx, partnerSelectedRow) => {
    if (idx === partnerSelectedRow) {
      return true;
    } else {
      return false;
    }
  };

  const isMyRef = (idx, selectedRow) => {
    if (idx === selectedRow - autoScrollRows) {
      return true;
    } else if (selectedRow < autoScrollRows && idx === selectedRow) {
      return true;
    } else {
      return false;
    }
  };

  const isPartnerRef = (idx, partnerSelectedRow) => {
    if (idx === partnerSelectedRow - autoScrollRows) {
      return true;
    } else if (
      partnerSelectedRow < autoScrollRows &&
      idx === partnerSelectedRow
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isEditableScript = (idx, editableScript) => {
    if (idx + 1 === editableScript) {
      return true;
    } else {
      return false;
    }
  };
  const isEditableTranslation = (idx, editableTranslation) => {
    if (idx + 1 === editableTranslation) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <tbody>
      {sheets &&
        sheets.map((line, idx) => {
          return (
            <SheetRow
              key={line.index}
              line={line}
              idx={idx}
              isSelected={isSelected(idx, selectedRow)}
              isMyRef={isMyRef(idx, selectedRow)}
              setSelectedRow={setSelectedRow}
              editableScript={editableScript}
              editableTranslation={editableTranslation}
              setEditableScript={setEditableScript}
              isEditableScript={isEditableScript(idx, editableScript)}
              setEditableTranslation={setEditableTranslation}
              isEditableTranslation={isEditableTranslation(
                idx,
                editableTranslation
              )}
              isYoutubeView={isYoutubeView}
              prevDisplay={prevDisplay}
              sessionId={sessionId}
              setSheetsInput={setSheetsInput}
              setSheetsInputIndex={setSheetsInputIndex}
              myRef={myRef}
              isPartnerSelected={isPartnerSelected(idx, partnerSelectedRow)}
              partnerRef={partnerRef}
              isPartnerRef={isPartnerRef(idx, partnerSelectedRow)}
              showName={showName}
              setShouldScroll={setShouldScroll}
            />
          );
        })}
    </tbody>
  );
};

export default function SheetsView(props: Props): React$Element<any> {
  const userContext = useContext(UserContext);

  const [sheets, setSheets] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);
  const [partnerSelectedRow, setPartnerSelectedRow] = useState(-1);
  const [subscription, setSubscription] = useState();
  // const [editableRow, setEditableRow] = useState(0);
  const [editableScript, setEditableScript] = useState(0);
  const [editableTranslation, setEditableTranslation] = useState(0);
  const [editing, setEditing] = useState("");
  const [isInsertDisabled, setIsInsertDisabled] = useState(false);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");
  const [textColor, setTextColor] = useState("#000000");
  const [autoScrollRows, setAutoScrollRows] = useState(2);
  const [followPartnerScroll, setFollowPartnerScroll] = useState(false);
  const [shouldScroll, setShouldScroll] = useState(true);

  const [showName, setShowName] = useState(true);
  const [nameWidth, setNameWidth] = useState(5);
  const [scriptWidth, setScriptWidth] = useState(45);
  const [transWidth, setTransWidth] = useState(45);

  const [sheetsArray, setSheetsArray] = useState([]);

  const [sendTranslationKey, setSendTranslationKey] = useState("F4");
  const [sendScriptKey, setSendScriptKey] = useState("Off");
  const [moveUpKey, setMoveUpKey] = useState("ArrowUp");
  const [moveDownKey, setMoveDownKey] = useState("ArrowDown");
  // const [clearSubtitlesKey, setClearSubtitlesKey] = useState("F8");

  const [showBookmarks, setShowBookmarks] = useState(false);

  const myRef = useRef(null);
  const partnerRef = useRef(null);

  const executeScroll = () => {
    myRef.current && myRef.current.scrollIntoView();
  };
  const executePartnerScroll = () => {
    partnerRef.current && partnerRef.current.scrollIntoView();
  };

  async function handleSendSelectedTranslation() {
    // Don't create message if tranlsation is empty
    if (sheets[selectedRow].translation === "") {
      setSelectedRow((prev) => prev + 1);
      executeScroll();
    } else {
      await Promise.all([
        createMessage(
          props.sessionId,
          sheets[selectedRow].translation,
          sheets[selectedRow].index
        ),
      ]);
      setSelectedRow((prev) => prev + 1);
      executeScroll();
    }
  }

  async function handleSendSelectedScript() {
    // Don't create message if script is empty
    if (sheets[selectedRow].script === "") {
      setSelectedRow((prev) => prev + 1);
      executeScroll();
    } else {
      await Promise.all([
        createMessage(
          props.sessionId,
          sheets[selectedRow].script,
          sheets[selectedRow].index
        ),
      ]);
      setSelectedRow((prev) => prev + 1);
      executeScroll();
    }
  }

  // for sending translation to YouTube
  // isYoutubeView boolean variable to prevent rerender when in regular translator view
  function handleSheetsInput(text) {
    if (props.isYoutubeView) {
      props.setSheetsInput(text);
      props.setSheetsInputIndex(
        (prevSheetsInputIndex) => prevSheetsInputIndex + 1
      );
    }
  }

  // Sheets initial fetch
  useEffect(() => {
    async function initialFetch() {
      const sheets = await fetchScriptSheets(props.sessionId);
      setSheets(sheets);
    }
    initialFetch();
  }, [props.sessionId]);

  // User Key Command initial fetch
  useEffect(() => {
    async function initialFetch() {
      const fetchedKeyCommands = await fetchUserKeyCommands(userContext.userId);
      const hasValue = (command) => {
        for (const obj of fetchedKeyCommands) {
          if (obj.commandName === command) {
            return true;
          }
        }
        return false;
      };

      hasValue("sendTranslation") &&
        setSendTranslationKey(
          fetchedKeyCommands.find(
            (command) => command.commandName === "sendTranslation"
          ).triggerKey
        );
      hasValue("sendScript") &&
        setSendScriptKey(
          fetchedKeyCommands.find(
            (command) => command.commandName === "sendScript"
          ).triggerKey
        );
      hasValue("moveUp") &&
        setMoveUpKey(
          fetchedKeyCommands.find((command) => command.commandName === "moveUp")
            .triggerKey
        );
      hasValue("moveDown") &&
        setMoveDownKey(
          fetchedKeyCommands.find(
            (command) => command.commandName === "moveDown"
          ).triggerKey
        );
    }
    initialFetch();
  }, [props.sessionId]);

  // Subscribe to session sheets update count
  useEffect(() => {
    const subscription = API.graphql({
      query: onUpdateSession,
      variables: {
        id: props.sessionId,
      },
    }).subscribe({
      next: () => {
        async function updateFetch() {
          const sheets = await fetchScriptSheets(props.sessionId);
          setSheets(sheets);
        }
        updateFetch();
      },
      error: (error) => console.warn(error),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleHotkeyPress);
    return () => {
      window.removeEventListener("keydown", handleHotkeyPress);
    };
  }, [sendTranslationKey, sendScriptKey, moveDownKey, moveUpKey]);

  // Prevent selectedRow from exceeding the sheet length
  useEffect(() => {
    if (selectedRow > sheets.length - 1) {
      setSelectedRow(sheets.length - 1);
      // console.log('reached end');
    }
  }, [selectedRow]);

  // Publish selectedRow to PubSub
  useEffect(() => {
    publishSelectedRow();
  }, [selectedRow]);

  // Subscribe to partner's selectedRow
  useEffect(() => {
    if (props.selectedUser === "") {
      subscription && subscription.unsubscribe();
      setPartnerSelectedRow(-1);
      return;
    }
    subscription && subscription.unsubscribe();
    const topic = getSessionUsernameTopic(
      `${props.sessionId}sheets`,
      props.selectedUser
    );
    const sub = PubSub.subscribe(topic).subscribe({
      next: (data) => {
        setPartnerSelectedRow(data.value);
      },
      error: (error) => console.error(error),
      complete: () => console.log("Done"),
    });
    setSubscription(sub);
    return () => {
      subscription && subscription.unsubscribe();
    };
  }, [props.username, props.sessionId, props.selectedUser]);

  // Auto-scroll to selection
  useEffect(() => {
    if (selectedRow > -1 && shouldScroll) {
      executeScroll();
      setShouldScroll(true);
    }
  }, [selectedRow, shouldScroll]);

  // Auto-scroll to partner selection
  useEffect(() => {
    if (partnerSelectedRow > -1 && followPartnerScroll) {
      executePartnerScroll();
    }
  }, [partnerSelectedRow]);

  async function fetchScriptSheets(sessionId) {
    try {
      const apiData = await API.graphql({
        query: queryScriptSheetsBySessionSheetsIdIndex,
        variables: {
          sessionSheetsId: sessionId,
        },
      });
      return apiData.data.queryScriptSheetsBySessionSheetsIdIndex.items;
    } catch (e) {
      console.log(e);
    }
  }

  async function createScriptSheets(
    sessionId: string,
    index: number,
    script: string,
    translation: string
  ) {
    const ret = await API.graphql({
      query: createScriptSheetsMutation,
      variables: {
        input: {
          sessionSheetsId: sessionId,
          isSelected: false,
          isEditable: false,
          index: index,
        },
      },
    });
  }

  async function updateScriptSheetsIndex(id: string, index: number) {
    const ret = await API.graphql({
      query: updateScriptSheetsMutation,
      variables: {
        input: {
          id: id,
          index: index,
        },
      },
    });
  }

  async function publishSelectedRow() {
    if (userContext.username === "") {
      return;
    }
    const topic = getSessionUsernameTopic(
      `${props.sessionId}sheets`,
      userContext.username
    );
    await PubSub.publish(topic, selectedRow);
  }

  function handleInsertRow() {
    const index = selectedRow;
    for (let idx = index + 1; idx < sheets.length; idx++) {
      updateScriptSheetsIndex(sheets[idx].id, sheets[idx].index + 1);
    }
    createScriptSheets(props.sessionId, index + 2);
    setIsInsertDisabled(true);
    setTimeout(() => {
      setIsInsertDisabled(false);
    }, 1500);
    setTimeout(function () {
      updateSessionSheetsUpdateCount(props.sessionId);
    }, 1500);
  }

  function handleUpdateBackgroundColor() {
    updateScriptSheetsBackgroundColor(sheets[selectedRow].id, backgroundColor);
    setTimeout(() => {
      updateSessionSheetsUpdateCount(props.sessionId);
    }, 500);
  }
  function handleUpdateTextColor() {
    updateScriptSheetsTextColor(sheets[selectedRow].id, textColor);
    setTimeout(() => {
      updateSessionSheetsUpdateCount(props.sessionId);
    }, 500);
  }

  function handleDeleteSelectedRow() {
    const index = selectedRow;
    for (let idx = index + 1; idx < sheets.length; idx++) {
      updateScriptSheetsIndex(sheets[idx].id, sheets[idx].index - 1);
      // console.log('update subscription')
    }
    deleteScriptSheets(sheets[index].id);
    setIsDeleteDisabled(true);
    setTimeout(() => {
      setIsDeleteDisabled(false);
    }, 1500);
    setTimeout(() => {
      updateSessionSheetsUpdateCount(props.sessionId);
    }, 1500);
  }

  const handleHotkeyPress = (e) => {
    if (e.key === sendTranslationKey) {
      // Cancel the default action, if needed
      e.preventDefault();
      document.getElementById("send-translation").click();
    } else if (e.key === sendScriptKey) {
      // Cancel the default action, if needed
      e.preventDefault();
      document.getElementById("send-script").click();
    } else if (e.key === moveUpKey) {
      e.preventDefault();
      setSelectedRow((prev) => {
        if (prev > 0) {
          return prev - 1;
        } else {
          return prev;
        }
      });
      executeScroll();
    } else if (e.key === moveDownKey) {
      e.preventDefault();
      setSelectedRow((prev) => prev + 1);
      executeScroll();
    }
  };

  return (
    <Container className="m-0 p-0">
      <Card>
        <Card.Header as="h5">
          <Row>
            <Col xs={2}>Script</Col>
            <Col xs={10}>
              <Button
                title="Send selected row and move down"
                id="send-translation"
                className="float-end ms-1"
                variant="success"
                size="sm"
                disabled={props.isYoutubeView && props.prevDisplay}
                onClick={() => {
                  handleSendSelectedTranslation();
                }}
              >
                Send Translation
                {sendTranslationKey === "Off"
                  ? null
                  : ` (${sendTranslationKey})`}
                {/* ({selectedRow + 1}) */}
              </Button>
              {sendScriptKey === "Off" ? null : (
                <Button
                  title="Send selected script and move down"
                  id="send-script"
                  className="float-end ms-1"
                  variant="success"
                  size="sm"
                  disabled={props.isYoutubeView && props.prevDisplay}
                  onClick={() => {
                    handleSendSelectedScript();
                  }}
                >
                  Send Script ({sendScriptKey}){/* ({selectedRow + 1}) */}
                </Button>
              )}
              <Button
                className="float-end ms-1"
                variant="outline-secondary"
                size="sm"
                // disabled
                onClick={() => {
                  setShowEditModal(true);
                }}
              >
                Google Sheets
              </Button>
              <Button
                className="float-end ms-1"
                variant={showMenu ? "secondary" : "outline-secondary"}
                size="sm"
                onClick={() => {
                  setShowMenu((prev) => !prev);
                }}
              >
                Menu
              </Button>
              <Button
                className="float-end"
                variant={showBookmarks ? "secondary" : "outline-secondary"}
                size="sm"
                onClick={() => {
                  setShowBookmarks((prev) => !prev);
                }}
              >
                Bookmarks
              </Button>
            </Col>
          </Row>
        </Card.Header>
        {showMenu ? (
          <Container fluid className="m-0 p-0">
            <Card>
              <Card.Body className="m-1 p-0">
                <Row className="m-1">
                  <Col xs={4}>
                    <Row>
                      <Col>
                        <Button
                          className="float-start"
                          size="sm"
                          variant="outline-secondary"
                          onClick={handleUpdateBackgroundColor}
                        >
                          Apply Row Color
                        </Button>
                        <Form.Control
                          className="float-start"
                          size="sm"
                          type="color"
                          value={backgroundColor}
                          onChange={(event) => {
                            setBackgroundColor(event.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          className="float-start"
                          size="sm"
                          variant="outline-secondary"
                          onClick={handleUpdateTextColor}
                        >
                          Apply Text Color
                        </Button>
                        <Form.Control
                          className="float-start"
                          size="sm"
                          type="color"
                          value={textColor}
                          onChange={(event) => {
                            setTextColor(event.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={5}>
                    <Stack direction="horizontal" gap={2}>
                      <div>
                        <Form.Label>Auto scroll rows:</Form.Label>
                        <Form.Control
                          style={{ width: "60%" }}
                          size="sm"
                          min="0"
                          type="number"
                          value={autoScrollRows}
                          onChange={(event) => {
                            setAutoScrollRows(event.target.value);
                          }}
                        />
                      </div>
                      <div>
                        <Form>
                          <Form.Check
                            label="Follow Partner"
                            checked={followPartnerScroll}
                            onChange={() => {
                              setFollowPartnerScroll((prev) => !prev);
                            }}
                          />
                        </Form>

                        <Form>
                          <Form.Check
                            label="Show Name"
                            checked={showName}
                            onChange={() => {
                              setShowName((prev) => !prev);
                            }}
                          />
                        </Form>
                      </div>
                    </Stack>
                  </Col>
                  <Col xs={3}>
                    <Button
                      className="float-end"
                      variant="outline-danger"
                      size="sm"
                      onClick={(event) => {
                        if (event.detail >= 2) {
                          event.stopPropagation();
                        } else {
                          handleDeleteSelectedRow();
                        }
                      }}
                      disabled={isDeleteDisabled || isInsertDisabled}
                    >
                      {isDeleteDisabled ? "Deleting..." : "Delete Row"}
                    </Button>
                    <Button
                      className="float-end"
                      variant="outline-success"
                      size="sm"
                      onClick={(event) => {
                        if (event.detail >= 2) {
                          event.stopPropagation();
                        } else {
                          handleInsertRow();
                        }
                      }}
                      disabled={isInsertDisabled || isDeleteDisabled}
                    >
                      {isInsertDisabled ? "Inserting..." : "Insert Row"}
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body className="m-1 p-0">
                <Row className="d-flex ms-3" style={{ width: "50%" }}>
                  <Col
                    style={{ fontWeight: "bold" }}
                    className="m-0 p-0"
                    xs={3}
                  >
                    Column Widths (%)
                  </Col>
                  <Col xs={3}>
                    <Form.Label className="m-0 p-0">Name</Form.Label>
                    <Form.Control
                      style={{ width: "100%" }}
                      size="sm"
                      min="1"
                      type="number"
                      value={nameWidth}
                      onChange={(event) => {
                        setNameWidth(event.target.value);
                      }}
                    />
                  </Col>
                  <Col xs={3}>
                    <Form.Label className="m-0 p-0">Script</Form.Label>
                    <Form.Control
                      style={{ width: "100%" }}
                      size="sm"
                      min="1"
                      type="number"
                      value={scriptWidth}
                      onChange={(event) => {
                        setScriptWidth(event.target.value);
                      }}
                    />
                  </Col>
                  <Col xs={3}>
                    <Form.Label className="m-0 p-0">Trans</Form.Label>
                    <Form.Control
                      style={{ width: "100%" }}
                      size="sm"
                      min="1"
                      type="number"
                      value={transWidth}
                      onChange={(event) => {
                        setTransWidth(event.target.value);
                      }}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
        ) : null}
        {showBookmarks ? (
          <BookmarksBar
            sessionId={props.sessionId}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            executeScroll={executeScroll}
            setShouldScroll={setShouldScroll}
          />
        ) : null}
        <Card.Body className="m-0 p-0">
          <Container
            className="p-0 m-0"
            style={{
              maxHeight: "77vh",
              // maxHeight: "560px",
              overflow: "scroll",
              overflowX: "hidden",
            }}
          >
            <Row>
              <Col>
                <Table
                  bordered
                // hover
                >
                  <thead>
                    <tr align="center">
                      {/* <th style={{ width: "10px" }}>#</th> */}
                      <th style={{ width: "4%" }}>#</th>
                      {showName ? (
                        <th style={{ width: `${nameWidth}%` }}>Name</th>
                      ) : null}
                      <th style={{ width: `${scriptWidth}%` }}>Script</th>
                      <th style={{ width: `${transWidth}%` }}>Translation</th>
                      {/* <th>Count</th> */}
                      <th style={{ width: "4%" }}>Send</th>
                    </tr>
                  </thead>
                  <SheetRows
                    sheets={sheets}
                    setSheets={setSheets}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    editableScript={editableScript}
                    // setEditableRow={setEditableRow}
                    editableTranslation={editableTranslation}
                    setEditableScript={setEditableScript}
                    setEditableTranslation={setEditableTranslation}
                    isYoutubeView={props.isYoutubeView}
                    prevDisplay={props.prevDisplay}
                    sessionId={props.sessionId}
                    setSheetsInput={props.setSheetsInput}
                    setSheetsInputIndex={props.setSheetsInputIndex}
                    myRef={myRef}
                    handleSheetsInput={handleSheetsInput}
                    autoScrollRows={autoScrollRows}
                    partnerSelectedRow={partnerSelectedRow}
                    partnerRef={partnerRef}
                    showName={showName}
                    setShouldScroll={setShouldScroll}
                  />
                </Table>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
      <SheetsLoadView
        sessionId={props.sessionId}
        show={showEditModal}
        sheets={sheets}
        setSheets={setSheets}
        onHide={() => {
          setShowEditModal(false);
        }}
      />
    </Container>
  );
}

// @flow

import { API } from "aws-amplify";
import React, { useState, useEffect, useRef } from "react";
import { messagesBySession, getSession } from "./../graphql/queries";
import { Container, Row, Col, Form, Table, Button, Stack } from "react-bootstrap";
import Card from "react-bootstrap/Card";

type Props = $ReadOnly<{
  sessionId: string,
}>;

async function fetchMessages(sessionId) {
  const apiData = await API.graphql({
    query: messagesBySession,
    variables: {
      sessionMessagesId: sessionId,
      sortDirection: "DESC",
      limit: 99999999,
    },
  });
  return apiData.data?.messagesBySession?.items.reverse() ?? [];
}

export default function ScriptView(props: Props): React$Element<any> {
  const [messages, setMessages] = useState([]);
  const [sessionName, setSessionName] = useState("");
  const [startTime, setStartTime] = useState("");
  const [startMessageID, setStartMessageID] = useState("");
  const [minSeconds, setMinSeconds] = useState(2);
  const [maxSeconds, setMaxSeconds] = useState(6);
  const [minLength, setMinLength] = useState(10);
  const [maxLength, setMaxLength] = useState(60);
  const [secBeforeStart, setSecBeforeStart] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(messages.length)
  // const [srtArray, setSrtArray] = useState([]);

  const startRef = useRef(null);
  const endRef = useRef(null);
  const executeScrollStart = () => startRef.current.scrollIntoView({ block: "center" });
  const executeScrollEnd = () => endRef.current.scrollIntoView({ block: "center" });
  const scrollToTop = () => {
    const column = document.getElementById('tableColumn');
    column.scrollTop = 0;
  }

  const styles = {
    inputText: {
      width: "20%",
      float: "left",
      padding: "10px",
      whiteSpace: "nowrap"
    },
    startTime: {
      cursor: "pointer",
      textDecoration: "underline",
      fontWeight: "600",
    },
    endTime: {
      cursor: "pointer",
      textDecoration: "underline",
      fontWeight: "600"
    },
    selectedRows: {
      backgroundColor: "#e8f4fc"
    }
  };

  const handleRef = index => {
    if (index + 1 === startIndex) {
      return startRef;
    } else if (index + 1 === endIndex) {
      return endRef;
    }
  }


  const createSrt = () => {
    const newArray = [];
    for (let idx = startIndex; idx < endIndex; idx++) {
      const srt =
        `${idx + 1 - startIndex}\n${toSrtTimestamp(messages[idx].formattedTime)} --> ${toSrtTimestamp(calculateEndTime(
          messages[idx].text,
          timeToSeconds(messages[idx].formattedTime),
          idx < messages.length - 1 ? timeToSeconds(messages[idx + 1].formattedTime) : "last"
        ))}\n${messages[idx].text}\n\n`;
      newArray.push(srt);

    }
    document.getElementById("srt-file").value = newArray.join("");
  }

  const downloadSrt = async () => {
    var text = document.getElementById("srt-file").value;
    text = text.replace(/\n/g, "\r\n"); // To retain the Line breaks.

    // Prompt the user to choose a download location and file name
    const opts = {
      types: [
        {
          description: 'Subtitle file',
          accept: { 'text/plain': ['.srt'] },
        },
      ],
      suggestedName: `${sessionName}.srt`, // Set the default file name
    };
    const fileHandle = await window.showSaveFilePicker(opts);

    if (fileHandle) { // If the user chose a file name and location
      const writableStream = await fileHandle.createWritable();
      await writableStream.write(text);
      await writableStream.close();
    }
  };



  function timeToSeconds(t) {
    let time;

    if (t[0] === "-") {
      time = t.substring(1);
      const array = time.split(":");
      const milliseconds = (parseInt(array[0] * 1000, 10) * 60 * 60) + (parseInt(array[1] * 1000, 10) * 60) + parseInt(array[2] * 1000, 10)
      return milliseconds * -1;
    } else {
      time = t;
      const array = time.split(":");
      const milliseconds = (parseInt(array[0] * 1000, 10) * 60 * 60) + (parseInt(array[1] * 1000, 10) * 60) + parseInt(array[2] * 1000, 10)
      return milliseconds;
    }

  }


  function calculateEndTime(text, milliseconds, nextSeconds) {

    let displayLengthMs;
    const length = text.length;

    if (length <= minLength) {
      displayLengthMs = minSeconds * 1000;
    } else if (length > minLength && length < maxLength) {
      displayLengthMs = Math.round(100 * (minSeconds + (maxSeconds - minSeconds) * ((length - minLength) / (maxLength - minLength))));
    } else if (maxLength <= length) {
      displayLengthMs = maxSeconds * 1000;
    }

    const endTime = milliseconds + displayLengthMs;

    if (nextSeconds < endTime) {

      if (nextSeconds < 0) {
        return "-" + secondsToTime(-1 * nextSeconds);
      } else {
        return secondsToTime(nextSeconds);
      }

    } else {

      if (endTime < 0) {
        return "-" + secondsToTime(-1 * endTime)
      } else {
        return secondsToTime(endTime);
      }

    }
  }

  function toSrtTimestamp(time) {
    const newTime = addStartTime(time);
    const array = newTime.split(".");
    const srtTime = array.join(",");
    return srtTime;
  }

  function addStartTime(t) {
    if (t[0] === "-") {
      return "-" + secondsToTime(timeToSeconds(t.substring(1)) + secBeforeStart * 1000);
    } else {
      return secondsToTime(timeToSeconds(t) + secBeforeStart * 1000);
    }
  }

  const convertToKst = (t) => {
    const time = new Date(t);
    time.setTime(time.getTime() + (9 * 60 * 60 * 1000));
    const kst = time.toISOString();
    return kst;
  }

  const getDate = (t) => {
    const date = `${t.substring(2, 4)}/${t.substring(5, 7)}/${t.substring(8, 10)}`;
    return date;
  }

  const getTime = (t) => {
    const time = t.substring(11, 23);
    return time;
  }


  useEffect(() => {
    async function initialFetch() {
      const messages = await fetchMessages(props.sessionId);
      if (messages.length == 0) {
        setMessages([]);
        setStartTime("");
        setStartMessageID("");
      } else {
        setStartTime(messages[0].createdAt);
        setStartMessageID(messages[0].id);
        setMessages(recalculateTime(messages, messages[0].createdAt));
        setEndIndex(messages.length);
      }
    }
    initialFetch();
  }, [props.sessionId]);

  useEffect(() => {
    async function fetchSession() {
      const apiData = await API.graphql({
        query: getSession,
        variables: {
          id: props.sessionId,
        },
      });
      setSessionName(apiData.data.getSession.name);
    }
    fetchSession();
  }, [props.sessionId]);

  function recalculateTime(prevMessages, startTime: string) {
    function calculateMessageSecondsSinceStart(time: string) {
      if (new Date(time) < new Date(startTime)) {
        return (
          "-" +
          secondsToTime(
            parseFloat(new Date(startTime) - new Date(time))
          ).toString()
        );
      } else {
        return secondsToTime(parseFloat(new Date(time) - new Date(startTime)));
      }
    }
    if (prevMessages == null) {
      return [];
    }
    prevMessages.forEach((msg) => {
      msg.formattedTime = calculateMessageSecondsSinceStart(msg.createdAt);
    });
    return prevMessages;
  }

  function secondsToTime(e) {
    const secs = e / 1000;
    var h = Math.floor(secs / 3600)
      .toString()
      .padStart(2, "0"),
      m = Math.floor((secs % 3600) / 60)
        .toString()
        .padStart(2, "0"),
      s = Math.floor(secs % 60)
        .toString()
        .padStart(2, "0"),
      ms = Math.floor(e % 1000)
        .toString()
        .padStart(3, "00");
    return h + ":" + m + ":" + s + "." + ms;
  }

  const handleClickStart = (id, createdAt, index) => {
    setStartMessageID(id);
    setStartTime(createdAt);
    setMessages((prevMessages) =>
      recalculateTime(prevMessages, createdAt)
    );
    setStartIndex(index);
  }

  const timestampSettings = (
    <Col xs={7}>
      <div style={styles.inputText}>
        <Form.Label>Min Display Sec</Form.Label>
        <Form.Control
          size="sm"
          type="number"
          value={minSeconds}
          min="1"
          max={maxSeconds}
          onChange={(event) => {
            setMinSeconds(event.target.value);
          }}
        />
      </div>
      <div style={styles.inputText}>
        <Form.Label>Max Display Sec</Form.Label>
        <Form.Control
          size="sm"
          type="number"
          min={minSeconds}
          max={99}
          value={maxSeconds}
          onChange={(event) => {
            setMaxSeconds(event.target.value);
          }}
        />
      </div>
      <div style={styles.inputText}>
        <Form.Label>Min Thres Chars</Form.Label>
        <Form.Control
          size="sm"
          type="number"
          value={minLength}
          onChange={(event) => {
            setMinLength(event.target.value);
          }}
        />
      </div>
      <div style={styles.inputText}>
        <Form.Label>Max Thres Chars</Form.Label>
        <Form.Control
          size="sm"
          type="number"
          value={maxLength}
          onChange={(event) => {
            setMaxLength(event.target.value);
          }}
        />
      </div>
      <div style={styles.inputText}>
        <Form.Label>Seconds Before Start</Form.Label>
        <Form.Control
          size="sm"
          type="number"
          value={secBeforeStart}
          onChange={(event) => {
            setSecBeforeStart(event.target.value);
          }}
        />
      </div>
    </Col>
  )

  const srtMaker = (
    <Col xs={5}>
      <Col>
        <div>
          <textarea
            type="text"
            id="srt-file"
            // value={srtArray.join("")}
            style={{ display: "flex", marginLeft: "auto", marginRight: "auto", width: "100%", height: "80px" }}
          >
          </textarea>
        </div>
      </Col>
      <Col>
        <Button
          className="float-end"
          size="sm"
          onClick={downloadSrt}
          variant="primary"
        >
          Download
        </Button>
        <Button
          className="float-end"
          size="sm"
          onClick={createSrt}
          variant="outline-primary"
        >
          Generate SRT
        </Button>
        <Button
          className="float-start"
          size="sm"
          onClick={startIndex === 0 ? scrollToTop : executeScrollStart}
          variant="outline-primary"
        >
          To Start
        </Button>
        <Button
          className="float-start"
          size="sm"
          onClick={executeScrollEnd}
          variant="outline-danger"
        >
          To End
        </Button>
      </Col>
    </Col>
  )

  return (
    <Container
      fluid
      className="m-0 p-0"
    >
      <Card >
        <Card.Header as="h4">{sessionName}</Card.Header>
        <Card.Body className="p-1">
          <Container fluid className="m-0">
            <Row>
              {timestampSettings}
              {srtMaker}
            </Row>
            <br />
            <Row>
              <Col
                id="tableColumn"
                className="p-0"
                style={{
                  maxHeight: "75vh",
                  overflowY: "scroll",
                }}
              >
                <Table bordered>
                  <thead
                    style={{
                      background: "#FFFFFF",
                      top: "0",
                      position: "sticky",
                      boxShadow: "0px 1px 0px 0.5px rgba(0, 0, 0, 1)",
                    }}
                  >
                    <tr align="center">
                      <th style={{ width: "6%" }}>Range</th>
                      <th style={{ width: "15%" }}>Actual Time (KST)</th>
                      {/* <th style={{ width: "75px" }}>Set Start</th> */}
                      {/* <th style={{ width: "75px" }}>Set End</th> */}
                      <th style={{ width: "9%" }}>Start Time</th>
                      <th style={{ width: "9%" }}>End Time</th>
                      <th style={{ width: "3%" }}>No.</th>
                      {/* <th style={{ width: "300px" }}>SRT Timestamp</th> */}
                      <th style={{ width: "50%" }}> Translation</th>
                      {/* <th style={{ width: "550px" }}>SRT</th> */}
                      <th style={{ width: "10%" }}> Username</th>
                    </tr>
                  </thead>
                  <tbody>
                    {messages != null && messages.length > 0
                      ? messages.map((message, index) => (
                        <tr
                          align="center"
                          style={{ verticalAlign: "middle" }}
                          key={message.id}
                          ref={handleRef(index)}
                        >
                          <td
                            style={index > startIndex - 1 && index < endIndex ? styles.selectedRows : {}}
                          >
                            {startMessageID === message.id ? (
                              <div style={{ color: "#4a5cff" }}>
                                <b>START ></b>
                              </div>
                            ) : endIndex - 1 === index ? (
                              <div style={{ color: "red" }}>
                                <b>END ></b>
                              </div>) : null}
                          </td>
                          <td>
                            <span
                              style={{ color: "#fc6060" }}
                            >
                              {getDate(convertToKst(message.createdAt))}{" "}
                            </span>
                            <span>
                              &nbsp;&nbsp;&nbsp;
                            </span>
                            <span
                              style={{ color: "#5e5e5e" }}
                            >
                              {getTime(convertToKst(message.createdAt))}
                            </span>
                          </td>
                          <td>
                            <div
                              style={startMessageID === message.id ? styles.startTime : { cursor: "pointer" }}
                              onClick={() => {
                                if (index + 1 < endIndex) {
                                  handleClickStart(message.id, message.createdAt, index);
                                } else {
                                  handleClickStart(message.id, message.createdAt, index)
                                  setEndIndex(index + 2);
                                }
                              }}
                            >
                              {addStartTime(message.formattedTime)}
                            </div>
                          </td>
                          <td>
                            <div
                              style={index === endIndex - 1 ? styles.endTime : { cursor: "pointer" }}
                              onClick={() => {
                                if (index - 1 < startIndex) {
                                  handleClickStart(messages[index - 1].id, messages[index - 1].createdAt, index - 1);
                                  setEndIndex(index + 1);
                                } else {
                                  setEndIndex(index + 1);
                                }
                              }}
                            >
                              {addStartTime(
                                calculateEndTime(
                                  message.text,
                                  timeToSeconds(message.formattedTime),
                                  index < messages.length - 1 ? timeToSeconds(messages[index + 1].formattedTime) : "last"
                                ))}
                            </div>
                          </td>
                          <td>
                            {index + 1 - startIndex}
                          </td>
                          <td align="left">
                            {message.text}
                          </td>
                          <td align="left">
                            {message.username}
                          </td>
                        </tr>
                      ))
                      : null}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </Container >
  );
}

// @flow

import { API } from "aws-amplify";
import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";

import { listUsers } from "./../graphql/queries";
import { updateUser as updateUserMutation } from "./../graphql/mutations";
import UserContext from "./../utils/UserContext";

import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";

type Props = $ReadOnly<{}>;

async function fetchAllUsers() {
  const apiData = await API.graphql({
    query: listUsers,
    variables: {},
  });
  return apiData.data?.listUsers?.items.reverse() ?? [];
}

export default function AdminView(props: Props): React$Element<any> {
  const [users, setUsers] = useState([]);
  const [toggled, setToggled] = useState(0);
  const userContext = useContext(UserContext);

  useEffect(() => {
    async function fetch() {
      const users = await fetchAllUsers();
      setUsers(users);
    }
    fetch();
  }, [toggled]);

  async function updateUserAllAccess(
    user: {
      id: string,
      username: string,
      name: string,
      isSuperAdmin: boolean,
      ...
    },
allowAccess
  ) {
  if (!checkPermission()) {
    return;
  }
  await API.graphql({
    query: updateUserMutation,
    variables: {
      input: {
        id: user.id,
        username: user.username,
        name: user.username,
        isSuperAdmin: user.isSuperAdmin,
        allowAllAccess: allowAccess,
      },
    },
  });
  setToggled(toggled + 1);
}

// Obviously change this to a real permission check later
function checkPermission() {
  const myUser = users.filter(
    (user) => user.username === userContext.username
  );
  if (myUser.length === 0) {
    return false;
  }
  return myUser[0].isSuperAdmin;
}

return (
  <Container fluid>
    <Row className="p-2">
      <Col xs={4} className="p-0">
        <ListGroup>
          {users.map((user) => (
            <ListGroup.Item key={user.username}>
              {user.username}
              <div style={{ float: "right" }}>
                {user.isSuperAdmin ? (
                  "Admin"
                ) : user.allowAllAccess ? (
                  <Button
                    size="sm"
                    variant="outline-danger"
                    onClick={() => {
                      updateUserAllAccess(user, false);
                    }}
                  >
                    Revoke Access
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => {
                      updateUserAllAccess(user, true);
                    }}
                  >
                    Grant Access
                  </Button>
                )}
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Col>
    </Row>
  </Container>
);
}

import React, { useState, useEffect, useContext } from "react";
import { API } from "aws-amplify";

import { sessionsByDate } from "./../graphql/queries";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";

type Props = $ReadOnly<{
  show: boolean,
  onHide: (boolean) => void,
  setOtherSessionUsers: React.Dispatch<React.SetStateAction<any>>,
}>;

export default function SessionUserSelectModal(
  props: Props
): React$Element<any> {
  const [sessionList, setSessionList] = useState([]);
  const [selectedSessionId, setSelectedSessionId] = useState("");
  const [selectedSessionName, setSelectedSessionName] = useState("");
  const [selectedUserName, setSelectedUserName] = useState("");

  useEffect(() => {
    fetchSessionList();
  }, [props.show]);

  async function fetchSessionList() {
    const apiData = await API.graphql({
      query: sessionsByDate,
      variables: { type: 0, sortDirection: "DESC" },
    });
    setSessionList(apiData.data?.sessionsByDate?.items ?? []);
  }

  const setDefault = () => {
    setSelectedSessionId("");
    setSelectedSessionName("");
    setSelectedUserName("");
  };

  const handleClose = () => {
    props.onHide(false);
    setDefault();
  };

  const handleLoadClick = () => {
    props.setOtherSessionUsers((prev) => [
      ...prev,
      { sessionId: selectedSessionId, username: selectedUserName },
    ]);
    props.onHide(false);
    setDefault();
  };

  return (
    <Modal show={props.show} onHide={handleClose} dialogClassName="modal-60w">
      <Modal.Header closeButton>
        <Modal.Title>Other Session Users (beta)</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <b>Select Session Name</b>
        <DropdownButton
          id="dropdown-item-button"
          //   title="Select Session"
          style={{ marginBottom: "20px" }}
          title={selectedSessionId ? selectedSessionName : "Select session"}
          variant="light"
        >
          {sessionList.map((session, index) => (
            <Dropdown.Item
              as="button"
              // style={{ overflow: "scroll" }}
              key={index}
              onClick={() => {
                setSelectedSessionId(session.id);
                setSelectedSessionName(session.name);
                // console.log(session.id);
              }}
            >
              <span>{session.name}</span>
            </Dropdown.Item>
          ))}
        </DropdownButton>
        <Form.Group>
          <Form.Label>
            <b>Enter Username</b>
          </Form.Label>
          <Form.Control
            type="text"
            defaultValue={selectedUserName}
            onChange={(event) => {
              setSelectedUserName(event.target.value);
            }}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button> */}
        <Button
          disabled={!selectedSessionId || !selectedUserName}
          onClick={handleLoadClick}
        >
          Load User
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

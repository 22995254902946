// @flow

import BroadcastMessagesView from "./BroadcastMessagesView.react";

import { React, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

type Props = $ReadOnly<{
  sessionId: string,
  backgroundColor: string,
  backgroundOpacity: number,
  borderOpacity: number,
  font: string,
  fontSize: string,
  fontColor: string,
  lineHeight: string,
  textBackgroundColor: string,
  textBackgroundOpacity: number,
  textShadowWidth: number,
  textShadowBlur: number,
  minSeconds: number,
  maxSeconds: number,
  minSecondsTextLengthThreshold: number,
  maxSecondsTextLengthThreshold: number,
  screenWidth: number,
  screenHeight: number,
  subAlign: string,
  marginBottom: number,
  isPreview: boolean,
  lineNumber: number,
  dualSessionId: string,
  isNoWrap: boolean,
  isDemoText: boolean,
  multiSessionIdList: array,
}>;

export default function BroadcastScreenViewDual(
  props: Props
): React$Element<any> {
  const [backgroundColor, setBackgroundColor] = useState("");
  const [borderOpacity, setBorderOpacity] = useState(0);
  const [marginBottom, setMarginBottom] = useState(0);
  const [multiSessionIdList, setMultiSessionIdList] = useState([]);

  const width = props.isPreview ? "960px" : "100vw";
  const height = props.isPreview ? "540px" : "100vh";

  useEffect(() => {
    function calculateBackgroundColor() {
      if (props.backgroundOpacity < 7) {
        return (
          props.backgroundColor +
          "0" +
          Math.floor((props.backgroundOpacity * 255) / 100).toString(16)
        );
      }
      return (
        props.backgroundColor +
        Math.floor((props.backgroundOpacity * 255) / 100).toString(16)
      );
    }
    setBackgroundColor(calculateBackgroundColor());
  }, [props.backgroundColor, props.backgroundOpacity]);

  useEffect(() => {
    setBorderOpacity(props.borderOpacity / 100);
  }, [props.borderOpacity]);

  useEffect(() => {
    setMultiSessionIdList(props.multiSessionIdList);
  }, [props.multiSessionIdList]);

  // Calculate Margin Bottom Value
  useEffect(() => {
    const marginBottomValue = props.marginBottom
      ? props.marginBottom.toString()
      : "0";
    setMarginBottom(marginBottomValue);
  }, [props.marginBottom]);

  return (
    <div
      style={{
        // width: props.screenWidth + "px",
        // height: props.screenHeight + "px",
        // width: "100vw",
        // height: "100vh",
        overflow: "hidden",
        width: width,
        height: height,
        backgroundColor: backgroundColor,
        fontFamily: props.font,
        fontSize: props.fontSize + "px",
        lineHeight: props.lineHeight,
        border: `2px solid rgba(1, 1, 1, ${borderOpacity})`,
      }}
      className="d-flex align-items-end"
    >
      <Container fluid style={{ marginBottom: `${marginBottom}px` }}>
        {multiSessionIdList &&
          multiSessionIdList.map((id, index) => {
            return (
              <Row key={index}>
                <BroadcastMessagesView
                  // sessionId={props.sessionId}
                  sessionId={id}
                  textBackgroundColor={props.textBackgroundColor}
                  textBackgroundOpacity={props.textBackgroundOpacity}
                  textShadowWidth={props.textShadowWidth}
                  textShadowBlur={props.textShadowBlur}
                  fontSize={props.fontSize}
                  fontColor={props.fontColor}
                  lineHeight={props.lineHeight}
                  minSeconds={props.minSeconds}
                  maxSeconds={props.maxSeconds}
                  minSecondsTextLengthThreshold={
                    props.minSecondsTextLengthThreshold
                  }
                  maxSecondsTextLengthThreshold={
                    props.maxSecondsTextLengthThreshold
                  }
                  marginBottom={props.marginBottom}
                  subAlign={props.subAlign}
                  lineNumber={props.lineNumber}
                  isNoWrap={props.isNoWrap}
                  isDemoText={props.isDemoText}
                />
              </Row>
            );
          })}
      </Container>
    </div>
  );
}

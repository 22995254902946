// @flow

import { getSession, getBroadcastSetting } from "./../graphql/queries";
import { API } from "aws-amplify";
import React, { useState, useEffect } from "react";
import MessagesView from "./MessagesView.react";
import {
  Container,
  Row,
  Col,
  ToggleButton,
  ButtonGroup,
} from "react-bootstrap";
import NavigationBar from "./NavigationBar.react";

import Card from "react-bootstrap/Card";
import ChatInputView from "./ChatInputView.react";
import SessionUsersView from "./SessionUsersView.react";
import ShortcutsView from "./ShortcutsView.react";
import SheetsView from "./SheetsView.react";
import AutoTranslateModule from "./AutoTranslateModule.react";

type Props = $ReadOnly<{
  sessionId: ?string,
}>;

export default function TranslatorView(props: Props): React$Element<any> {
  const [sessionName, setSessionName] = useState("");
  const [broadcastSetting, setBroadcastSetting] = useState([]);

  const [showSheets, setShowSheets] = useState(false);
  const [showShortcuts, setShowShortcuts] = useState(false);
  const [sheetsChecked, setSheetsChecked] = useState(false);
  const [shortcutsChecked, setShortcutsChecked] = useState(false);

  const [shortcutInput, setShortcutInput] = useState("");
  const [shortcutInputIndex, setShortcutInputIndex] = useState(0);

  const [sourceWord, setSourceWord] = useState("");
  const [translatedWord, setTranslatedWord] = useState("");

  const [selectedUser, setSelectedUser] = useState("");

  let inputWidth;
  if (!showSheets && !showShortcuts) {
    inputWidth = 12;
  } else if (showSheets && !showShortcuts) {
    inputWidth = 5;
  } else if (!showSheets && showShortcuts) {
    inputWidth = 10;
  } else if (showSheets && showShortcuts) {
    inputWidth = 4;
  }

  let sheetsWidth;
  if (showShortcuts) {
    sheetsWidth = 7;
  } else {
    sheetsWidth = 7;
  }

  let shortcutsWidth;
  if (showSheets) {
    shortcutsWidth = 1;
  } else {
    shortcutsWidth = 2;
  }

  useEffect(() => {
    fetchSession();
  });

  useEffect(() => {
    fetchBroadcastSetting();
  }, []);

  async function fetchSession() {
    const apiData = await API.graphql({
      query: getSession,
      variables: {
        id: props.sessionId,
      },
    });
    setSessionName(apiData.data.getSession.name);
  }

  async function fetchBroadcastSetting() {
    const apiData = await API.graphql({
      query: getBroadcastSetting,
      variables: {
        id: props.sessionId,
      },
    });
    setBroadcastSetting(apiData.data.getBroadcastSetting);
  }

  function handleShowSheets(e) {
    setSheetsChecked(e.currentTarget.checked);
    setShowSheets((prevShowSheets) => !prevShowSheets);
  }

  function handleShowShortcuts(e) {
    setShortcutsChecked(e.currentTarget.checked);
    setShowShortcuts((prevShowShortcuts) => !prevShowShortcuts);
  }

  if (props.sessionId == null) {
    return <div>Invalid Session</div>;
  }
  return (
    <>
      <NavigationBar />
      <Container fluid>
        <Row>
          <Col className="p-0">
            <Card className="m-2 mb-0" bg="secondary" text="white">
              <Card.Body className="p-2 m-0">
                <Card.Text className="ms-1" as="h5">
                  {sessionName}
                  <ButtonGroup className="float-end">
                    <ToggleButton
                      id="toggle-setCheck"
                      type="checkbox"
                      size="sm"
                      style={{ fontSize: "0.9rem" }}
                      variant="outline-warning"
                      checked={sheetsChecked}
                      value="1"
                      onChange={handleShowSheets}
                    >
                      Script
                    </ToggleButton>
                    <ToggleButton
                      id="toggle-setCheckShortcuts"
                      type="checkbox"
                      size="sm"
                      style={{ fontSize: "0.9rem" }}
                      variant="outline-warning"
                      checked={shortcutsChecked}
                      value="1"
                      onChange={handleShowShortcuts}
                    >
                      Shortcuts
                    </ToggleButton>
                  </ButtonGroup>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="p-0">
          {showSheets ? (
            <Col xs={sheetsWidth} className="p-2">
              <SheetsView
                sessionId={props.sessionId}
                selectedUser={selectedUser}
              />
            </Col>
          ) : null}
          <Col xs={inputWidth} className="p-2">
            <MessagesView
              sessionId={props.sessionId}
              title="History"
            ></MessagesView>
            <br />
            <SessionUsersView
              sessionId={props.sessionId}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
            />
            <br />
            <ChatInputView
              sessionId={props.sessionId}
              shortcutInput={shortcutInput}
              shortcutInputIndex={shortcutInputIndex}
              setSourceWord={setSourceWord}
              translatedWord={translatedWord}
            />
            {/* <AutoTranslateModule
              sessionId={props.sessionId}
              sourceWord={sourceWord}
              setTranslatedWord={setTranslatedWord}
              title="Auto Translate"
            /> */}
          </Col>
          {showShortcuts ? (
            <Col xs={shortcutsWidth} className="p-2">
              <ShortcutsView
                sessionId={props.sessionId}
                setShortcutInput={setShortcutInput}
                setShortcutInputIndex={setShortcutInputIndex}
              />
            </Col>
          ) : null}
        </Row>
      </Container>
    </>
  );
}

// @flow

import UserContext from "../utils/UserContext";
import { API } from "aws-amplify";

import React from "react";
import { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import {
  queryScriptSheetsBySessionSheetsIdIndex,
  queryBookmarksBySessionIdRowIndex,
  getSession,
} from "./../graphql/queries";

import {
  createScriptSheets as createScriptSheetsMutation,
  updateSession,
  deleteScriptSheets as deleteScriptSheetsMutation,
  updateSession as updateSessionMutation,
  deleteBookmarks as deleteBookmarksMutation,
} from "./../graphql/mutations";

import { onUpdateSession } from "../graphql/subscriptions";

import "./../index.css";

type Props = $ReadOnly<{
  sessionId: string,
  show: boolean,
  onHide: (boolean) => void,
  setSheets: React.Dispatch<React.SetStateAction<any>>,
  sheets: array,
}>;

async function fetchScriptSheets(sessionId) {
  try {
    const apiData = await API.graphql({
      query: queryScriptSheetsBySessionSheetsIdIndex,
      variables: {
        sessionSheetsId: sessionId,
      },
    });
    return apiData.data.queryScriptSheetsBySessionSheetsIdIndex.items;
  } catch (e) {
    console.log(e);
  }
}

async function deleteScriptSheets(id: string) {
  const ret = await API.graphql({
    query: deleteScriptSheetsMutation,
    variables: {
      input: {
        id: id,
      },
    },
  });
}

async function deleteBookmark(id: string, row: number) {
  const ret = await API.graphql({
    query: deleteBookmarksMutation,
    variables: {
      input: {
        id: id,
        row: row,
      },
    },
  });
}

function colorCodeToRGB(colorCode) {
  if (colorCode) {
    if (!colorCode.startsWith("#")) {
      throw new Error("Invalid color code format");
    }

    const hex = colorCode.slice(1); // Remove the '#' symbol

    if (hex.length !== 6) {
      throw new Error("Invalid color code length");
    }

    const red = (parseInt(hex.slice(0, 2), 16) / 255).toFixed(3);
    const green = (parseInt(hex.slice(2, 4), 16) / 255).toFixed(3);
    const blue = (parseInt(hex.slice(4, 6), 16) / 255).toFixed(3);

    return { red: red, green: green, blue: blue };
  }
}

export default function SheetsLoadView(props: Props): React$Element<any> {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [sheetsUrl, setSheetsUrl] = useState("");
  const [sheetName, setSheetName] = useState("");
  const [sheetNameArray, setSheetNameArray] = useState([]);
  const [spreadsheetsName, setSpreadsheetsName] = useState(
    "Select a spreadsheet"
  );
  const [spreadsheetsId, setSpreadsheetsId] = useState("");
  const [spreadsheetsArray, setSpreadsheetsArray] = useState([]);
  const [updateCount, setUpdateCount] = useState(0);
  const [hasOauthInfo, setHasOauthInfo] = useState(false);
  const [oauthSheetNameArray, setOauthSheetNameArray] = useState([]);
  const [oauthSheetName, setOauthSheetName] = useState("");
  const [deleteBookmarksChecked, setDeleteBookmarksChecked] = useState(true);

  const userContext = useContext(UserContext);
  const accessToken = localStorage.getItem("accessToken");

  // SheetsUrl, updateCount initial fetch
  useEffect(() => {
    async function initialFetch() {
      const fetchedSession = await fetchSheetsSession(props.sessionId);
      setSheetsUrl(fetchedSession.sheetsUrl);
      setUpdateCount(fetchedSession.sheetsUpdateCount);
    }
    initialFetch();
  }, [props.sessionId]);

  // Spreadsheets list initial fetch for OAuth users
  useEffect(() => {
    if (accessToken) {
      handleGetList();
    }
  }, []);

  // Sheet name auto fetch for Url
  useEffect(() => {
    if (sheetsUrl && sheetsUrl !== "") {
      fetchSheetNames(sheetsUrl.split("/")[5]);
    }
  }, [sheetsUrl]);

  // Spreadsheets names auto fetch
  useEffect(() => {
    if (spreadsheetsId && spreadsheetsId !== "") {
      oauthFetchSheetNames(spreadsheetsId);
    }
  }, [spreadsheetsId]);

  // Subscribe to sheets url / updateCount update
  useEffect(() => {
    const subscription = API.graphql({
      query: onUpdateSession,
      variables: {
        id: props.sessionId,
      },
    }).subscribe({
      next: () => {
        async function updateFetch() {
          const fetchedSession = await fetchSheetsSession();
          setSheetsUrl(fetchedSession.sheetsUrl);
          setUpdateCount(fetchedSession.sheetsUpdateCount);
        }
        updateFetch();
      },
      error: (error) => console.warn(error),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // Subscribe to session sheets update count
  useEffect(() => {
    const subscription = API.graphql({
      query: onUpdateSession,
      variables: {
        id: props.sessionId,
      },
    }).subscribe({
      next: () => {
        async function updateFetch() {
          const fetchedSheets = await fetchScriptSheets(props.sessionId);
          props.setSheets(fetchedSheets);
        }
        updateFetch();
      },
      error: (error) => console.warn(error),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // Check if the user has OAuth info
  useEffect(() => {
    checkOauthInfo();
  }, []);

  async function fetchSheetsSession() {
    try {
      const apiData = await API.graphql({
        query: getSession,
        variables: {
          id: props.sessionId,
        },
      });
      return apiData.data.getSession;
    } catch (e) {
      console.log(e);
    }
  }

  async function updateSessionSheetsUrl(url: string) {
    if (props.sessionId === "" || userContext.userId === "") {
      return;
    }
    const ret = await API.graphql({
      query: updateSessionMutation,
      variables: {
        input: {
          id: props.sessionId,
          sheetsUrl: url,
        },
      },
    });
  }

  async function updateSessionSheetsUpdateCount() {
    if (props.sessionId === "" || userContext.userId === "") {
      return;
    }
    const ret = await API.graphql({
      query: updateSessionMutation,
      variables: {
        input: {
          id: props.sessionId,
          sheetsUpdateCount: 0,
        },
      },
    });
  }

  async function createScriptSheets(
    sessionId: string,
    name: string,
    script: string,
    translation: string,
    index: number,
    backgroundColor: string,
    textColor: string
  ) {
    const ret = await API.graphql({
      query: createScriptSheetsMutation,
      variables: {
        input: {
          sessionSheetsId: sessionId,
          name: name,
          script: script,
          translation: translation,
          isSelected: false,
          isEditable: false,
          index: index,
          backgroundColor: backgroundColor,
          textColor: textColor,
        },
      },
    });
  }

  async function deleteAllScriptSheets(id: string) {
    const apiData = await API.graphql({
      query: queryScriptSheetsBySessionSheetsIdIndex,
      variables: {
        sessionSheetsId: id,
      },
    });
    apiData.data.queryScriptSheetsBySessionSheetsIdIndex.items.forEach((line) =>
      deleteScriptSheets(line.id)
    );
  }

  async function deleteAllBookmarks() {
    try {
      const apiData = await API.graphql({
        query: queryBookmarksBySessionIdRowIndex,
        variables: {
          sessionId: props.sessionId,
        },
      });
      const bookmarks = apiData.data.queryBookmarksBySessionIdRowIndex.items;
      bookmarks.forEach((bookmark) => {
        deleteBookmark(bookmark.id, bookmark.row);
      });
    } catch (e) {
      console.log(e);
    }
  }

  const componentToHex = (c) => {
    const int = Math.round(c * 255);
    const hex = int.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  };

  const rgbToHex = (r, g, b) => {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  };

  const checkOauthInfo = () => {
    const info = JSON.parse(localStorage.getItem("authInfo"));
    if (info && info.refresh_token) {
      setHasOauthInfo(true);
      // setAccessToken(localStorage.getItem("accessToken"));
    } else {
      setHasOauthInfo(false);
    }
  };

  async function getDriveFiles() {
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const itemsCount = 20;
    const apiUrl = `https://www.googleapis.com/drive/v3/files?key=${apiKey}&q=mimeType%3D'application%2Fvnd.google-apps.spreadsheet'&pageSize=${itemsCount}&trashed=false&orderBy=viewedByMeTime desc`;

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  const handleGetList = () => {
    getDriveFiles()
      .then((res) => {
        const list = res.files;
        // console.log("List of files:", list);
        setSpreadsheetsArray(list);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // Fetch every sheet's name
  const fetchSheetNames = (sheetsId) => {
    // const sheetsId = sheetsUrl.split("/")[5];
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetsId}?includeGridData=false&key=${apiKey}`;

    fetch(url)
      .then((res) => res.text())
      .then((rep) => {
        const jsonData = JSON.parse(rep).sheets;
        // const jsonData = JSON.parse(rep);

        const newArray = [];

        for (let idx = 0; idx < jsonData.length; idx++) {
          const title = jsonData[idx].properties.title;
          newArray.push(title);
          if (idx === 0) {
            setSheetName(title);
          }
        }
        setSheetNameArray(newArray);
      });
  };

  // Fetch every sheet's name
  const oauthFetchSheetNames = (sheetsId) => {
    // const sheetsId = sheetsUrl.split("/")[5];
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetsId}?includeGridData=false&key=${apiKey}`;

    fetch(url)
      .then((res) => res.text())
      .then((rep) => {
        const jsonData = JSON.parse(rep).sheets;
        // const jsonData = JSON.parse(rep);

        const newArray = [];

        for (let idx = 0; idx < jsonData.length; idx++) {
          const title = jsonData[idx].properties.title;
          newArray.push(title);
          if (idx === 0) {
            setOauthSheetName(title);
          }
        }
        setOauthSheetNameArray(newArray);
      });
  };

  //Import google sheets function
  const fetchGoogleSheets = (sheetsId, sheetName) => {
    // const sheetsId = sheetsUrl.split("/")[5];
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const range = `'${sheetName}'!A:C`;
    const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetsId}?includeGridData=true&ranges=${range}&key=${apiKey}`;

    deleteAllScriptSheets(props.sessionId);
    deleteBookmarksChecked && deleteAllBookmarks();
    fetch(url)
      .then((res) => res.text())
      .then((rep) => {
        // Remove additional text and extract only JSON:
        const newArray = [];
        const jsonData = JSON.parse(rep).sheets[0].data[0].rowData;
        const hiddenData = JSON.parse(rep).sheets[0].data[0].rowMetadata;

        for (let idx = 0; idx < jsonData.length; idx++) {
          const row = jsonData[idx];
          if (
            !hiddenData[idx].hasOwnProperty("hiddenByUser")
            // Conditions to filter out empty rows
            // &&
            // (row.values[0].hasOwnProperty("formattedValue") ||
            //   row.values[1].hasOwnProperty("formattedValue"))
          ) {
            newArray.push(row);
          }
          // console.log(row);
        }
        // console.log(newArray);

        for (let idx = 0; idx < newArray.length; idx++) {
          if (newArray[idx].hasOwnProperty("values")) {
            const rowData = newArray[idx].values;
            // console.log(rowData);

            let nameData;
            if (
              rowData.length === 0 ||
              !rowData[0].hasOwnProperty("formattedValue")
            ) {
              nameData = "";
            } else {
              nameData = rowData[0].formattedValue;
            }

            let scriptData;
            if (
              rowData.length === 0 ||
              rowData.length === 1 ||
              !rowData[1].hasOwnProperty("formattedValue")
            ) {
              scriptData = "";
            } else {
              scriptData = rowData[1].formattedValue;
            }

            let translationData;
            if (
              rowData.length === 0 ||
              rowData.length === 1 ||
              rowData.length === 2 ||
              !rowData[2].hasOwnProperty("formattedValue")
            ) {
              translationData = "";
            } else {
              translationData = rowData[2].formattedValue;
            }

            let backgroundColorObj;
            if (
              rowData.length === 0 ||
              rowData.length === 1 ||
              !rowData[1].hasOwnProperty("effectiveFormat")
            ) {
              backgroundColorObj = { red: 1, green: 1, blue: 1 };
            } else {
              backgroundColorObj = rowData[1].effectiveFormat.backgroundColor;
            }

            let textColorObj;
            if (
              rowData.length === 0 ||
              rowData.length === 1 ||
              !rowData[1].hasOwnProperty("effectiveFormat")
            ) {
              textColorObj = {};
            } else {
              textColorObj =
                rowData[1].effectiveFormat.textFormat.foregroundColor;
            }

            const backgroundColorHex = rgbToHex(
              backgroundColorObj.red ? backgroundColorObj.red : 0,
              backgroundColorObj.green ? backgroundColorObj.green : 0,
              backgroundColorObj.blue ? backgroundColorObj.blue : 0
            );

            const textColorHex = rgbToHex(
              textColorObj.red ? textColorObj.red : 0,
              textColorObj.green ? textColorObj.green : 0,
              textColorObj.blue ? textColorObj.blue : 0
            );

            let indexNum = idx + 1;
            createScriptSheets(
              props.sessionId,
              nameData,
              scriptData,
              translationData,
              indexNum,
              backgroundColorHex,
              textColorHex
            );
          } else {
            const nameData = "";
            const scriptData = "";
            const translationData = "";
            let indexNum = idx + 1;
            createScriptSheets(
              props.sessionId,
              nameData,
              scriptData,
              translationData,
              indexNum,
              "#FFFFFF",
              "#000000"
            );
          }
        }
        // console.log(JSON.parse(rep));
      });
  };

  // function createNewSheet() {
  //   // const accessToken = accessToken; // Replace with your actual access token
  //   // const spreadsheetId = spreadsheetsId; // Replace with your actual spreadsheet ID

  //   const url = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetsId}:batchUpdate`;

  //   const requestData = {
  //     requests: [
  //       {
  //         addSheet: {
  //           properties: {
  //             title: `Comet_${sheetName}`,
  //           },
  //         },
  //       },
  //     ],
  //   };

  //   fetch(url, {
  //     method: "POST",
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`,
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(requestData),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // console.log(
  //       //   "New sheet created:",
  //       //   data.replies[0].addSheet.properties.title
  //       // );
  //       // console.log(data.replies[0].addSheet.properties.sheetId);
  //       return data.replies[0].addSheet.properties.sheetId;
  //     })
  //     .catch((error) => {
  //       console.error("Error creating sheet:", error);
  //       window.alert(
  //         "An error occurred. Please check if the sheet name is already in use."
  //       );
  //     });
  // }

  async function createNewSheet() {
    const url = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetsId}:batchUpdate`;

    const requestData = {
      requests: [
        {
          addSheet: {
            properties: {
              title: `Comet_${oauthSheetName}`,
            },
          },
        },
      ],
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      const data = await response.json();
      return data.replies[0].addSheet.properties.sheetId;
    } catch (error) {
      console.error("Error creating sheet:", error);
      window.alert(
        "An error occurred. Please check if the sheet name is already in use."
      );
      throw error; // Rethrow the error to handle it outside this function if needed
    }
  }

  function batchUpdateValues(values) {
    const range = `Comet_${oauthSheetName}!A:C`;

    const data = [];
    data.push({
      range: range,
      majorDimension: "ROWS",
      values: values,
    });

    const valueInputOption = "RAW";

    const body = {
      data: data,
      valueInputOption: valueInputOption,
    };

    const url = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetsId}/values:batchUpdate`;

    fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(`${data.totalUpdatedCells} cells updated.`);
        console.log(data);
        // if (callback) callback(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const batchUpdateStyle = (sheetId, rowCount, styles) => {
    // console.log(sheetId);

    const range = {
      sheetId: sheetId,
      startRowIndex: 0,
      endRowIndex: rowCount,
      startColumnIndex: 0,
      endColumnIndex: 3,
    };

    const url = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetsId}:batchUpdate`;

    const requestData = {
      requests: [
        {
          updateCells: {
            range: range,
            fields:
              "userEnteredFormat.textFormat,userEnteredFormat.backgroundColor",
            // rows: [
            //   {
            //     values: [
            //       {
            //         userEnteredFormat: {
            //           textFormat: {
            //             foregroundColor: { red: 1.0, green: 0.0, blue: 0.0 },
            //           },
            //           backgroundColor: { red: 0.0, green: 1.0, blue: 0.0 },
            //         },
            //         formattedValue: "Cell Value",
            //       },
            //       {
            //         userEnteredFormat: {
            //           textFormat: {
            //             foregroundColor: { red: 0.0, green: 0.0, blue: 1.0 },
            //           },
            //           backgroundColor: { red: 1.0, green: 1.0, blue: 0.0 },
            //         },
            //         formattedValue: "Another Value",
            //       },
            //     ],
            //   },
            // ],
            rows: styles,
          },
        },
      ],
    };

    fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Batch update successful:", data);
      })
      .catch((error) => {
        console.error("Error in batch update:", error);
      });
  };

  const overwriteGoogleSheets = () => {
    async function updateFetch() {
      const newSheetId = await createNewSheet();
      const fetchedSheets = await fetchScriptSheets(props.sessionId);
      // console.log(fetchedSheets.length);

      let values = [];
      let styles = [];
      let rowCount = fetchedSheets.length;

      fetchedSheets.forEach((row) =>
        values.push([row.name, row.script, row.translation])
      );

      fetchedSheets.forEach(
        (row) =>
          styles.push({
            values: [
              {
                userEnteredFormat: {
                  textFormat: {
                    foregroundColor: colorCodeToRGB(row.textColor),
                  },
                  backgroundColor: colorCodeToRGB(row.backgroundColor),
                },
              },
              {
                userEnteredFormat: {
                  textFormat: {
                    foregroundColor: colorCodeToRGB(row.textColor),
                  },
                  backgroundColor: colorCodeToRGB(row.backgroundColor),
                },
              },
              {
                userEnteredFormat: {
                  textFormat: {
                    foregroundColor: colorCodeToRGB(row.textColor),
                  },
                  backgroundColor: colorCodeToRGB(row.backgroundColor),
                },
              },
            ],
          })
        // console.log(colorCodeToRGB(row.textColor))
        // console.log(colorCodeToRGB(row.backgroundColor))
      );

      batchUpdateValues(values);
      batchUpdateStyle(newSheetId, rowCount, styles);
    }
    updateFetch();
  };

  const handleFetchClick = () => {
    if (hasOauthInfo) {
      fetchGoogleSheets(spreadsheetsId, oauthSheetName);
    } else {
      fetchGoogleSheets(sheetsUrl.split("/")[5], sheetName);
    }
    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
    setTimeout(updateSessionSheetsUpdateCount, 3000);
  };

  const handleOverwriteClick = () => {
    overwriteGoogleSheets();
    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
    setTimeout(updateSessionSheetsUpdateCount, 3000);
  };

  const confirmModifiedFetch = () => {
    if (updateCount === 1) {
      const confirmFetch = () => {
        if (
          window.confirm(
            `Wait! There was a change in the sheet after the previous load.` +
              `\n` +
              `Are you sure to load Google Sheets?`
          )
        ) {
          handleFetchClick();
        } else {
          return;
        }
      };
      confirmFetch();
    } else if (updateCount === 0) {
      handleFetchClick();
    }
  };

  const confirmOverwrite = () => {
    if (window.confirm(`Copy session sheets to Google Sheets?`)) {
      handleOverwriteClick();
      // console.log("Overwrite");
    } else {
      return;
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={() => props.onHide(false)}
      dialogClassName="modal-60w"
    >
      <Modal.Header closeButton>
        <Modal.Title>Load Google Sheets</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="p-0 m-0">
          {hasOauthInfo ? (
            <Row className="mt-0">
              <Row className="mb-3">
                <Col xs={8}>
                  <h5>Choose From Your Spreadsheets :</h5>
                </Col>
                <DropdownButton
                  id="dropdown-item-button"
                  title={<span>{spreadsheetsName}</span>}
                  variant="light"
                >
                  {spreadsheetsArray.map((value, index) => (
                    <Dropdown.Item
                      as="button"
                      // style={{ overflow: "scroll" }}
                      key={index}
                      onClick={() => {
                        setSpreadsheetsId(value.id);
                        setSpreadsheetsName(value.name);
                        oauthFetchSheetNames(value.id);
                      }}
                    >
                      <span>{value.name}</span>
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Row>
              <Row>
                <Col xs={8}>
                  <h6>Sheet Name :</h6>
                </Col>
                <DropdownButton
                  id="dropdown-item-button"
                  title={<span>{oauthSheetName}</span>}
                  variant="light"
                >
                  {oauthSheetNameArray.map((value, index) => (
                    <Dropdown.Item
                      as="button"
                      // style={{ overflow: "scroll" }}
                      key={index}
                      onClick={() => {
                        setOauthSheetName(value);
                      }}
                    >
                      <span>{value}</span>
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Row>
            </Row>
          ) : (
            <Container>
              <Row>
                <Col style={{}}>
                  <h5>Paste Google Sheets URL Here :</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Google Sheets URL"
                    // disabled
                    onChange={(event) => {
                      updateSessionSheetsUrl(event.target.value);
                    }}
                    defaultValue={sheetsUrl}
                    // value={sheetsUrl}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs={8}>
                  <h6>Sheet Name :</h6>
                </Col>
                <DropdownButton
                  id="dropdown-item-button"
                  title={<span>{sheetName}</span>}
                  variant="light"
                >
                  {sheetNameArray.map((value, index) => (
                    <Dropdown.Item
                      as="button"
                      // style={{ overflow: "scroll" }}
                      key={index}
                      onClick={() => {
                        setSheetName(value);
                      }}
                    >
                      <span>{value}</span>
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Row>
              <br />
              <Row>
                <span style={{ color: "#5e75ff", fontStyle: "italic" }}>
                  * Set Google Sheet's share option to{" "}
                  <b> 'Anyone with the link'</b>
                </span>
              </Row>
            </Container>
          )}

          <Row
            style={{
              color: "#5e75ff",
              fontStyle: "italic",
            }}
            className="mt-4 ms-0"
          >
            <Col className="mb-2" style={{ fontWeight: "bold" }}>
              * Please Keep The Format !!
            </Col>
            <Row>
              <Col>- Column A : Name</Col>
            </Row>
            <Row>
              <Col>- Column B : Script</Col>
            </Row>
            <Row>
              <Col>- Column C : Translation</Col>
            </Row>
          </Row>
          <Row
            style={{
              color: "#5e75ff",
              fontStyle: "italic",
            }}
            className="mt-3 ms-0"
          >
            <Col className="mb-2">
              * If the sheets doesn't show after load, please refresh the page
            </Col>
          </Row>
          <Row>
            <Col>
              <Form>
                <Form.Check
                  label="Delete previous bookmarks"
                  checked={deleteBookmarksChecked}
                  onChange={() => {
                    setDeleteBookmarksChecked((prev) => !prev);
                  }}
                />
              </Form>
            </Col>
          </Row>
          <Row>
            <Col>
              {hasOauthInfo ? (
                <Button
                  // hidden
                  className="float-end"
                  variant="danger"
                  size="sm"
                  // disabled={isButtonDisabled}
                  onClick={confirmOverwrite}
                  // disabled
                  disabled={
                    isButtonDisabled ||
                    spreadsheetsId === "" ||
                    oauthSheetName === ""
                  }
                >
                  {/* {isButtonDisabled ? "Writing..." : "Write"} */}
                  Write
                </Button>
              ) : null}
              <Button
                className="float-end me-2"
                variant="primary"
                size="sm"
                onClick={confirmModifiedFetch}
                disabled={
                  hasOauthInfo
                    ? isButtonDisabled ||
                      spreadsheetsId === "" ||
                      oauthSheetName === ""
                    : isButtonDisabled || sheetsUrl === "" || sheetName === ""
                }
              >
                {/* {isButtonDisabled ? "Loading..." : "Load"} */}
                Load
              </Button>
              {/* <Button
                className="float-end me-2"
                size="sm"
                variant="dark"
                onClick={batchUpdateStyle}
              >
                Test Button
              </Button> */}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

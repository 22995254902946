// @flow

import { API } from "aws-amplify";
import React, { useState, useEffect, useRef } from "react";
import { onCreateMessageBySession } from "./../graphql/subscriptions";
import { messagesBySession } from "./../graphql/queries";
import { Container, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";

type Props = $ReadOnly<{
  sessionId: string,
  title: ?string,
}>;

const MAX_MESSAGES_COUNT_IN_HISTORY = 8;

const styles = {
  index: {
    display: "inline",
    float: "left",
    width: "7%",
    // marginLeft: "0px",
    // paddingLeft: "0px"
  },
  message: {
    display: "inline",
    float: "left",
    paddingLeft: "10px",
    width: "93%"
  },
  empty: {
    display: "inline",
    float: "left",
    width: "7%"
  }
}

// Only fetches the latest 20 messages
async function fetchMessages(sessionId) {
  const apiData = await API.graphql({
    query: messagesBySession,
    variables: {
      sessionMessagesId: sessionId,
      sortDirection: "DESC",
      limit: MAX_MESSAGES_COUNT_IN_HISTORY,
    },
  });
  return apiData.data?.messagesBySession?.items.reverse() ?? [];
}

export default function MessagesView(props: Props): React$Element<any> {
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    async function initialFetch() {
      const messages = await fetchMessages(props.sessionId);
      setMessages(messages);
    }
    initialFetch();
  }, [props.sessionId]);

  useEffect(() => {
    const subscription = API.graphql({
      query: onCreateMessageBySession,
      variables: {
        sessionMessagesId: props.sessionId,
      },
    }).subscribe({
      next: ({ provider, value }) => {
        if (value.data.onCreateMessageBySession.text === "") {
          return;
        } else if (value.data.onCreateMessageBySession.text === " ") {
          return;
        } else {
          setMessages((prevMessages) => [
            ...prevMessages.slice((MAX_MESSAGES_COUNT_IN_HISTORY - 1) * -1),
            value.data.onCreateMessageBySession,
          ]);
        }

      },
      error: (error) => console.warn(error),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <Card>
      {props.title != null ? <Card.Header as="h5">History</Card.Header> : null}
      <Card.Body className="p-1">
        <Container
          className="m-0"
          style={{
            height: "23vh",
            // maxHeight: "30vh",
            overflow: "hidden",
          }}
        >
          <Row>
            <Col className="p-0">
              {messages != null && messages.length > 0
                ? messages.map((message, index) => (
                  <Row className="p-0" key={message.id}>
                    <Col>
                      {index > messages.length - 2 ? (
                        // <b>
                        //   <div style={styles.index}>{message.scriptIndex}</div>
                        //   <div style={styles.message}>{message.text}</div>
                        // </b>
                        <b>
                          {message.scriptIndex ?
                            <div style={styles.index}>{message.scriptIndex}</div> :
                            <div style={styles.empty}>--</div>}
                          <div style={styles.message}>{message.text}</div>
                        </b>
                      ) : (
                        <div>
                          {message.scriptIndex ?
                            <div style={styles.index}>{message.scriptIndex}</div> :
                            <div style={styles.empty}>--</div>}
                          <div style={styles.message}>{message.text}</div>
                        </div>
                      )}
                    </Col>
                  </Row>
                ))
                : null}
              <div ref={messagesEndRef} />
            </Col>
          </Row>
        </Container>
      </Card.Body >
    </Card >
  );
}

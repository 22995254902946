// @flow

import React from "react";
import Alert from "react-bootstrap/Alert";

export default class Logger {
  logs: Array<string> = [];

  log(message: string): void {
    this.logs.push(message);
  }

  getLogView(): React$Element<"div"> {
    return (
      <Alert variant="info">
        {this.logs.map((log) => (
          <div>{log}</div>
        ))}
      </Alert>
    );
  }
}

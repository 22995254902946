/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getArtist = /* GraphQL */ `
  query GetArtist($id: String!) {
    getArtist(id: $id) {
      chName
      enName
      id
      jaName
      krName
      team
      __typename
    }
  }
`;
export const getBroadcastSetting = /* GraphQL */ `
  query GetBroadcastSetting($id: ID!) {
    getBroadcastSetting(id: $id) {
      borderOpacity
      createdAt
      dualSessionId
      id
      isDemoText
      isNoWrap
      lineNumber
      marginBottom
      multiSessionIdList
      screenColor
      screenHeight
      screenOpacity
      screenWidth
      subAlign
      subBackgroundColor
      subBackgroundOpacity
      subColor
      subFont
      subLineHeight
      subMaxDisplaySeconds
      subMaxThresholdCharacters
      subMinDisplaySeconds
      subMinThresholdCharacters
      subSize
      textShadowBlur
      textShadowWidth
      updatedAt
      __typename
    }
  }
`;
export const getKeyBinding = /* GraphQL */ `
  query GetKeyBinding($id: ID!) {
    getKeyBinding(id: $id) {
      alt
      createdAt
      ctrl
      id
      key
      keyBindingSessionId
      shift
      text
      updatedAt
      userKeyBindingsId
      user
      session
      __typename
    }
  }
`;
export const getKeyBindingsBySession = /* GraphQL */ `
  query GetKeyBindingsBySession($keyBindingSessionId: String!) {
    getKeyBindingsBySession(keyBindingSessionId: $keyBindingSessionId) {
      items {
        alt
        createdAt
        ctrl
        id
        key
        keyBindingSessionId
        shift
        text
        updatedAt
        userKeyBindingsId
        user
        session
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      createdAt
      id
      scriptIndex
      sessionMessagesId
      text
      type
      updatedAt
      username
      __typename
    }
  }
`;
export const getSession = /* GraphQL */ `
  query GetSession($id: ID!) {
    getSession(id: $id) {
      broadcastSetting {
        borderOpacity
        createdAt
        dualSessionId
        id
        isDemoText
        isNoWrap
        lineNumber
        marginBottom
        multiSessionIdList
        screenColor
        screenHeight
        screenOpacity
        screenWidth
        subAlign
        subBackgroundColor
        subBackgroundOpacity
        subColor
        subFont
        subLineHeight
        subMaxDisplaySeconds
        subMaxThresholdCharacters
        subMinDisplaySeconds
        subMinThresholdCharacters
        subSize
        textShadowBlur
        textShadowWidth
        updatedAt
        __typename
      }
      createdAt
      id
      messages {
        items {
          createdAt
          id
          scriptIndex
          sessionMessagesId
          text
          type
          updatedAt
          username
          __typename
        }
        nextToken
        __typename
      }
      name
      sessionBroadcastSettingId
      sheetsUpdateCount
      sheetsUrl
      streamUrl
      type
      updatedAt
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      allowAllAccess
      createdAt
      id
      isSuperAdmin
      keyBindings {
        items {
          alt
          createdAt
          ctrl
          id
          key
          keyBindingSessionId
          shift
          text
          updatedAt
          userKeyBindingsId
          user
          session
          __typename
        }
        nextToken
        __typename
      }
      name
      updatedAt
      username
      isBroadcaster
      __typename
    }
  }
`;
export const getYoutubeSettings = /* GraphQL */ `
  query GetYoutubeSettings($id: String!) {
    getYoutubeSettings(id: $id) {
      addedDelay
      addedDelayOn
      id
      url
      __typename
    }
  }
`;
export const listArtists = /* GraphQL */ `
  query ListArtists(
    $filter: TableArtistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArtists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        chName
        enName
        id
        jaName
        krName
        team
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listBroadcastSettings = /* GraphQL */ `
  query ListBroadcastSettings(
    $filter: ModelBroadcastSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBroadcastSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        borderOpacity
        createdAt
        dualSessionId
        id
        isDemoText
        isNoWrap
        lineNumber
        marginBottom
        multiSessionIdList
        screenColor
        screenHeight
        screenOpacity
        screenWidth
        subAlign
        subBackgroundColor
        subBackgroundOpacity
        subColor
        subFont
        subLineHeight
        subMaxDisplaySeconds
        subMaxThresholdCharacters
        subMinDisplaySeconds
        subMinThresholdCharacters
        subSize
        textShadowBlur
        textShadowWidth
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listKeyBindings = /* GraphQL */ `
  query ListKeyBindings(
    $filter: ModelKeyBindingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKeyBindings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        alt
        createdAt
        ctrl
        id
        key
        keyBindingSessionId
        shift
        text
        updatedAt
        userKeyBindingsId
        user
        session
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        id
        scriptIndex
        sessionMessagesId
        text
        type
        updatedAt
        username
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listSessions = /* GraphQL */ `
  query ListSessions(
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        broadcastSetting {
          borderOpacity
          createdAt
          dualSessionId
          id
          isDemoText
          isNoWrap
          lineNumber
          marginBottom
          multiSessionIdList
          screenColor
          screenHeight
          screenOpacity
          screenWidth
          subAlign
          subBackgroundColor
          subBackgroundOpacity
          subColor
          subFont
          subLineHeight
          subMaxDisplaySeconds
          subMaxThresholdCharacters
          subMinDisplaySeconds
          subMinThresholdCharacters
          subSize
          textShadowBlur
          textShadowWidth
          updatedAt
          __typename
        }
        createdAt
        id
        messages {
          nextToken
          __typename
        }
        name
        sessionBroadcastSettingId
        sheetsUpdateCount
        sheetsUrl
        streamUrl
        type
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        allowAllAccess
        createdAt
        id
        isSuperAdmin
        keyBindings {
          nextToken
          __typename
        }
        name
        updatedAt
        username
        isBroadcaster
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listYoutubeSettings = /* GraphQL */ `
  query ListYoutubeSettings(
    $filter: TableYoutubeSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listYoutubeSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        addedDelay
        addedDelayOn
        id
        url
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messagesByDate = /* GraphQL */ `
  query MessagesByDate(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $type: Int!
  ) {
    messagesByDate(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      type: $type
    ) {
      items {
        createdAt
        id
        scriptIndex
        sessionMessagesId
        text
        type
        updatedAt
        username
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messagesBySession = /* GraphQL */ `
  query MessagesBySession(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
    $sessionMessagesId: String!
    $sortDirection: ModelSortDirection
  ) {
    messagesBySession(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sessionMessagesId: $sessionMessagesId
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        id
        scriptIndex
        sessionMessagesId
        text
        type
        updatedAt
        username
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const sessionsByDate = /* GraphQL */ `
  query SessionsByDate(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $type: Int!
  ) {
    sessionsByDate(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      type: $type
    ) {
      items {
        broadcastSetting {
          borderOpacity
          createdAt
          dualSessionId
          id
          isDemoText
          isNoWrap
          lineNumber
          marginBottom
          multiSessionIdList
          screenColor
          screenHeight
          screenOpacity
          screenWidth
          subAlign
          subBackgroundColor
          subBackgroundOpacity
          subColor
          subFont
          subLineHeight
          subMaxDisplaySeconds
          subMaxThresholdCharacters
          subMinDisplaySeconds
          subMinThresholdCharacters
          subSize
          textShadowBlur
          textShadowWidth
          updatedAt
          __typename
        }
        createdAt
        id
        messages {
          nextToken
          __typename
        }
        name
        sessionBroadcastSettingId
        sheetsUpdateCount
        sheetsUrl
        streamUrl
        type
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByUsername = /* GraphQL */ `
  query UserByUsername(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $username: String!
  ) {
    userByUsername(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      username: $username
    ) {
      items {
        allowAllAccess
        createdAt
        id
        isSuperAdmin
        keyBindings {
          nextToken
          __typename
        }
        name
        updatedAt
        username
        isBroadcaster
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserKeyCommand = /* GraphQL */ `
  query GetUserKeyCommand($id: String!) {
    getUserKeyCommand(id: $id) {
      id
      commandName
      userId
      triggerKey
      isEnabled
      __typename
    }
  }
`;
export const listUserKeyCommands = /* GraphQL */ `
  query ListUserKeyCommands(
    $filter: TableUserKeyCommandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserKeyCommands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        commandName
        userId
        triggerKey
        isEnabled
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryUserKeyCommandsByUserIdCommandNameIndex = /* GraphQL */ `
  query QueryUserKeyCommandsByUserIdCommandNameIndex(
    $userId: String!
    $first: Int
    $after: String
  ) {
    queryUserKeyCommandsByUserIdCommandNameIndex(
      userId: $userId
      first: $first
      after: $after
    ) {
      items {
        id
        commandName
        userId
        triggerKey
        isEnabled
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserMacro = /* GraphQL */ `
  query GetUserMacro($id: String!) {
    getUserMacro(id: $id) {
      id
      triggerWord
      userId
      insertWord
      isEnabled
      macroGroup
      macroName
      __typename
    }
  }
`;
export const listUserMacros = /* GraphQL */ `
  query ListUserMacros(
    $filter: TableUserMacroFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMacros(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        triggerWord
        userId
        insertWord
        isEnabled
        macroGroup
        macroName
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryUserMacrosByUserIdTriggerWordIndex = /* GraphQL */ `
  query QueryUserMacrosByUserIdTriggerWordIndex(
    $userId: String!
    $first: Int
    $after: String
  ) {
    queryUserMacrosByUserIdTriggerWordIndex(
      userId: $userId
      first: $first
      after: $after
    ) {
      items {
        id
        triggerWord
        userId
        insertWord
        isEnabled
        macroGroup
        macroName
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShortcutBinding = /* GraphQL */ `
  query GetShortcutBinding($id: String!) {
    getShortcutBinding(id: $id) {
      id
      index
      keyBindingSessionId
      userKeyBindingsId
      ctrl
      key
      text
      __typename
    }
  }
`;
export const listShortcutBindings = /* GraphQL */ `
  query ListShortcutBindings(
    $filter: TableShortcutBindingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShortcutBindings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        index
        keyBindingSessionId
        userKeyBindingsId
        ctrl
        key
        text
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryShortcutBindingsByUserKeyBindingsIdIndex = /* GraphQL */ `
  query QueryShortcutBindingsByUserKeyBindingsIdIndex(
    $userKeyBindingsId: String!
    $first: Int
    $after: String
  ) {
    queryShortcutBindingsByUserKeyBindingsIdIndex(
      userKeyBindingsId: $userKeyBindingsId
      first: $first
      after: $after
    ) {
      items {
        id
        index
        keyBindingSessionId
        userKeyBindingsId
        ctrl
        key
        text
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryShortcutBindingsByKeyBindingSessionIdIndex = /* GraphQL */ `
  query QueryShortcutBindingsByKeyBindingSessionIdIndex(
    $keyBindingSessionId: String!
    $first: Int
    $after: String
  ) {
    queryShortcutBindingsByKeyBindingSessionIdIndex(
      keyBindingSessionId: $keyBindingSessionId
      first: $first
      after: $after
    ) {
      items {
        id
        index
        keyBindingSessionId
        userKeyBindingsId
        ctrl
        key
        text
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getScriptSheets = /* GraphQL */ `
  query GetScriptSheets($id: String!) {
    getScriptSheets(id: $id) {
      id
      index
      sessionSheetsId
      name
      script
      translation
      backgroundColor
      textColor
      isEditable
      isSelected
      __typename
    }
  }
`;
export const listScriptSheets = /* GraphQL */ `
  query ListScriptSheets(
    $filter: TableScriptSheetsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScriptSheets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        index
        sessionSheetsId
        name
        script
        translation
        backgroundColor
        textColor
        isEditable
        isSelected
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryScriptSheetsBySessionSheetsIdIndex = /* GraphQL */ `
  query QueryScriptSheetsBySessionSheetsIdIndex(
    $sessionSheetsId: String!
    $first: Int
    $after: String
  ) {
    queryScriptSheetsBySessionSheetsIdIndex(
      sessionSheetsId: $sessionSheetsId
      first: $first
      after: $after
    ) {
      items {
        id
        index
        sessionSheetsId
        name
        script
        translation
        backgroundColor
        textColor
        isEditable
        isSelected
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBookmarks = /* GraphQL */ `
  query GetBookmarks($id: String!, $row: Int!) {
    getBookmarks(id: $id, row: $row) {
      id
      row
      sessionId
      color
      title
      __typename
    }
  }
`;
export const listBookmarks = /* GraphQL */ `
  query ListBookmarks(
    $filter: TableBookmarksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookmarks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        row
        sessionId
        color
        title
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryBookmarksBySessionIdRowIndex = /* GraphQL */ `
  query QueryBookmarksBySessionIdRowIndex(
    $sessionId: String!
    $first: Int
    $after: String
  ) {
    queryBookmarksBySessionIdRowIndex(
      sessionId: $sessionId
      first: $first
      after: $after
    ) {
      items {
        id
        row
        sessionId
        color
        title
        __typename
      }
      nextToken
      __typename
    }
  }
`;

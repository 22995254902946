// @flow

import { PubSub } from "aws-amplify";
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import { getSessionUsernameTopic } from "../utils/SessionUsers";
import { PUBLISH_LINE_BREAK } from "../utils/util";

type Props = $ReadOnly<{
  sessionId: string,
  username: string,
}>;

export default function ChatPartnerView(props: Props): React$Element<any> {
  const [message, setMessage] = useState("");
  const [previousMessage, setPreviousMessage] = useState("");
  const [subscription, setSubscription] = useState();
  const [triggerLinebreak, setTriggerLineBreak] = useState(false);

  useEffect(
    () => {
      if (props.username === "") {
        subscription && subscription.unsubscribe();
        setMessage("");
        return;
      }
      subscription && subscription.unsubscribe();
      const topic = getSessionUsernameTopic(props.sessionId, props.username);
      const sub = PubSub.subscribe(topic).subscribe({
        next: (data) => {
          if (data.value === PUBLISH_LINE_BREAK) {
            setTriggerLineBreak(true);
          } else {
            setMessage(data.value);
          }
        },
        error: (error) => console.error(error),
        complete: () => console.log("Done"),
      });
      setSubscription(sub);
      return () => {
        subscription && subscription.unsubscribe();
      };
    },
    // Do not include anything else in the dependency list
    [props.username, props.sessionId]
  );

  useEffect(() => {
    setMessage("");
    setPreviousMessage("");
  }, [props.sessionId, props.username]);

  useEffect(
    () => {
      // Handle line break
      if (triggerLinebreak) {
        setMessage((prev) => {
          setPreviousMessage(prev);
          return "";
        });
        setTriggerLineBreak(false);
      }
    },
    // Do not include anything else in the dependency list
    [triggerLinebreak]
  );
  return (
    <Container
      className="p-0 m-0"
    >
      <Row>
        <Col>
          {/* <span
            className="text-secondary"
            style={{
              display: "inline-block",
              whiteSpace: "nowrap",
              overflow: "hidden",
              width: "100%",
            }}
          >
            {previousMessage
              ? previousMessage
              : props.username !== ""
                ? "(empty)"
                : ""}
          </span>
          <br /> */}
          {message ? (
            <span
              style={{
                display: "inline-block",
                whiteSpace: "wrap",
                overflow: "auto",
                height: "100%",
                overflowY: "hidden",
              }}
            >
              {message}
            </span>
          ) : props.username !== "" ? (
            <span className="text-secondary">(waiting...)</span>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </Container>
  );
}

import UserContext from "../utils/UserContext";
import { API } from "aws-amplify";

import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import { getSession, getBroadcastSetting } from "./../graphql/queries";
import { updateBroadcastSetting as updateBroadcastSettingMutation } from "../graphql/mutations";
import { onUpdateBroadcastSetting } from "../graphql/subscriptions";
import { sessionsByDate } from "./../graphql/queries";
import NavigationBar from "./NavigationBar.react";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FontPicker from "./FontPicker.react";
import AlignPicker from "./AlignPicker.react";
import Stack from "react-bootstrap/Stack";
import Card from "react-bootstrap/Card";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import BroadcastScreenView from "./BroadcastScreenView.react";
import BroadcastScreenViewDual from "./BroadcastScreenViewDual.react";

type Props = $ReadOnly<{
  sessionId: ?string,
}>;

const DEFAULT_COLOR = "#04F404";
const DEFAULT_FONT = "Helvetica";
const DEFAULT_TEXT_BACKGROUND_COLOR = "#000000";
const DEFAULT_TEXT_BACKGROUND_OPACITY = 100;
const DEFAULT_TEXT_SHADOW_WIDTH = 0;
const DEFAULT_TEXT_SHADOW_BLUR = 2;
const DEFAULT_FONT_SIZE = "40";
const DEFAULT_FONT_COLOR = "#FFFFFF";
const DEFAULT_LINE_HEIGHT = "1.2";
const DEFAULT_MIN_LENGTH = 6;
const DEFAULT_MAX_LENGTH = 60;
const DEFAULT_MIN_SECONDS = 2;
const DEFAULT_MAX_SECONDS = 6;
const DEFAULT_SCREEN_WIDTH = 960;
const DEFAULT_SCREEN_HEIGHT = 540;
const DEFAULT_BACKGROUND_OPACITY = 100;
const DEFAULT_BORDER_OPACITY = 0;
const DEFAULT_MARGIN_BOTTOM = 0;
const DEFAULT_SUB_ALIGN = "center";
const DEFAULT_LINE_NUMBER = 2;
const DEFAULT_DUALSESSION_ID = "";
const DEFAULT_ISNOWRAP = false;
const DEFAULT_ISDEMOTEXT = false;
const DEFAULT_MULTI_SESSION_ID_LIST = [];

export default function BroadcasterView(props: Props): React$Element<any> {
  const [sessionName, setSessionName] = useState("");
  const [sessionList, setSessionList] = useState([]);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  // const [broadcastSetting, setBroadcastSetting] = useState([]);
  const [backgroundColor, setBackgroundColor] = useState(DEFAULT_COLOR);
  const [font, setFont] = useState(DEFAULT_FONT);
  const [textBackgroundColor, setTextBackgroundColor] = useState(
    DEFAULT_TEXT_BACKGROUND_COLOR
  );
  const [textBackgroundOpacity, setTextBackgroundOpacity] = useState(
    DEFAULT_TEXT_BACKGROUND_OPACITY
  );
  const [backgroundOpacity, setBackgroundOpacity] = useState(
    DEFAULT_BACKGROUND_OPACITY
  );
  const [borderOpacity, setBorderOpacity] = useState(DEFAULT_BORDER_OPACITY);
  const [textShadowWidth, setTextShadowWidth] = useState(
    DEFAULT_TEXT_SHADOW_WIDTH
  );
  const [textShadowBlur, setTextShadowBlur] = useState(
    DEFAULT_TEXT_SHADOW_BLUR
  );
  const [fontSize, setFontSize] = useState(DEFAULT_FONT_SIZE);
  const [fontColor, setFontColor] = useState(DEFAULT_FONT_COLOR);
  const [lineHeight, setLineHeight] = useState(DEFAULT_LINE_HEIGHT);
  const [minLength, setMinLength] = useState(DEFAULT_MIN_LENGTH);
  const [maxLength, setMaxLength] = useState(DEFAULT_MAX_LENGTH);
  const [minSeconds, setMinSeconds] = useState(DEFAULT_MIN_SECONDS);
  const [maxSeconds, setMaxSeconds] = useState(DEFAULT_MAX_SECONDS);
  const [screenWidth, setScreenWidth] = useState(DEFAULT_SCREEN_WIDTH);
  const [screenHeight, setScreenHeight] = useState(DEFAULT_SCREEN_HEIGHT);
  const [marginBottom, setMarginBottom] = useState(DEFAULT_MARGIN_BOTTOM);
  const [subAlign, setSubAlign] = useState(DEFAULT_SUB_ALIGN);
  const [lineNumber, setLineNumber] = useState(DEFAULT_LINE_NUMBER);
  const [dualSessionId, setDualSessionId] = useState(DEFAULT_DUALSESSION_ID);
  const [isNoWrap, setIsNoWrap] = useState(DEFAULT_ISNOWRAP);
  const [isDemoText, setIsDemoText] = useState(DEFAULT_ISDEMOTEXT);
  const [multiSessionIdList, setMultiSessionIdList] = useState(
    DEFAULT_MULTI_SESSION_ID_LIST
  );

  // const [backgroundColor, setBackgroundColor] = useState(broadcastSetting.screenColor);
  // const [font, setFont] = useState(broadcastSetting.subFont);
  // const [textBackgroundColor, setTextBackgroundColor] = useState(broadcastSetting.subBackgroundColor);
  // const [textBackgroundOpacity, setTextBackgroundOpacity] = useState(broadcastSetting.subBackgroundOpacity);
  // const [backgroundOpacity, setBackgroundOpacity] = useState(broadcastSetting.screenOpacity);
  // const [borderOpacity, setBorderOpacity] = useState(broadcastSetting.borderOpacity);
  // const [textShadowWidth, setTextShadowWidth] = useState(broadcastSetting.textShadowWidth);
  // const [textShadowBlur, setTextShadowBlur] = useState(broadcastSetting.textShadowBlur);
  // const [fontSize, setFontSize] = useState(broadcastSetting.subSize);
  // const [fontColor, setFontColor] = useState(broadcastSetting.subColor);
  // const [lineHeight, setLineHeight] = useState(broadcastSetting.subLineHeight);
  // const [minLength, setMinLength] = useState(broadcastSetting.subMinThresholdCharacters);
  // const [maxLength, setMaxLength] = useState(broadcastSetting.subMaxThresholdCharacters);
  // const [minSeconds, setMinSeconds] = useState(broadcastSetting.subMinDisplaySeconds);
  // const [maxSeconds, setMaxSeconds] = useState(broadcastSetting.subMaxDisplaySeconds);
  // const [screenWidth, setScreenWidth] = useState(broadcastSetting.screenWidth);
  // const [screenHeight, setScreenHeight] = useState(broadcastSetting.screenHeight);

  const userContext = useContext(UserContext);

  const styles = {
    inputText: {
      width: "120px",
      marginBottom: "8px",
    },
  };

  useEffect(() => {
    fetchSession();
  });

  useEffect(() => {
    fetchBroadcastSetting();
  }, []);

  useEffect(() => {
    fetchSessionList();
  }, []);

  // Subscribe to broadcast setting update
  useEffect(() => {
    const subscription = API.graphql({
      query: onUpdateBroadcastSetting,
      variables: {
        id: props.sessionId,
      },
    }).subscribe({
      next: () => {
        async function updateFetch() {
          const fetchedBroadcastSettings = await fetchBroadcastSetting();
          // setBroadcastSetting(fetchedBroadcastSettings);
        }
        updateFetch();
      },
      error: (error) => console.warn(error),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", clickSave);
    return () => {
      window.removeEventListener("keydown", clickSave);
    };
  }, []);

  const clickSave = (e) => {
    if (e.ctrlKey) {
      if (e.keyCode === 83) {
        // Cancel the default action, if needed
        e.preventDefault();
        document.getElementById("save-button").click();
      }
    }
  };

  const getNameById = (sessionId) => {
    for (let session of sessionList) {
      if (session.id === sessionId) {
        return session.name;
      }
      // return null;
    }
  };

  async function fetchSession() {
    const apiData = await API.graphql({
      query: getSession,
      variables: {
        id: props.sessionId,
      },
    });
    setSessionName(apiData.data.getSession.name);
  }

  async function fetchSessionList() {
    // setFetchSessionsInFlight(true);
    const apiData = await API.graphql({
      query: sessionsByDate,
      variables: { type: 0, sortDirection: "DESC" },
    });
    setSessionList(apiData.data?.sessionsByDate?.items ?? []);
    // setFetchSessionsInFlight(false);
    // console.log(sessionList);
  }

  async function fetchBroadcastSetting() {
    const apiData = await API.graphql({
      query: getBroadcastSetting,
      variables: {
        id: props.sessionId,
      },
    });
    // setBroadcastSetting(apiData.data.getBroadcastSetting);
    setBackgroundColor(apiData.data.getBroadcastSetting.screenColor);
    setFont(apiData.data.getBroadcastSetting.subFont);
    setTextBackgroundColor(apiData.data.getBroadcastSetting.subBackgroundColor);
    setTextBackgroundOpacity(
      apiData.data.getBroadcastSetting.subBackgroundOpacity
    );
    setBackgroundOpacity(apiData.data.getBroadcastSetting.screenOpacity);
    setBorderOpacity(apiData.data.getBroadcastSetting.borderOpacity);
    setTextShadowWidth(apiData.data.getBroadcastSetting.textShadowWidth);
    setTextShadowBlur(apiData.data.getBroadcastSetting.textShadowBlur);
    setFontSize(apiData.data.getBroadcastSetting.subSize);
    setFontColor(apiData.data.getBroadcastSetting.subColor);
    setLineHeight(apiData.data.getBroadcastSetting.subLineHeight);
    setMinLength(apiData.data.getBroadcastSetting.subMinThresholdCharacters);
    setMaxLength(apiData.data.getBroadcastSetting.subMaxThresholdCharacters);
    setMinSeconds(apiData.data.getBroadcastSetting.subMinDisplaySeconds);
    setMaxSeconds(apiData.data.getBroadcastSetting.subMaxDisplaySeconds);
    setScreenWidth(apiData.data.getBroadcastSetting.screenWidth);
    setScreenHeight(apiData.data.getBroadcastSetting.screenHeight);
    setMarginBottom(apiData.data.getBroadcastSetting.marginBottom);
    setSubAlign(apiData.data.getBroadcastSetting.subAlign);
    setLineNumber(apiData.data.getBroadcastSetting.lineNumber);
    setDualSessionId(apiData.data.getBroadcastSetting.dualSessionId);
    setIsNoWrap(apiData.data.getBroadcastSetting.isNoWrap);
    setIsDemoText(apiData.data.getBroadcastSetting.isDemoText);
    setMultiSessionIdList(apiData.data.getBroadcastSetting.multiSessionIdList);
  }

  async function updateBroadcastSetting() {
    if (props.sessionId === "" || userContext.userId === "") {
      return;
    }
    const ret = await API.graphql({
      query: updateBroadcastSettingMutation,
      variables: {
        input: {
          id: props.sessionId,
          screenWidth: screenWidth,
          screenHeight: screenHeight,
          screenColor: backgroundColor,
          screenOpacity: backgroundOpacity,
          subFont: font,
          subSize: fontSize,
          subColor: fontColor,
          subBackgroundColor: textBackgroundColor,
          subBackgroundOpacity: textBackgroundOpacity,
          subLineHeight: lineHeight,
          subMinDisplaySeconds: minSeconds,
          subMaxDisplaySeconds: maxSeconds,
          subMinThresholdCharacters: minLength,
          subMaxThresholdCharacters: maxLength,
          borderOpacity: borderOpacity,
          textShadowWidth: textShadowWidth,
          textShadowBlur: textShadowBlur,
          marginBottom: marginBottom,
          subAlign: subAlign,
          lineNumber: lineNumber,
          dualSessionId: dualSessionId,
          isNoWrap: isNoWrap,
          isDemoText: isDemoText,
          multiSessionIdList: multiSessionIdList,
        },
      },
    });
    setButtonEnabled(false);
  }

  const screenSettings1 = (
    <div className="ms-auto" style={{ minHeight: "600px", paddingTop: "0px" }}>
      {/* <div style={styles.inputText}>
                <Form.Label>Width</Form.Label>
                <Form.Control
                    size="sm"
                    type="text"
                    value={screenWidth}
                    onChange={(event) => {
                        setScreenWidth(event.target.value);
                    }}
                />
            </div>
            <div style={styles.inputText}>
                <Form.Label>Height</Form.Label>
                <Form.Control
                    size="sm"
                    type="text"
                    value={screenHeight}
                    onChange={(event) => {
                        setScreenHeight(event.target.value);
                    }}
                />
            </div> */}
      <div>
        <Form.Label>Screen Color</Form.Label>
        <Form.Control
          size="sm"
          type="color"
          value={backgroundColor}
          onChange={(event) => {
            setBackgroundColor(event.target.value);
            setButtonEnabled(true);
          }}
        />
      </div>
      <div style={{ marginTop: "8px" }}>
        <Button
          size="sm"
          variant="secondary"
          onClick={() => {
            if (backgroundColor === "#04F404") {
              return;
            } else {
              setBackgroundColor(DEFAULT_COLOR);
              setButtonEnabled(true);
            }
          }}
        >
          Set Default Color
        </Button>
      </div>
      <div style={styles.inputText}>
        <Form.Label>Screen Opacity</Form.Label>
        <Form.Control
          size="sm"
          type="number"
          min="0"
          max="100"
          step="10"
          value={backgroundOpacity}
          onChange={(event) => {
            setBackgroundOpacity(event.target.value);
            setButtonEnabled(true);
          }}
        />
      </div>
      <div style={styles.inputText}>
        <Form.Label>Border Opacity</Form.Label>
        <Form.Control
          size="sm"
          type="number"
          min="0"
          max="100"
          step="10"
          value={borderOpacity}
          onChange={(event) => {
            setBorderOpacity(event.target.value);
            setButtonEnabled(true);
          }}
        />
      </div>
      <div>
        <Form.Label>Text Box Color</Form.Label>
        <Form.Control
          size="sm"
          type="color"
          value={textBackgroundColor}
          onChange={(event) => {
            setTextBackgroundColor(event.target.value);
            setButtonEnabled(true);
          }}
        />
      </div>
      <div style={styles.inputText}>
        <Form.Label>Text Box Opacity</Form.Label>
        <Form.Control
          size="sm"
          type="number"
          min="0"
          max="100"
          step="10"
          value={textBackgroundOpacity}
          onChange={(event) => {
            setTextBackgroundOpacity(event.target.value);
            setButtonEnabled(true);
          }}
        />
      </div>
      <div style={styles.inputText}>
        <Form.Label>Max Line Num</Form.Label>
        <Form.Control
          size="sm"
          type="number"
          step="1"
          min="1"
          max="10"
          value={lineNumber}
          onChange={(event) => {
            setLineNumber(event.target.value);
            setButtonEnabled(true);
          }}
        />
      </div>
      <div style={styles.inputText}>
        <Form.Label>Shadow Width</Form.Label>
        <Form.Control
          size="sm"
          type="number"
          step="0.1"
          min="0"
          max="3"
          value={textShadowWidth}
          onChange={(event) => {
            setTextShadowWidth(event.target.value);
            setButtonEnabled(true);
          }}
        />
      </div>
      <div style={styles.inputText}>
        <Form.Label>Shadow Blur</Form.Label>
        <Form.Control
          size="sm"
          type="number"
          step="0.1"
          min="0"
          max="4"
          value={textShadowBlur}
          onChange={(event) => {
            setTextShadowBlur(event.target.value);
            setButtonEnabled(true);
          }}
        />
      </div>
      <div style={styles.inputText}>
        <Form.Label>Margin Bottom</Form.Label>
        <Form.Control
          size="sm"
          type="number"
          step="1"
          min="0"
          max="600"
          value={marginBottom}
          onChange={(event) => {
            setMarginBottom(event.target.value);
            setButtonEnabled(true);
          }}
        />
      </div>
      <div style={styles.inputText}>
        <Form.Check
          type="checkbox"
          label="No Wrap"
          checked={isNoWrap}
          onChange={(event) => {
            setIsNoWrap((prev) => !prev);
            setButtonEnabled(true);
          }}
        />
        <Form.Check
          type="checkbox"
          label="Demo Text"
          checked={isDemoText}
          onChange={(event) => {
            setIsDemoText((prev) => !prev);
            setButtonEnabled(true);
          }}
        />
      </div>
    </div>
  );

  const screenSettings2 = (
    <div
      style={{ minHeight: "600px", paddingTop: "0px", paddingBottom: "0px" }}
    >
      <div>
        <Form.Label>Subtitle Font</Form.Label>
        <FontPicker
          value={font}
          onValueChange={(value) => {
            setFont(value);
            setButtonEnabled(true);
          }}
        />
      </div>
      <div>
        <Form.Label>Subtitle Align</Form.Label>
        <AlignPicker
          value={subAlign}
          onValueChange={(value) => {
            setSubAlign(value);
            setButtonEnabled(true);
          }}
        />
      </div>
      <div style={styles.inputText}>
        <Form.Label>Subtitle Size</Form.Label>
        <Form.Control
          size="sm"
          type="number"
          value={fontSize}
          onChange={(event) => {
            setFontSize(event.target.value);
            setButtonEnabled(true);
          }}
        />
      </div>
      <div>
        <Form.Label>Text Color</Form.Label>
        <Form.Control
          size="sm"
          type="color"
          value={fontColor}
          onChange={(event) => {
            setFontColor(event.target.value);
            setButtonEnabled(true);
          }}
        />
      </div>
      <div style={styles.inputText}>
        <Form.Label>Line Height</Form.Label>
        <Form.Control
          size="sm"
          type="number"
          step="0.1"
          value={lineHeight}
          onChange={(event) => {
            setLineHeight(event.target.value);
            setButtonEnabled(true);
          }}
        />
      </div>
      <div style={styles.inputText}>
        <Form.Label>Min Display Sec</Form.Label>
        <Form.Control
          size="sm"
          type="number"
          value={minSeconds}
          onChange={(event) => {
            setMinSeconds(event.target.value);
            setButtonEnabled(true);
          }}
        />
      </div>
      <div style={styles.inputText}>
        <Form.Label>Max Display Sec</Form.Label>
        <Form.Control
          size="sm"
          type="number"
          value={maxSeconds}
          onChange={(event) => {
            setMaxSeconds(event.target.value);
            setButtonEnabled(true);
          }}
        />
      </div>
      <div style={styles.inputText}>
        <Form.Label>Min Thres Chars</Form.Label>
        <Form.Control
          size="sm"
          type="number"
          value={minLength}
          onChange={(event) => {
            setMinLength(event.target.value);
            setButtonEnabled(true);
          }}
        />
      </div>
      <div style={styles.inputText}>
        <Form.Label>Max Thres Chars</Form.Label>
        <Form.Control
          size="sm"
          type="number"
          value={maxLength}
          onChange={(event) => {
            setMaxLength(event.target.value);
            setButtonEnabled(true);
          }}
        />
      </div>
      <div style={styles.inputText}>
        <Form.Label>Dual Session Id</Form.Label>
        <Form.Control
          size="sm"
          type="text"
          value={dualSessionId}
          onChange={(event) => {
            setDualSessionId(event.target.value);
            setButtonEnabled(true);
          }}
        />
      </div>
      <div style={styles.inputText}>
        <Form.Label>Multi Sessions</Form.Label>
        {multiSessionIdList &&
          multiSessionIdList.map((id, index) => (
            <Row key={index}>
              <Col className="m-0 p-0" xs={9}>
                <Form.Control
                  readOnly
                  style={{ backgroundColor: "white" }}
                  size="sm"
                  type="text"
                  defaultValue={getNameById(id)}
                />
              </Col>
              <Col className="m-0 p-0" xs={2}>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => {
                    const updatedList = [...multiSessionIdList]; // Create a copy of the array
                    updatedList.splice(index, 1);
                    setMultiSessionIdList(updatedList);
                    setButtonEnabled(true);
                  }}
                >
                  X
                </Button>
              </Col>
            </Row>
          ))}
        {/* Make a function to add multi session ids from here */}
        <DropdownButton
          id="dropdown-item-button"
          title="Add Multi"
          variant="light"
        >
          {sessionList.map((session, index) => (
            <Dropdown.Item
              as="button"
              // style={{ overflow: "scroll" }}
              key={index}
              onClick={() => {
                // setSheetName(value);
                // console.log(JSON.stringify(session));
                if (!multiSessionIdList) {
                  setMultiSessionIdList([session.id]);
                } else {
                  setMultiSessionIdList((prev) => [...prev, session.id]);
                }
                setButtonEnabled(true);
              }}
            >
              <span>{session.name}</span>
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>
    </div>
  );

  if (props.sessionId == null) {
    return <div>Invalid Session</div>;
  }
  return (
    <>
      <NavigationBar />
      <Container fluid>
        <span
          style={{
            marginLeft: "10px",
            position: "absolute",
            left: "0",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          {sessionName}
        </span>
        <Row className="p-0">
          <Col className="p-2">
            <Stack direction="horizontal" gap={3}>
              <BroadcastScreenViewDual
                sessionId={props.sessionId}
                backgroundColor={backgroundColor}
                backgroundOpacity={backgroundOpacity}
                borderOpacity={borderOpacity}
                font={font}
                fontSize={fontSize}
                fontColor={fontColor}
                lineHeight={lineHeight}
                textBackgroundColor={textBackgroundColor}
                textBackgroundOpacity={textBackgroundOpacity}
                textShadowWidth={textShadowWidth}
                textShadowBlur={textShadowBlur}
                minSeconds={minSeconds}
                maxSeconds={maxSeconds}
                minSecondsTextLengthThreshold={minLength}
                maxSecondsTextLengthThreshold={maxLength}
                screenWidth={screenWidth}
                screenHeight={screenHeight}
                isPreview={true}
                marginBottom={marginBottom}
                subAlign={subAlign}
                lineNumber={lineNumber}
                isNoWrap={isNoWrap}
                isDemoText={isDemoText}
                multiSessionIdList={multiSessionIdList}
              />
              {screenSettings1}
              {screenSettings2}
            </Stack>
            <br />
            <Button
              className="float-end"
              variant="primary"
              // size="lg"
              onClick={updateBroadcastSetting}
              id="save-button"
              disabled={!buttonEnabled}
              style={{ marginRight: "100px" }}
            >
              Apply (Ctrl+S)
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

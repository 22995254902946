export default function signIn() {
  let oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";
  // let tokenEndpoint = "https://oauth2.googleapis.com/token";
  let redirectUri = window.location.origin + "/callback"; // Use the current site's URL as the redirect URI
  // let redirectUri = "http://localhost:3000/callback";
  // console.log(redirectUri);

  let form = document.createElement("form");
  form.setAttribute("method", "GET");
  form.setAttribute("action", oauth2Endpoint);

  let params = {
    client_id: process.env.REACT_APP_GOOGLE_COMET_SA,
    redirect_uri: redirectUri,
    response_type: "code",
    scope:
      "https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/drive",
    include_granted_scopes: "true",
    prompt: "consent",
    access_type: "offline", // Request an offline access type to obtain a refresh token
    state: "pass-through-value",
  };

  for (var p in params) {
    let input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", p);
    input.setAttribute("value", params[p]);
    form.appendChild(input);
  }

  document.body.appendChild(form);
  form.submit();
}

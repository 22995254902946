// @flow

import { API } from "aws-amplify";
import React, { useState, useEffect, useContext } from "react";

import { Container, Row, Col, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Card from "react-bootstrap/Card";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Modal from "react-bootstrap/Modal";

import NavigationBar from "./NavigationBar.react";
import UserContext from "./../utils/UserContext";
import { defaultKeyCommands, functionKeys } from "./../utils/util";

import { queryUserMacrosByUserIdTriggerWordIndex } from "./../graphql/queries";
import {
  createUserMacro as createUserMacroMutation,
  deleteUserMacro as deleteUserMacroMutation,
  updateUserMacro as updateUserMacroMutation,
} from "./../graphql/mutations";
import {
  onCreateUserMacro,
  onUpdateUserMacro,
  onDeleteUserMacro,
} from "./../graphql/subscriptions";
import {
  onCreateUserKeyCommand,
  onUpdateUserKeyCommand,
} from "./../graphql/subscriptions";

import { queryUserKeyCommandsByUserIdCommandNameIndex as queryUserKeyCommands } from "./../graphql/queries";
import {
  createUserKeyCommand as createUserKeyCommandMutation,
  updateUserKeyCommand as updateUserKeyCommandMutation,
} from "./../graphql/mutations";

const changeTitle = (n) => {
  if (n === "clearSubtitles") {
    return "Clear Subtitles";
  } else if (n === "focusInput") {
    return "Focus Input Field";
  } else if (n === "moveDown") {
    return "Move Down Row";
  } else if (n === "moveUp") {
    return "Move Up Row";
  } else if (n === "sendTranslation") {
    return "Send Translation";
  } else if (n === "sendScript") {
    return "Send Script";
  }
};

const changeKeyLabel = (k) => {
  if (k === "ArrowUp") {
    return "↑";
  } else if (k === "ArrowDown") {
    return "↓";
  } else if (k === "ArrowRight") {
    return "→";
  } else if (k === "ArrowLeft") {
    return "←";
  } else {
    return k;
  }
};

const styles = {
  title: {
    width: "200px",
    fontWeight: "bold",
    backgroundColor: "white",
    border: "1px solid black",
  },
  inputItem: {
    maxWidth: "200px",
  },
  inputGroupName: {
    maxWidth: "150px",
  },
};

const macroStyle = (e) => {
  if (e) {
    return {
      width: "200px",
      cursor: "default",
    };
  } else {
    return {
      color: "gray",
      width: "200px",
      cursor: "default",
    };
  }
};

async function fetchUserMacro(userId) {
  try {
    const apiData = await API.graphql({
      query: queryUserMacrosByUserIdTriggerWordIndex,
      variables: {
        userId: userId,
      },
    });
    return apiData.data.queryUserMacrosByUserIdTriggerWordIndex.items;
  } catch (e) {
    console.log(e);
  }
}

async function createUserMacro(
  userId: string,
  group: string,
  macroName: string,
  triggerWord: string,
  insertWord: string,
  isEnabled: boolean
) {
  const ret = await API.graphql({
    query: createUserMacroMutation,
    variables: {
      input: {
        userId: userId,
        macroName: macroName,
        triggerWord: triggerWord,
        insertWord: insertWord,
        macroGroup: group,
        isEnabled: false,
      },
    },
  });
}

async function updateUserMacro(
  id: string,
  macroGroup: string,
  macroName: string,
  triggerWord: string,
  insertWord: string,
  isEnabled: boolean
) {
  const ret = await API.graphql({
    query: updateUserMacroMutation,
    variables: {
      input: {
        id: id,
        macroGroup: macroGroup,
        macroName: macroName,
        triggerWord: triggerWord,
        insertWord: insertWord,
        isEnabled: isEnabled,
      },
    },
  });
}

async function updateMacroEnabled(id: string, isEnabled: boolean) {
  const ret = await API.graphql({
    query: updateUserMacroMutation,
    variables: {
      input: {
        id: id,
        isEnabled: isEnabled,
      },
    },
  });
}

async function deleteUserMacro(id: string) {
  const ret = await API.graphql({
    query: deleteUserMacroMutation,
    variables: {
      input: {
        id: id,
      },
    },
  });
}

async function fetchUserKeyCommands(userId) {
  try {
    const apiData = await API.graphql({
      query: queryUserKeyCommands,
      variables: {
        userId: userId,
      },
    });
    return apiData.data.queryUserKeyCommandsByUserIdCommandNameIndex.items;
  } catch (e) {
    console.log(e);
  }
}

async function createUserKeyCommand(
  userId: string,
  commandName: string,
  triggerKey: string
) {
  const ret = await API.graphql({
    query: createUserKeyCommandMutation,
    variables: {
      input: {
        userId: userId,
        commandName: commandName,
        triggerKey: triggerKey,
        isEnabled: true,
      },
    },
  });
}

async function updateUserKeyCommand(
  id: string,
  triggerKey: string,
  isEnabled: boolean
) {
  const ret = await API.graphql({
    query: updateUserKeyCommandMutation,
    variables: {
      input: {
        id: id,
        triggerKey: triggerKey,
        isEnabled: isEnabled,
      },
    },
  });
}

export default function MacroEditView(props: Props): React$Element<any> {
  const userContext = useContext(UserContext);
  const [macroArray, setMacroArray] = useState([]);
  const [macroGroupArray, setMacroGroupArray] = useState([]);
  const [keyCommandArray, setKeyCommandArray] = useState([]);

  const [newName, setNewName] = useState("");
  const [newTrigger, setNewTrigger] = useState("");
  const [newInsert, setNewInsert] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [newGroup, setNewGroup] = useState("");
  const [editableGroup, setEditableGroup] = useState("");
  const [newGroupArray, setNewGroupArray] = useState([]);

  // Initial Fetch
  useEffect(() => {
    handleFetchKeyCommands();
  }, []);

  useEffect(() => {
    handleFetchMacro();
  }, []);

  // Subscribe to User Macro mutation
  useEffect(() => {
    const subscription = API.graphql({
      query: onCreateUserMacro,
      variables: {
        userId: userContext.userId,
      },
    }).subscribe({
      next: () => {
        handleFetchMacro();
      },
      error: (error) => console.warn(error),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const subscription = API.graphql({
      query: onDeleteUserMacro,
      variables: {
        userId: userContext.userId,
      },
    }).subscribe({
      next: () => {
        handleFetchMacro();
      },
      error: (error) => console.warn(error),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const subscription = API.graphql({
      query: onUpdateUserMacro,
      variables: {
        userId: userContext.userId,
      },
    }).subscribe({
      next: () => {
        handleFetchMacro();
      },
      error: (error) => console.warn(error),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // Subscribe to Key Command mutation
  useEffect(() => {
    const subscription = API.graphql({
      query: onCreateUserKeyCommand,
      variables: {
        userId: userContext.userId,
      },
    }).subscribe({
      next: () => {
        handleFetchKeyCommands();
      },
      error: (error) => console.warn(error),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const subscription = API.graphql({
      query: onUpdateUserKeyCommand,
      variables: {
        userId: userContext.userId,
      },
    }).subscribe({
      next: () => {
        handleFetchKeyCommands();
      },
      error: (error) => console.warn(error),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // Sort Macro Array
  useEffect(() => {
    macroArray.sort((a, b) => (a.macroName > b.macroName ? 1 : -1));
  }, [macroArray]);

  // Various functions
  const makeMacroGroupArray = (macro: array) => {
    let arr = [];
    for (let i = 0; i < macro.length; i++) {
      if (!arr.includes(macro[i].macroGroup)) {
        arr.push(macro[i].macroGroup);
      }
    }
    arr.sort();
    setMacroGroupArray(arr);
  };

  const groupBatchUpdate = () => {
    newGroupArray.map((macro, idx) => {
      updateUserMacro(
        macro.id,
        newGroup,
        macro.macroName,
        macro.triggerWord,
        macro.insertWord
      );
    });
  };

  const confirmDeleteMacro = (id, name) => {
    const confirmDelete = () => {
      if (window.confirm(`Are you sure to delete "${name}"?`)) {
        deleteUserMacro(id);
        setEditableGroup("");
      } else {
        return;
      }
    };
    confirmDelete();
  };

  const compareArrays = (arr1, arr2) => {
    for (let i = 0; i < arr1.length; i++) {
      for (let j = 0; j < arr2.length; j++) {
        if (
          arr1[i].triggerWord === arr2[j].triggerWord &&
          arr1[i].id !== arr2[j].id
        ) {
          return [arr1[i], arr2[j]];
        }
      }
    }
    return false;
  };

  const checkGroupEnabled = (group) => {
    for (let i = 0; i < macroArray.length; i++) {
      if (macroArray[i].macroGroup === group && macroArray[i].isEnabled)
        return true;
    }
    return false;
  };

  const editNewGroup = (id, param, event) => {
    const index = newGroupArray.findIndex((x) => x.id === id);
    newGroupArray[index][param] = event.target.value;
  };

  const groupSwitch = (group) => {
    if (checkGroupEnabled(group)) {
      for (let i = 0; i < macroArray.length; i++) {
        if (macroArray[i].macroGroup === group) {
          updateMacroEnabled(macroArray[i].id, false);
        }
      }
    } else {
      for (let i = 0; i < macroArray.length; i++) {
        if (macroArray[i].macroGroup === group) {
          updateMacroEnabled(macroArray[i].id, true);
        }
      }
    }
  };

  // Event handlers
  const handleFetchKeyCommands = () => {
    async function updateFetch() {
      const keyCommands = await fetchUserKeyCommands(userContext.userId);
      // Use default key if there's no custom key command trigger
      for (let i = 0; i < defaultKeyCommands.length; i++) {
        if (
          !keyCommands.find(
            (x) => x.commandName === defaultKeyCommands[i].commandName
          )
        ) {
          keyCommands.push(defaultKeyCommands[i]);
        }
        // Sort key command array by command name
        keyCommands.sort((a, b) => (a.commandName > b.commandName ? 1 : -1));
      }
      setKeyCommandArray(keyCommands);
    }
    updateFetch();
  };

  const handleUpdateKeyCommand = (id, commandName, eventKey, isEnabled) => {
    if (id) {
      updateUserKeyCommand(id, eventKey, isEnabled);
    } else {
      createUserKeyCommand(userContext.userId, commandName, eventKey);
    }
  };

  const handleFetchMacro = () => {
    async function updateFetch() {
      const macro = await fetchUserMacro(userContext.userId);
      setMacroArray(macro);
      makeMacroGroupArray(macro);
    }
    updateFetch();
  };

  const handleCreateMacro = () => {
    if (macroArray.find((x) => x.triggerWord === newTrigger)) {
      let usedIndex = macroArray.findIndex((x) => x.triggerWord === newTrigger);
      alert(
        `Trigger "${newTrigger}" is already taken. \n(By macro "${macroArray[usedIndex].macroName}" in group "${macroArray[usedIndex].macroGroup}")`
      );
    } else {
      createUserMacro(
        userContext.userId,
        newGroup,
        newName,
        newTrigger,
        newInsert
      );
      setNewName("");
      setNewTrigger("");
      setNewInsert("");
      setShowCreateModal(false);
    }
  };

  const handleClickAdd = (group) => {
    setShowCreateModal(true);
    setNewGroup(group);
    setNewName("");
    setNewTrigger("");
    setNewInsert("");
  };

  const handleClickNewMacro = () => {
    // if (newGroup === "") {
    //   setNewGroup(macroGroupArray[0]);
    // }
    setShowCreateModal(true);
    setNewGroup("");
    setNewName("");
    setNewTrigger("");
    setNewInsert("");
  };

  const handleClickEdit = (group) => {
    setNewGroup(group);
    setEditableGroup(group);
    handleNewGroupArray(group);
  };

  const handleClickSave = () => {
    const overlap = compareArrays(newGroupArray, macroArray);
    if (overlap) {
      alert(
        `Trigger of macro "${overlap[0].macroName}" is already taken. \n(By "${overlap[1].macroName}" in group "${overlap[1].macroGroup}")`
      );
    } else {
      setEditableGroup("");
      groupBatchUpdate();
    }
  };

  const handleClickDelete = (id, name) => {
    confirmDeleteMacro(id, name);
  };

  const handleNewGroupArray = (group) => {
    let newArray = [];
    macroArray.map((macro, idx) => {
      if (macro.macroGroup === group) {
        newArray.push({
          id: macro.id,
          macroGroup: macro.macroGroup,
          macroName: macro.macroName,
          triggerWord: macro.triggerWord,
          insertWord: macro.insertWord,
        });
      }
    });
    setNewGroupArray(newArray);
  };

  // Components
  const createMacroModal = (
    <Modal
      animation={false}
      show={showCreateModal}
      onHide={() => {
        setShowCreateModal(false);
      }}
      backdrop="static"
      dialogClassName="modal-60w"
      keyboard={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create New Macro</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Group
        <DropdownButton
          id="dropdown-basic"
          title={
            macroGroupArray.find((x) => x === newGroup)
              ? newGroup
              : "New Group (+)"
          }
          variant="outline-dark"
          onSelect={(eventKey) => {
            setNewGroup(eventKey);
          }}
        >
          {macroGroupArray.map((group, idx) => (
            <Dropdown.Item
              key={idx}
              active={newGroup === group}
              eventKey={group}
            >
              {group}
            </Dropdown.Item>
          ))}
          <Dropdown.Item eventKey={""}>New Group (+)</Dropdown.Item>
        </DropdownButton>
        {macroGroupArray.find((x) => x === newGroup) ? null : (
          <InputGroup className="mt-4">
            <InputGroup.Text>Group</InputGroup.Text>
            <Form.Control
              autoFocus
              type="text"
              style={{ maxWidth: "180px" }}
              onChange={(event) => {
                setNewGroup(event.target.value);
              }}
              value={newGroup}
            ></Form.Control>
          </InputGroup>
        )}
        <Row className="mt-4">
          <Col>
            <InputGroup>
              <InputGroup.Text>Name</InputGroup.Text>
              <Form.Control
                autoFocus={macroGroupArray.find((x) => x === newGroup)}
                type="text"
                style={{ maxWidth: "180px" }}
                onChange={(event) => {
                  setNewName(event.target.value);
                }}
                value={newName}
              ></Form.Control>
            </InputGroup>
          </Col>
          <Col>
            <InputGroup>
              <InputGroup.Text>Trigger</InputGroup.Text>
              <Form.Control
                type="text"
                style={{ maxWidth: "180px" }}
                onChange={(event) => {
                  setNewTrigger(event.target.value);
                }}
                value={newTrigger}
              ></Form.Control>
            </InputGroup>
          </Col>
          <Col>
            <InputGroup>
              <InputGroup.Text>Insert</InputGroup.Text>
              <Form.Control
                type="text"
                style={{ maxWidth: "180px" }}
                onChange={(event) => {
                  setNewInsert(event.target.value);
                }}
                value={newInsert}
              ></Form.Control>
            </InputGroup>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShowCreateModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleCreateMacro();
          }}
          disabled={!newName || !newTrigger || !newInsert || !newGroup}
        >
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const macroByGroup = (group, macro, idx) => {
    if (group === macro.macroGroup) {
      return (
        <Row>
          {editableGroup === group ? (
            <Col xs={12}>
              <InputGroup key={idx}>
                <Form.Control
                  type="text"
                  style={styles.inputItem}
                  placeholder="Macro Name"
                  onChange={(event) => {
                    editNewGroup(macro.id, "macroName", event);
                  }}
                  defaultValue={macro.macroName}
                />
                <Form.Control
                  type="text"
                  style={styles.inputItem}
                  placeholder="Macro Trigger"
                  onChange={(event) => {
                    editNewGroup(macro.id, "triggerWord", event);
                  }}
                  defaultValue={macro.triggerWord}
                />
                <Form.Control
                  type="text"
                  style={styles.inputItem}
                  placeholder="Displayed Word"
                  onChange={(event) => {
                    editNewGroup(macro.id, "insertWord", event);
                  }}
                  defaultValue={macro.insertWord}
                />
                <DropdownButton
                  id="dropdown-basic"
                  title={macro.macroGroup}
                  variant="outline-dark"
                  onSelect={(eventKey) => {
                    updateUserMacro(macro.id, eventKey);
                    newGroupArray.splice(
                      newGroupArray.findIndex((x) => x.id === macro.id),
                      1
                    );
                  }}
                >
                  {macroGroupArray.map((group, idx) => (
                    <Dropdown.Item
                      key={idx}
                      active={macro.macroGroup === group}
                      eventKey={group}
                    >
                      {group}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
                <Button
                  className="float-start"
                  variant="outline-danger"
                  onClick={() => {
                    handleClickDelete(macro.id, macro.macroName);
                  }}
                >
                  Delete
                </Button>
              </InputGroup>
            </Col>
          ) : (
            <Col xs={12}>
              <InputGroup>
                <InputGroup.Text
                  style={macroStyle(macro.isEnabled)}
                  id="macro-name"
                >
                  {macro.macroName}
                </InputGroup.Text>
                <InputGroup.Text
                  style={macroStyle(macro.isEnabled)}
                  id="macro-trigger"
                >
                  {macro.triggerWord}
                </InputGroup.Text>
                <InputGroup.Text
                  style={macroStyle(macro.isEnabled)}
                  id="macro-insert"
                >
                  {macro.insertWord}
                </InputGroup.Text>
                <Form.Check
                  className="float-start ms-3 me-1"
                  type="switch"
                  checked={macro.isEnabled}
                  onChange={() => {
                    macro.isEnabled
                      ? updateMacroEnabled(macro.id, false)
                      : updateMacroEnabled(macro.id, true);
                  }}
                />
              </InputGroup>
            </Col>
          )}
        </Row>
      );
    }
  };

  return (
    <>
      <NavigationBar />
      {createMacroModal}
      <Container fluid>
        <Row>
          <Col className="p-0">
            <Card className="m-2" bg="secondary" text="white">
              <Card.Body>
                <Card.Text as="h3">Edit User Macro</Card.Text>
                <Card.Text>
                  Hello, <i>{userContext.username}</i>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {userContext.isSuperAdmin ? (
          <Button
            className="ms-2 float-end"
            variant="primary"
            onClick={() => {
              createUserKeyCommand(userContext.userId);
            }}
          >
            Create Key Command (admin)
          </Button>
        ) : null}
        <div className="mb-4 mt-3">
          <h1>Key Command</h1>
        </div>
        <InputGroup>
          <InputGroup.Text
            style={{
              width: "15%",
              fontWeight: "bold",
              backgroundColor: "white",
              border: "1px solid black",
            }}
          >
            Command
          </InputGroup.Text>
          <InputGroup.Text
            style={{
              width: "fit-content",
              fontWeight: "bold",
              backgroundColor: "white",
              border: "1px solid black",
            }}
          >
            Key
          </InputGroup.Text>
        </InputGroup>
        {keyCommandArray.map((command, idx) => (
          <InputGroup key={idx}>
            <InputGroup.Text style={{ width: "15%" }}>
              {changeTitle(command.commandName)}
            </InputGroup.Text>
            <DropdownButton
              id="dropdown-basic"
              title={changeKeyLabel(command.triggerKey)}
              variant="outline-dark"
              onSelect={(eventKey) => {
                handleUpdateKeyCommand(
                  command.id,
                  command.commandName,
                  eventKey,
                  true
                );
              }}
            >
              {functionKeys.map((functionKey, idx) => (
                <Dropdown.Item
                  key={idx + 1}
                  active={command.triggerKey === functionKey}
                  hidden={
                    command.triggerKey !== functionKey &&
                    keyCommandArray.find(
                      (command) => command.triggerKey === functionKey
                    ) &&
                    functionKey !== "Off"
                  }
                  eventKey={functionKey}
                >
                  {changeKeyLabel(functionKey)}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </InputGroup>
        ))}
        <br />
        <br />
        <div className="mb-0 mt-2">
          <h1>Replace Macro</h1>
        </div>
        {/* {JSON.stringify(keyCommandArray)} */}
        {/* {JSON.stringify(newGroupArray)} */}
        {/* {JSON.stringify(macroArray)} */}
        {/* {macroArray.map((macro, idx) => (<div key={idx}>{macro.triggerWord}</div>))} */}
        <Button
          className="float-end"
          variant="primary"
          onClick={() => {
            handleClickNewMacro();
          }}
        >
          Create New Macro
        </Button>
        <br />
        <br />
        {macroGroupArray.map((group, idx) => (
          <div className="mb-5" key={idx}>
            {editableGroup === group ? (
              <InputGroup className="mb-2">
                <Form.Control
                  autoFocus
                  className="my-0"
                  type="text"
                  style={{ maxWidth: "150px" }}
                  onChange={(event) => {
                    setNewGroup(event.target.value);
                  }}
                  value={newGroup}
                ></Form.Control>
                <Button
                  className="my-0"
                  variant="outline-primary"
                  onClick={() => {
                    handleClickSave();
                  }}
                >
                  Save
                </Button>
              </InputGroup>
            ) : (
              <Row className="m-0">
                <div
                  className="me-0 mb-2 ps-1 pe-0"
                  style={{ display: "inline-block", width: "fit-content" }}
                >
                  <h4>{group}</h4>
                </div>
                <div style={{ display: "inline-block", width: "fit-content" }}>
                  <Button
                    className="ms-2"
                    size="sm"
                    variant="outline-secondary"
                    onClick={() => {
                      handleClickEdit(group);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    className="ms-1"
                    size="sm"
                    variant="outline-primary"
                    onClick={() => {
                      handleClickAdd(group);
                    }}
                  >
                    Add
                  </Button>
                  <Form.Check
                    className="float-end ms-2 me-2"
                    type="switch"
                    checked={checkGroupEnabled(group)}
                    onChange={() => {
                      groupSwitch(group);
                    }}
                  />
                </div>
              </Row>
            )}
            <Row>
              <Col xs={12}>
                <InputGroup style={{ cursor: "default" }}>
                  <InputGroup.Text style={styles.title}>
                    Macro Name
                  </InputGroup.Text>
                  <InputGroup.Text style={styles.title}>
                    Trigger
                  </InputGroup.Text>
                  <InputGroup.Text style={styles.title}>Insert</InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
            <div>
              {macroArray.map((macro, idx) => (
                <div key={idx}>{macroByGroup(group, macro, idx)}</div>
              ))}
            </div>
          </div>
        ))}
      </Container>
    </>
  );
}

// @flow

import { getSession, getBroadcastSetting } from "./../graphql/queries";
import { updateSession as updateSessionMutation } from "./../graphql/mutations";
import { onUpdateBroadcastSetting, onUpdateSession } from "../graphql/subscriptions";
import { API } from "aws-amplify";
import React, { useState, useEffect } from "react";
import MessagesView from "./MessagesView.react";
import { Container, Row, Col, ToggleButton, ButtonGroup } from "react-bootstrap";
import NavigationBar from "./NavigationBar.react";

import Card from "react-bootstrap/Card";
import ChatInputView from "./ChatInputView.react";
import SessionUsersView from "./SessionUsersView.react";
import ShortcutsView from "./ShortcutsView.react";
import SheetsView from "./SheetsView.react";
import BroadcastScreenView from "./BroadcastScreenView.react"
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";


type Props = $ReadOnly<{
  sessionId: ?string,
}>;

export default function TrainingView(props: Props): React$Element<any> {
  const [sessionName, setSessionName] = useState("");
  const [broadcastSetting, setBroadcastSetting] = useState([]);

  const [showSheets, setShowSheets] = useState(false);
  const [showShortcuts, setShowShortcuts] = useState(false);
  const [sheetsChecked, setSheetsChecked] = useState(false);
  const [shortcutsChecked, setShortcutsChecked] = useState(false);

  const [shortcutInput, setShortcutInput] = useState("");
  const [shortcutInputIndex, setShortcutInputIndex] = useState(0);

  const [sourceWord, setSourceWord] = useState("");
  const [translatedWord, setTranslatedWord] = useState("");

  const [streamUrl, setStreamUrl] = useState("");
  const [showSettingModal, setShowSettingModal] = useState(false);

  const [fontSize, setFontSize] = useState(24);
  const [minSeconds, setMinSeconds] = useState(3);
  const [maxSeconds, setMaxSeconds] = useState(7);
  const [marginBottom, setMarginBottom] = useState(8);
  const [lineNumber, setLineNumber] = useState(2);

  let inputWidth;
  if (!showSheets && !showShortcuts) {
    inputWidth = 12;
  } else if (showSheets && !showShortcuts) {
    inputWidth = 6;
  } else if (!showSheets && showShortcuts) {
    inputWidth = 10;
  } else if (showSheets && showShortcuts) {
    inputWidth = 5;
  }

  let sheetsWidth;
  if (showShortcuts) {
    sheetsWidth = 6;
  } else if (!showShortcuts) {
    sheetsWidth = 6;
  } else {
    sheetsWidth = 7;
  }

  let shortcutsWidth;
  if (showSheets) {
    shortcutsWidth = 1;
  } else {
    shortcutsWidth = 2;
  }


  useEffect(() => {
    fetchSession();
  });

  useEffect(() => {
    fetchBroadcastSetting();
  }, []);

  // Subscribe to session info update (for stream url)
  useEffect(() => {
    const subscription = API.graphql({
      query: onUpdateSession,
      variables: {
        id: props.sessionId,
      },
    }).subscribe({
      next: () => {
        fetchSession();
      },
      error: (error) => console.warn(error),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // Subscribe to broadcast setting update
  useEffect(() => {
    const subscription = API.graphql({
      query: onUpdateBroadcastSetting,
      variables: {
        id: props.sessionId,
      },
    }).subscribe({
      next: () => {
        fetchBroadcastSetting();
      },
      error: (error) => console.warn(error),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);


  async function fetchSession() {
    const apiData = await API.graphql({
      query: getSession,
      variables: {
        id: props.sessionId,
      },
    });
    setSessionName(apiData.data.getSession.name);
    setStreamUrl(apiData.data.getSession.streamUrl);
  }

  async function fetchBroadcastSetting() {
    const apiData = await API.graphql({
      query: getBroadcastSetting,
      variables: {
        id: props.sessionId,
      },
    });
    setBroadcastSetting(apiData.data.getBroadcastSetting);
  }

  async function updateSessionStreamUrl(url: string) {
    if (props.sessionId === "") {
      return;
    }
    const ret = await API.graphql({
      query: updateSessionMutation,
      variables: {
        input: {
          id: props.sessionId,
          streamUrl: url,
        },
      },
    });
  }

  function handleShowSheets(e) {
    setSheetsChecked(e.currentTarget.checked);
    setShowSheets(prevShowSheets => !prevShowSheets)
  }

  function handleShowShortcuts(e) {
    setShortcutsChecked(e.currentTarget.checked);
    setShowShortcuts(prevShowShortcuts => !prevShowShortcuts)
  }

  function openSettingModal(id: string, name: string) {
    setShowSettingModal(true);
  }

  function exitSettingModal() {
    setShowSettingModal(false);
  }

  const streamUrlModal = (
    <Modal
      show={showSettingModal}
      onHide={() => {
        setShowSettingModal(false);
      }}
      backdrop="static"
      // keyboard={false}
      dialogClassName="modal-60w"
    >
      <Modal.Header closeButton>
        <Modal.Title>Training Session Setting</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="p-0 m-0">
          <Row>
            <Col>
              <h5>Paste Video URL Here :</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Control
                type="text"
                placeholder="Stream URL"
                onChange={(event) => {
                  updateSessionStreamUrl(event.target.value);
                }}
                defaultValue={streamUrl}
              />
              <br /><br />
              <Stack direction="horizontal" gap={4}>
                <Col xs={2}>
                  <Form.Label><b>Min Seconds</b></Form.Label>
                  <Form.Control
                    type="number"
                    min="1"
                    max={maxSeconds}
                    value={minSeconds}
                    onChange={(event) => {
                      setMinSeconds(event.target.value);
                    }}
                  />
                </Col>
                <Col xs={2}>
                  <Form.Label><b>Max Seconds</b></Form.Label>
                  <Form.Control
                    type="number"
                    min={minSeconds}
                    value={maxSeconds}
                    onChange={(event) => {
                      setMaxSeconds(event.target.value);
                    }}
                  />
                </Col>
                <Col xs={2}>
                  <Form.Label><b>Font Size</b></Form.Label>
                  <Form.Control
                    type="number"
                    min="10"
                    value={fontSize}
                    onChange={(event) => {
                      setFontSize(event.target.value);
                    }}
                  />
                </Col>
                <Col xs={2}>
                  <Form.Label><b>Margin Bottom</b></Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    value={marginBottom}
                    onChange={(event) => {
                      setMarginBottom(event.target.value);
                    }}
                  />
                </Col>
              </Stack>
            </Col>
          </Row>
        </Container>
        <br />
        <Button
          className="m-2 float-end"
          variant="primary"
          onClick={() => { exitSettingModal(); }}
        >
          Done
        </Button>
      </Modal.Body>
    </Modal >
  )

  const toggleMenus = (
    <Row>
      <Col className="p-0">
        <Card className="m-0 p-0" bg="secondary" text="white">
          <Card.Body
            className="align-items-center"
            style={{ padding: "10px" }}
          >
            <Card.Text
              as="h5"
              className="d-flex p-0 m-0 float-start align-items-center"
              style={{ cursor: "default" }}
            >
              <a href="/">
                <img
                  alt=""
                  src="./../../logo_white_96.png"
                  width="38"
                  height="38"
                  style={{ marginRight: "10px", cursor: "pointer" }}
                  className="d-inline-block align-top"
                />
              </a>
              {sessionName} - Training Session
            </Card.Text>
            <ButtonGroup className="float-end">
              <ToggleButton
                id="toggle-setCheck"
                // size="sm"
                type="checkbox"
                style={{ fontSize: "0.9rem" }}
                variant="outline-warning"
                checked={sheetsChecked}
                value="1"
                onChange={handleShowSheets}
              >
                Script
              </ToggleButton>
              <ToggleButton
                id="toggle-setCheckShortcuts"
                // size="sm"
                type="checkbox"
                style={{ fontSize: "0.9rem" }}
                variant="outline-warning"
                checked={shortcutsChecked}
                value="1"
                onChange={handleShowShortcuts}
              >
                Shortcuts
              </ToggleButton>
              <Button
                variant="outline-warning"
                // size="sm"
                onClick={() => {
                  openSettingModal();
                }}
              >
                Setting
              </Button>
            </ButtonGroup>
          </Card.Body>
        </Card>
      </Col>
    </Row >
  )


  if (props.sessionId == null) {
    return <div>Invalid Session</div>;
  }
  return (
    <>
      {/* <NavigationBar /> */}
      {streamUrlModal}
      <Container fluid>
        {toggleMenus}
        <Row className="p-0">
          {showSheets ? (
            <Col xs={sheetsWidth} className="p-2">
              <SheetsView
                sessionId={props.sessionId}
              />
            </Col>
          ) : null}
          <Col
            xs={inputWidth}
            className="p-2"
          >
            {/* <MessagesView
              sessionId={props.sessionId}
              title="History"
            >
            </MessagesView> */}
            <Card style={{ width: "100%", height: "60vh", }}>
              <Card.Body className="p-1">
                <div
                  style={{ position: "relative", width: "100%", height: "100%" }}
                >
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      /* background: #000; */
                      opacity: "1",
                      /*background:rgba(255,255,255,0.8); or just this*/
                      zIndex: "50",
                      color: "#fff",
                      // pointerEvents: "none",
                      fontSize: "5EM",
                      fontWeight: "bold",
                    }}
                  >
                    {/* <iframe
                      width="500px"
                      height="300px"
                      // src="https://www.youtube.com/embed/3oPs9HkhwQE?wmode=opaque"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen>
                    </iframe> */}
                    <iframe
                      // src="https://play.afreecatv.com/gdyung2/245856712/embed"
                      src={`${streamUrl}/embed`}
                      width="100%"
                      height="100%"
                      // frameBorder="0"
                      allowFullScreen
                    >
                    </iframe>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      // top: "0",
                      // left: "0",
                      width: "100%",
                      height: "100%",
                      /* background: #000; */
                      opacity: "1",
                      // background: "255, 255, 255, 0",
                      zIndex: "50",
                      color: "#fff",
                      pointerEvents: "none",
                      fontSize: "5EM",
                      fontWeight: "bold",
                    }}>
                    <BroadcastScreenView
                      sessionId={props.sessionId}
                      backgroundColor={broadcastSetting.screenColor}
                      // backgroundOpacity={broadcastSetting.backgroundOpacity}
                      backgroundOpacity={0}
                      borderOpacity={broadcastSetting.borderOpacity}
                      font={broadcastSetting.subFont}
                      fontSize={fontSize}
                      fontColor={broadcastSetting.subColor}
                      lineHeight={broadcastSetting.subLineHeight}
                      textBackgroundColor={broadcastSetting.subBackgroundColor}
                      textBackgroundOpacity={broadcastSetting.subBackgroundOpacity}
                      textShadowWidth={broadcastSetting.textShadowWidth}
                      textShadowBlur={broadcastSetting.textShadowBlur}
                      minSeconds={minSeconds}
                      maxSeconds={maxSeconds}
                      minSecondsTextLengthThreshold={broadcastSetting.subMinThresholdCharacters}
                      maxSecondsTextLengthThreshold={broadcastSetting.subMaxThresholdCharacters}
                      screenWidth={broadcastSetting.screenWidth}
                      screenHeight={broadcastSetting.screenHeight}
                      subAlign={broadcastSetting.subAlign}
                      marginBottom={marginBottom}
                      lineNumber={broadcastSetting.lineNumber}
                      isTrainingView={true}
                      isNoWrap={broadcastSetting.isNoWrap}
                      isDemoText={broadcastSetting.isDemoText}
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
            <SessionUsersView
              sessionId={props.sessionId}
              isTrainingView={true}
            />
            <ChatInputView
              sessionId={props.sessionId}
              shortcutInput={shortcutInput}
              shortcutInputIndex={shortcutInputIndex}
              setSourceWord={setSourceWord}
              translatedWord={translatedWord}
            />
          </Col>
          {showShortcuts ? (
            <Col xs={shortcutsWidth} className="p-2">
              <ShortcutsView
                sessionId={props.sessionId}
                setShortcutInput={setShortcutInput}
                setShortcutInputIndex={setShortcutInputIndex}
              />
            </Col>
          ) : null}
        </Row>
      </Container>
    </>
  );
}

// @flow

import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

type Props = $ReadOnly<{
  value: string,
  onValueChange: (string) => void,
}>;


const fontValues = {
  Arial: ("Arial": string),
  Verdana: "Verdana",
  Helvetica: "Helvetica",
  "NotoSans-Bold": "NotoSans-Bold",
  Tahoma: "Tahoma",
  "Trebuchet MS": "Trebuchet MS",
  "Times New Roman": "Times New Roman",
  Georgia: "Georgia",
  Garamond: "Garamond",
  "Courier New": "Courier New, monospace",
  "ヒラギノ角ゴ": "Hiragino Kaku Gothic Pro",
  "NotoSansJP-Bold": "NotoSansJP-Bold",
  "본고딕": "NotoSansKR-Bold",
  "나눔고딕": "NanumGothicBd",
  "NetflixSans_W_Bd": "NetflixSans_W_Bd",
  "NetflixSans_W_BdIt": "NetflixSans_W_BdIt",
};

const fontTitles = {
  Arial: ("Arial": string),
  Verdana: "Verdana",
  Helvetica: "Helvetica",
  "NotoSans-Bold": "NotoSans-Bold",
  Tahoma: "Tahoma",
  "Trebuchet MS": "Trebuchet MS",
  "Times New Roman": "Times New Roman",
  Georgia: "Georgia",
  Garamond: "Garamond",
  "Courier New, monospace": "Courier New",
  "Hiragino Kaku Gothic Pro": "ヒラギノ角ゴ",
  "NotoSansJP-Bold": "NotoSansJP-Bold",
  "NotoSansKR-Bold": "본고딕",
  "NanumGothicBd": "나눔고딕",
  "NetflixSans_W_Bd": "NetflixSans_W_Bd",
  "NetflixSans_W_BdIt": "NetflixSans_W_BdIt",
}

export default function _ComponentTemplate(props: Props): React$Element<any> {
  const [font, setFont] = useState(fontValues[props.value]);
  const [title, setTitle] = useState(props.value);

  useEffect(() => {
    setFont(fontValues[props.value]);
  }, [props.value]);

  useEffect(() => {
    setTitle(fontTitles[props.value]);
  }, [props.value]);

  return (
    <DropdownButton
      id="dropdown-item-button"
      title={<span style={{ fontFamily: font }}>{title}</span>}
      variant="light"
    >
      {Object.entries(fontValues).map(([key, value], idx) => (
        <Dropdown.Item
          as="button"
          key={idx}
          onClick={() => {
            setFont(value);
            setTitle(key);
            props.onValueChange(((value: any): string));
          }}
        >
          <span style={{ fontFamily: value }}>{key}</span>
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
}

// @flow

import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

type Props = $ReadOnly<{
  value: string,
  onValueChange: (string) => void,
}>;

const alignValues = {
  Left: ("start": string),
  Center: "center",
  Right: "end",
};

const alignTitles = {
  start: ("Left": string),
  center: "Center",
  end: "Right",
};

export default function _ComponentTemplate(props: Props): React$Element<any> {
  // const [align, setAlign] = useState(alignValues[props.value]);
  const [title, setTitle] = useState(props.value);


  useEffect(() => {
    setTitle(alignTitles[props.value]);
  }, [props.value]);

  return (
    <DropdownButton
      id="dropdown-item-button"
      title={<span style={{ fontFamily: "Arial" }}>{title}</span>}
      variant="light"
    >
      {Object.entries(alignValues).map(([key, value], idx) => (
        <Dropdown.Item
          as="button"
          key={idx}
          onClick={() => {
            // setAlign(value);
            setTitle(key);
            props.onValueChange(((value: any): string));
          }}
        >
          <span style={{ fontFamily: "Arial" }}>{key}</span>
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
}

// @flow
import React from "react";

const UserContext = (React.createContext({
  username: "",
  userId: "",
  isSuperAdmin: false,
}): React$Context<{
  username: string,
  userId: string,
  isSuperAdmin: boolean,
  ...
}>);

export default UserContext;

// @flow
import React from "react";

import Logger from "./Logger";

const LoggerContext = (React.createContext({
  logger: new Logger(),
}): React$Context<{ logger: Logger, ... }>);

export default LoggerContext;

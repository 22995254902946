// @flow

import BroadcastMessagesView from "./BroadcastMessagesView.react";

import { React, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

type Props = $ReadOnly<{
  sessionId: string,
  backgroundColor: string,
  backgroundOpacity: number,
  borderOpacity: number,
  font: string,
  fontSize: string,
  fontColor: string,
  lineHeight: string,
  textBackgroundColor: string,
  textBackgroundOpacity: number,
  textShadowWidth: number,
  textShadowBlur: number,
  minSeconds: number,
  maxSeconds: number,
  minSecondsTextLengthThreshold: number,
  maxSecondsTextLengthThreshold: number,
  screenWidth: number,
  screenHeight: number,
  subAlign: string,
  marginBottom: number,
  isPreview: boolean,
  isTrainingView: boolean,
  lineNumber: number,
  isNoWrap: boolean,
  isDemoText: boolean,
}>;

export default function BroadcastScreenView(props: Props): React$Element<any> {
  const [backgroundColor, setBackgroundColor] = useState("");
  const [borderOpacity, setBorderOpacity] = useState(0);
  const [marginBottom, setMarginBottom] = useState(0);

  let width;
  let height;
  if (props.isPreview) {
    width = "960px";
    height = "540px";
  } else if (props.isTrainingView) {
    width = "100%";
    height = "100%";
  } else {
    width = "100vw";
    height = "100vh";
  }

  useEffect(() => {
    function calculateBackgroundColor() {
      if (props.backgroundOpacity < 7) {
        return (
          props.backgroundColor +
          "0" +
          Math.floor((props.backgroundOpacity * 255) / 100).toString(16)
        );
      }
      return (
        props.backgroundColor +
        Math.floor((props.backgroundOpacity * 255) / 100).toString(16)
      );
    }
    setBackgroundColor(calculateBackgroundColor());
  }, [props.backgroundColor, props.backgroundOpacity]);

  useEffect(() => {
    setBorderOpacity(props.borderOpacity / 100);
  }, [props.borderOpacity]);

  // Calculate Margin Bottom Value
  useEffect(() => {
    const marginBottomValue = props.marginBottom
      ? props.marginBottom.toString()
      : "0";
    setMarginBottom(marginBottomValue);
  }, [props.marginBottom]);

  return (
    <div
      style={{
        overflow: "hidden",
        width: width,
        height: height,
        backgroundColor: backgroundColor,
        fontFamily: props.font,
        fontSize: props.fontSize + "px",
        lineHeight: props.lineHeight,
        border: `2px solid rgba(1, 1, 1, ${borderOpacity})`,
        textIndent: "-3px",
        paddingLeft: "10px",
      }}
      className="d-flex align-items-end"
    >
      <Container fluid style={{ marginBottom: `${marginBottom}px` }}>
        <BroadcastMessagesView
          sessionId={props.sessionId}
          textBackgroundColor={props.textBackgroundColor}
          textBackgroundOpacity={props.textBackgroundOpacity}
          textShadowWidth={props.textShadowWidth}
          textShadowBlur={props.textShadowBlur}
          fontSize={props.fontSize}
          fontColor={props.fontColor}
          lineHeight={props.lineHeight}
          minSeconds={props.minSeconds}
          maxSeconds={props.maxSeconds}
          minSecondsTextLengthThreshold={props.minSecondsTextLengthThreshold}
          maxSecondsTextLengthThreshold={props.maxSecondsTextLengthThreshold}
          marginBottom={props.marginBottom}
          subAlign={props.subAlign}
          lineNumber={props.lineNumber}
          isNoWrap={props.isNoWrap}
          isDemoText={props.isDemoText}
        />
      </Container>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { queryBookmarksBySessionIdRowIndex } from "./../graphql/queries";
import {
  createBookmarks as createBookmarksMutation,
  deleteBookmarks as deleteBookmarksMutation,
} from "./../graphql/mutations";
import {
  onCreateBookmarks,
  onDeleteBookmarks,
  // onUpdateSession,
} from "../graphql/subscriptions";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

type Props = $ReadOnly<{
  sessionId: string,
  selectedRow: number,
  setSelectedRow: React.Dispatch<React.SetStateAction<any>>,
  setShouldScroll: React.Dispatch<React.SetStateAction<any>>,
  executeScroll: () => void,
}>;

const DEFAULT_COLOR = "#ac5ffa";

export default function BookmarksBar(props: Props): React$Element<any> {
  const [bookmarks, setBookmarks] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [newColor, setNewColor] = useState(DEFAULT_COLOR);
  const [newRow, setNewRow] = useState(0);

  // console.log(bookmarks);

  // Bookmarks initial fetch
  useEffect(() => {
    async function initialFetch() {
      const bookmarks = await fetchBookmarks();
      setBookmarks(bookmarks);
    }
    initialFetch();
  }, [props.sessionId]);

  // Subscribe to Bookmarks mutation
  useEffect(() => {
    const onCreateSubscription = API.graphql({
      query: onCreateBookmarks,
      variables: {
        sessionId: props.sessionId,
      },
    }).subscribe({
      next: updateFetch,
      error: (error) => console.warn(error),
    });

    const onDeleteSubscription = API.graphql({
      query: onDeleteBookmarks,
      variables: {
        sessionId: props.sessionId,
      },
    }).subscribe({
      next: updateFetch,
      error: (error) => console.warn(error),
    });

    async function updateFetch() {
      const bookmarks = await fetchBookmarks();
      setBookmarks(bookmarks);
    }

    return () => {
      onCreateSubscription.unsubscribe();
      onDeleteSubscription.unsubscribe();
    };
  }, [props.sessionId]);

  async function fetchBookmarks() {
    try {
      const apiData = await API.graphql({
        query: queryBookmarksBySessionIdRowIndex,
        variables: {
          sessionId: props.sessionId,
        },
      });
      return apiData.data.queryBookmarksBySessionIdRowIndex.items;
    } catch (e) {
      console.log(e);
    }
  }

  async function createBookmark(title: string, color: string, row: Number) {
    if (props.selectedRow !== null && props.selectedRow >= 0) {
      const ret = await API.graphql({
        query: createBookmarksMutation,
        variables: {
          input: {
            sessionId: props.sessionId,
            row: row,
            title: title,
            color: color,
          },
        },
      });
    }
  }

  async function deleteBookmark() {
    const ret = await API.graphql({
      query: deleteBookmarksMutation,
      variables: {
        input: {
          id: bookmarks.find((bookmark) => bookmark.row === props.selectedRow)
            .id,
          row: bookmarks.find((bookmark) => bookmark.row === props.selectedRow)
            .row,
        },
      },
    });
  }

  // const deleteAllBookmarks = () => {
  //   bookmarks.forEach((bookmark) => {
  //     deleteBookmark(bookmark.id, bookmark.row);
  //   });
  // };

  const handleBookmarkClick = (row) => {
    if (row === props.selectedRow) {
      props.executeScroll();
      props.setShouldScroll(true);
      document.getElementById("chat-input-field").focus();
    } else {
      props.setSelectedRow(row);
      props.setShouldScroll(true);
      document.getElementById("chat-input-field").focus();
    }
  };

  const handleCreateClick = () => {
    createBookmark(newTitle, newColor, props.selectedRow);
    setNewTitle("");
    // setNewColor(DEFAULT_COLOR);
    setShowCreateModal(false);
  };

  const handleEditClick = () => {
    setShowEditModal(true);
    setNewTitle(
      bookmarks.find((bookmark) => bookmark.row === props.selectedRow).title
    );
    setNewColor(
      bookmarks.find((bookmark) => bookmark.row === props.selectedRow).color
    );
    setNewRow(props.selectedRow + 1);
  };

  const handleSaveClick = () => {
    deleteBookmark();
    createBookmark(newTitle, newColor, newRow - 1);
    setNewTitle("");
    setShowEditModal(false);
    props.setSelectedRow(newRow - 1);
  };

  const handleHideCreateModal = () => {
    setNewTitle("");
    setNewColor(DEFAULT_COLOR);
    setShowCreateModal(false);
  };

  const handleHideEditModal = () => {
    setNewTitle("");
    setNewColor(DEFAULT_COLOR);
    setShowEditModal(false);
  };

  const handleStyle = (color, row) => {
    return {
      backgroundColor: color,
      border: `0px solid`,
      fontWeight: row === props.selectedRow ? "bold" : null,
    };
  };

  return (
    <Container style={{ backgroundColor: "#edf3fa" }} fluid className="m-0 p-0">
      <Row className="mt-1 mb-1">
        {/* Create Modal */}
        <Modal
          show={showCreateModal}
          onHide={handleHideCreateModal}
          keyboard={false}
        >
          <Modal.Header closeButton>
            <h3>Create Bookmark</h3>
          </Modal.Header>
          <Modal.Body>
            <Row className="float-end">
              <Col>(Row : {props.selectedRow + 1})</Col>
            </Row>
            <Form>
              <Form.Group>
                <Form.Label>Bookmark Title</Form.Label>
                <Form.Control
                  type="text"
                  autoFocus
                  placeholder="Enter Bookmark Title..."
                  value={newTitle}
                  onChange={(event) => {
                    setNewTitle(event.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      // console.log("Enter");
                      handleCreateClick();
                    }
                  }}
                />
              </Form.Group>
              <Form.Group className="mt-2">
                <Form.Label>Color</Form.Label>
                <Row className="align-items-center" xs="auto">
                  <Col className="me-0 pe-0">
                    <Form.Control
                      type="color"
                      value={newColor}
                      title="Choose bookmark color"
                      onChange={(event) => {
                        setNewColor(event.target.value);
                      }}
                    />
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      onClick={() => {
                        if (newColor === "#ac5ffa") {
                          return;
                        } else {
                          setNewColor(DEFAULT_COLOR);
                        }
                      }}
                    >
                      Default Color
                    </Button>
                  </Col>
                  {/* </Col> */}
                </Row>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleCreateClick}>Create</Button>
          </Modal.Footer>
        </Modal>
        {/* Edit Modal From Below */}
        <Modal
          show={showEditModal}
          onHide={handleHideEditModal}
          keyboard={false}
        >
          <Modal.Header closeButton>
            <h3>Edit Bookmark</h3>
          </Modal.Header>
          <Modal.Body>
            {/* <Row className="float-end">
              <Col>(Row : {props.selectedRow + 1})</Col>
            </Row> */}
            <Form>
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  autoFocus
                  placeholder="Enter Bookmark Title..."
                  value={newTitle}
                  onChange={(event) => {
                    setNewTitle(event.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      handleSaveClick();
                    }
                  }}
                />
              </Form.Group>
              <Form.Group className="mt-2">
                <Form.Label>Row</Form.Label>
                <Form.Control
                  type="number"
                  style={{ width: "35%" }}
                  autoFocus
                  // placeholder="Enter Bookmark Title..."
                  value={newRow}
                  onChange={(event) => {
                    setNewRow(event.target.valueAsNumber);
                  }}
                />
              </Form.Group>
              <Form.Group className="mt-2">
                <Form.Label>Color</Form.Label>
                <Row className="align-items-center" xs="auto">
                  <Col className="me-0 pe-0">
                    <Form.Control
                      type="color"
                      value={newColor}
                      title="Choose bookmark color"
                      onChange={(event) => {
                        setNewColor(event.target.value);
                      }}
                    />
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      onClick={() => {
                        if (newColor === "#ac5ffa") {
                          return;
                        } else {
                          setNewColor(DEFAULT_COLOR);
                        }
                      }}
                    >
                      Default Color
                    </Button>
                  </Col>
                  {/* </Col> */}
                </Row>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleSaveClick}>Save</Button>
          </Modal.Footer>
        </Modal>
        <Col>
          {bookmarks.map((bookmark, index) => (
            <Button
              key={bookmark.row}
              className="ms-1 mb-1"
              size="sm"
              style={handleStyle(bookmark.color, bookmark.row)}
              onClick={() => {
                handleBookmarkClick(bookmark.row);
              }}
            >
              {bookmark.title} ({bookmark.row + 1})
            </Button>
          ))}
          {bookmarks.some((bookmark) => bookmark.row === props.selectedRow) ? (
            <>
              <Button
                size="sm"
                className="float-end me-3"
                variant="outline-danger"
                onClick={() => {
                  deleteBookmark();
                }}
              >
                Delete
              </Button>
              <Button
                size="sm"
                className="float-end me-1"
                variant="outline-secondary"
                onClick={() => {
                  handleEditClick();
                }}
              >
                Edit
              </Button>
            </>
          ) : (
            <Button
              size="sm"
              className="float-end me-3"
              variant="outline-primary"
              onClick={() => {
                setShowCreateModal(true);
              }}
            >
              Mark
            </Button>
          )}
          {/* <Button onClick={deleteAllBookmarks}>Del all</Button> */}
        </Col>
      </Row>
    </Container>
  );
}
